/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { ActionButton, NumberInput } from "components"
import {
  useProjectList,
  useTaxCodeList
} from "pages/Finance/Transaction/GeneralEntry/utils/hooks"
import { useDropdownProduct } from "pages/Sales/Transaction/CustomerOrder/utils/hooks"
import { useEffect, useMemo } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { formInvoiceDetailSchema, FormInvoiceDetailType } from "../../../types"
import { calculateTotalInvoiceDetail } from "../../../utils/functions"

type Action = "add" | "edit"

type RowProps = {
  index?: number
  action: Action
  defaultValues?: any
  onSubmit: (value: any) => void
  onCancel: () => void
  tax: ReturnType<typeof useTaxCodeList>
  jobs: ReturnType<typeof useProjectList>
  products: ReturnType<typeof useDropdownProduct>
  priceLevelName?: string
  displayArrowNavigation?: boolean
}

export function RowSalesOrderInvoice(props: RowProps) {
  const defaultValues: FormInvoiceDetailType = useMemo(
    () => ({
      invoice_detail_id: props?.defaultValues?.invoice_detail_id,
      invoice_id: props?.defaultValues?.invoice_id,
      sales_order_detail_id: props?.defaultValues?.sales_order_detail_id,
      invoiced: props?.defaultValues?.invoiced,
      product_id: props?.defaultValues?.product_id ?? NaN,
      product_name: props?.defaultValues?.product_name ?? "",
      price_level_name: props?.priceLevelName ?? "",
      product_barcode: props?.defaultValues?.product_barcode ?? "",
      qty_order: props?.defaultValues?.qty_order ?? 0,
      received: props?.defaultValues?.received ?? 0,
      mr_unit_id: props?.defaultValues?.mr_unit_id ?? NaN,
      mr_unit_name: props?.defaultValues?.mr_unit_name ?? "",
      price_measure: props?.defaultValues?.price_measure ?? 0,
      discount: props?.defaultValues?.discount ?? 0,
      total: props?.defaultValues?.total ?? 0,
      project_id: props?.defaultValues?.project_id ?? NaN,
      project_name: props?.defaultValues?.project_name ?? "",
      tax_id: props?.defaultValues?.tax_id ?? 2,
      tax_name: `${props.defaultValues.tax_name}`,
      tax_rate: props?.defaultValues?.tax_rate,
      location_id: 0,
      soh: props?.defaultValues?.soh ?? 0,
      quantity_invoice: props?.defaultValues?.quantity_invoice ?? 0
    }),
    [props.defaultValues, props.priceLevelName]
  )

  const methods = useForm<FormInvoiceDetailType>({
    defaultValues,
    resolver: yupResolver(formInvoiceDetailSchema)
  })

  const [qty_order, quantity_invoice, price_measure, discount, received] =
    methods.watch([
      "qty_order",
      "quantity_invoice",
      "price_measure",
      "discount",
      "received",
      "price_level_name"
    ])

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  useEffect(() => {
    methods.setValue(
      "total",
      calculateTotalInvoiceDetail(methods.getValues()).finalPrice
    )
  }, [quantity_invoice, price_measure, discount])

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td className="text-right">
          <div className="w-[120px] px-3">
            {(qty_order - received).toLocaleString("id-ID")}
          </div>
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="quantity_invoice"
            render={({ field, fieldState }) => (
              <NumberInput
                textRight
                decimalScale={2}
                inputClass="w-[120px]"
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => {
                  field.onChange(value.floatValue)
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="soh"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="mr_unit_name"
            render={({ field }) => <div className="px-3">{field.value}</div>}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="product_barcode"
            render={({ field }) => (
              <div className="w-[250px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="product_name"
            render={({ field }) => (
              <div className="w-[250px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="price_measure"
            render={({ field }) => (
              <div className="px-3">{field.value.toLocaleString()}</div>
            )}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="discount"
            render={({ field }) => <div className="px-3">{field.value}</div>}
          />
        </td>
        <td className="text-right">
          <Controller
            control={methods.control}
            name="total"
            render={({ field }) => (
              <div className="w-[120px] px-3">
                {field.value.toLocaleString()}
              </div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="project_name"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <Controller
            control={methods.control}
            name="tax_name"
            render={({ field }) => (
              <div className="w-[120px] px-3">{field.value}</div>
            )}
          />
        </td>
        <td>
          <section className="flex justify-center items-center">
            <ActionButton.Check
              onClick={() => {
                methods.handleSubmit((data) => props.onSubmit(data))()
              }}
            />

            <ActionButton.Close onClick={props.onCancel} />
          </section>
        </td>
      </tr>
    </FormProvider>
  )
}
