// React
import { useContext } from "react"

// Components
import { Loading } from "components"
import { InputSection } from "../components"

// Contexts
import { DisabledContext } from "../../contexts"
import { DataContext, DataBillContext } from "../contexts"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Types
import type { FormType, DetailFormType } from "../utils"

// Utils
import { useBillDetail, useDetail } from "../utils"
import { useParams } from "react-router-dom"

export function DetailBill(params: {
  bill_id: string
  purchase_order_id: string
}): JSX.Element {
  // Hooks
  const { purchase_order_id, bill_id } = params
  const datapo = useDetail(purchase_order_id!)
  const { billData, data, isLoading } = useBillDetail(purchase_order_id!, bill_id!)

  if (isLoading || !data || datapo.isLoading || !datapo.data) {
    return (
      <Loading
        errorText="No data available"
        loading={isLoading || datapo.isLoading}
      />
    )
  }

  return (
    <DisabledContext.Provider value={true}>
      <DataBillContext.Provider value={billData!}>
        <DataContext.Provider value={{
          ...data.datapo,
          _vendor_name: data.datapo.vendor_name,
          approval_status: billData!.approve_status,
          approve_by_name: billData!.approved_by,
          po_payment_status_text: datapo.data.po_payment_status_text,
          podetail: datapo.data.podetail,
          reminder: datapo.data.reminder,
          sugest_freight: datapo.data.sugest_freight
        }}>
          <FormSection
            freight={data.freight}
            probildetail={data.probildetail}
            transaction_date={data.datapo.transaction_date}
            trx_code={data.trx_code}
          />
        </DataContext.Provider>
      </DataBillContext.Provider>
    </DisabledContext.Provider>
  )
}

export function FormSection(params: {
  freight: number
  probildetail: DetailFormType[]
  transaction_date: string
  trx_code: string
}) {
  // Hooks
  const dataBill = useContext(DataContext)
  const { id } = useParams()

  // Vars
  const datapo = dataBill

  // Form
  const defaultValues: FormType = {
    delivery_no: datapo.delivered.toString(),
    trx_code: params.trx_code,
    freight: params.freight,
    probildetail: params.probildetail,
    address: datapo.address,
    location_id: datapo.location_id,
    memo: datapo.memo,
    payment_term: datapo.payment_term,
    promise_date: datapo.promize_date,
    purchase_order_id: id!,
    referensi: datapo.referensi,
    reminder: dataBill.reminder,
    tax_inclusive: datapo.tax_inclusive,
    transaction_date: params.transaction_date,
    vendor_id: datapo.vendor_id
  }
  const methods = useForm<FormType>({
    defaultValues,
  })

  return (
    <FormProvider {...methods}>
      <InputSection />
    </FormProvider>
  )
}