/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { today, useApi } from "utils"
import { FormSalesOrderType } from "../CustomerOrder/types"
import { useDetailSalesOrder } from "../CustomerOrder/utils/hooks"
import { FormSection } from "./components"
import { SalesOrderStatus } from "./vars"

export default function CloseSalesOrder() {
  const api = useApi()
  const params = useParams()
  const navigate = useNavigate()
  const sales = useDetailSalesOrder({
    sales_order_id: params?.sales_order_id ?? ""
  })

  const defaultValues: FormSalesOrderType = useMemo(
    () => ({
      trx_code: sales.data?.dataso?.trx_code ?? "",
      sales_order_id: sales.data?.dataso.sales_order_id ?? NaN,
      customer_id: sales.data?.dataso.customer_id ?? NaN,
      employee_id: sales.data?.dataso.employee_id ?? NaN,
      payment_term: sales.data?.dataso.payment_term ?? NaN,
      location_id: sales.data?.dataso.location_id ?? NaN,
      memo: sales.data?.dataso?.memo ?? "sales order transaction",
      promize_date: sales.data?.dataso?.promize_date ?? today(),
      referensi: sales.data?.dataso?.referensi ?? "",
      tax_inclusive: true,
      transaction_date: sales.data?.dataso?.transaction_date ?? today(),
      shipped: sales.data?.dataso?.address ?? "",
      payment_term_name: sales.data?.dataso?.payment_term_name ?? "",
      price_level_name: sales.data?.dataso?.price_level_name ?? "",
      sodetail:
        sales.data?.detailso?.map((item) => ({
          sales_order_detail_id: item.sales_order_detail_id,
          product_id: item.product_id,
          product_name: item.product_name,
          product_barcode: item.product_barcode,
          qty_order: item.qty_order,
          received: item.received ?? 0,
          mr_unit_id: item.mr_unit_id,
          mr_unit_name: item.mr_unit_name,
          price_measure: item.price_measure,
          discount: item.discount,
          total: item.total,
          project_id: item.project_id,
          project_name: item.project_name,
          tax_id: item.tax_id,
          tax_name: `${item.tax_name} (${item.tax_rate}%)`,
          tax_rate: item.tax_rate,
          location_id: item.location_id,
          location_name: item.location_name,
          soh: item?.soh ?? 0
        })) ?? [],
      freight: sales.data?.dataso?.freight ?? 0,
      approved_by: sales.data?.dataso?.approve_by ?? "",
      approved_by_name:
        sales.data?.dataso?.approve_by_name?.toUpperCase() ?? "",
      approval_status: sales.data?.dataso?.approval_status ?? 1,
      approval_status_text:
        sales.data?.dataso?.approval_status_text?.toUpperCase() ?? "",
      so_payment_status: sales.data?.dataso?.so_payment_status ?? 1,
      so_payment_status_text:
        sales.data?.dataso?.so_payment_status_text?.toUpperCase() ?? "",
      so_status: sales.data?.dataso?.so_status ?? 1,
      so_status_text: SalesOrderStatus[sales.data?.dataso?.so_status ?? 1],
      reminder: Object.is(sales.data?.reminder, null)
        ? null
        : {
            card_group: sales.data?.reminder?.card_group ?? NaN,
            card_id: sales.data?.reminder?.card_id ?? NaN,
            memo: sales.data?.reminder?.memo ?? "",
            promise_date: sales.data?.reminder?.promise_date ?? today(),
            transaction_date: sales.data?.reminder?.transaction_date ?? today(),
            trx_code: sales.data?.reminder?.trx_code ?? "",
            vendor_id: sales.data?.reminder?.card_id ?? NaN
          }
    }),
    [sales.data]
  )

  const methods = useForm<FormSalesOrderType>({ defaultValues })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection
        isEdit
        approvalView
        onSubmit={methods.handleSubmit(async (value) => {
          return await toast
            .promise(
              api.post("/salesorder/manualclose", null, {
                params: {
                  sales_order_id: value.sales_order_id,
                  date_close: moment().format("YYYY-MM-DD")
                }
              }),
              {
                loading: "Loading...",
                success: (res) => res.data.message,
                error: (err) =>
                  err.response.data.detail?.message ?? err.response.data.message
              }
            )
            .then(() =>
              navigate(
                `/sales/transaction/customer-order/edit/${value.sales_order_id}`,
                { replace: true }
              )
            )
            .catch((err) => {
              throw new Error(err)
            })
        })}
      />
    </FormProvider>
  )
}
