// Form
import * as yup from "yup"

// Vars
const baseValidation = {
  balance: yup.number().label("BALANCE").required(),
  card_id: yup.string().label("TRADER").required(),
  amount: yup.number().label("CREDIT").min(0).required(),
  datajournal: yup.array().label("DETAIL").min(1).required(),
  coa_id: yup.string().label("ACCOUNTS").required(),
  coa_name: yup.string().defined(),
  group_card: yup.string().label("GROUP CARD").required(),
  imgPath: yup.array().label("FILE").min(0).required(),
  location_id: yup.string().label("LOCATION").required(),
  memo: yup.string().label("MEMO").required(),
  reconsiled: yup.bool().required(),
  reference_no: yup.string().label("MEMO").required(),
  tax_inclusive: yup.boolean().label("TAX").required(),
  transaction_date: yup.string().label("TRANSACTION DATE").required(),
  trx_code: yup.string().required(),
  type_payment: yup.string().defined(),
  name_payment: yup.string().defined(),
  number_payment: yup.string().defined(),
  expired_payment: yup.string().defined(),
  authorization_payment: yup.string().defined(),
  note_payment: yup.string().defined()
}
const baseValidationEdit = {
  ...baseValidation,
  financeid: yup.number().required(),
  journal_code: yup.string().required()
}

export const validationSchemaSpend = yup.object().shape({
  ...baseValidation,
  amount: baseValidation.amount.max(yup.ref("balance"))
})
export const validationSchemaReceive = yup.object().shape(baseValidation)
export const validationSchemaEditSpend = yup.object().shape({
  ...baseValidationEdit,
  amount: baseValidation.amount.max(yup.ref("balance"))
})
export const validationSchemaEditReceive = yup.object().shape(baseValidationEdit)

export type FormType = yup.InferType<typeof validationSchemaSpend>
export type FormEditType = yup.InferType<typeof validationSchemaEditSpend>