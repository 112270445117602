import { CommandButton, FooterMenu } from "components"
import { Fragment } from "react"

type CommandButtonsProps = {
  display?: boolean
  showDeleteButton?: boolean
  showSaveButton?: boolean
  onDelete?: () => void
  onSave?: () => void
}

export const CommandButtons = ({
  display = true,
  showDeleteButton,
  showSaveButton,
  onDelete,
  onSave
}: CommandButtonsProps) => {
  return (
    <div className={display ? "" : "hidden"}>
      <FooterMenu
        hasCancelButton
        customElement={{
          save: (
            <Fragment>
              {showSaveButton && (
                <CommandButton
                  actiontype="save"
                  type="button"
                  color="primary"
                  permission="ST021"
                  onClick={onSave}
                />
              )}
            </Fragment>
          ),

          delete: (
            <Fragment>
              {showDeleteButton && (
                <CommandButton actiontype="delete" onClick={onDelete} />
              )}
            </Fragment>
          ),

          journal: <CommandButton actiontype="journal" />,
          attachment: <CommandButton actiontype="attachment" />
        }}
      />
    </div>
  )
}
