// React
import { useEffect, useState } from "react"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

interface PaymentBillType {
  approve_status: number
  bill_id: number
  trx_code: string
  transaction_date: string
  bill_status: string
  delivery_no: number
  memo: string
  total: number
  depo: number
  payment: number
}

export function useBillList(purchase_order_id: string): {
  data: PaymentBillType[]
  isLoading: boolean
} {
  // Vars
  const defaultData: PaymentBillType[] = []
  const default_option: SelectOptionType[] = [
    {
      label: "All",
      value: "0"
    },
    {
      label: "Open",
      value: "1"
    },
    {
      label: "Paid",
      value: "2"
    },
    {
      label: "Credit",
      value: "3"
    }
  ]

  // Hooks
  const api = useApi()
  const [data, setData] = useState<PaymentBillType[]>(defaultData)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const promise = new Promise<PaymentBillType[]>((resolve) => {
      setLoading(true)

      api.get("/paymentbill/payment_bill_by_po", {
        params: { purchase_order_id }
      }).then((res: SuccessFetch<{ payload: {
        approve_status: number
        bill_id: number
        trx_code: string
        transaction_date: string
        bill_status: number
        delivery_no: number
        memo: string
        total: string | null
        depo: string
        payment: string
      }[] }>) => {
        resolve(res.data.payload.map(item => {
          return {
            approve_status: item.approve_status,
            bill_id: item.bill_id,
            bill_status: default_option.find(i => i.value === item.bill_status.toString())?.label ?? "-",
            delivery_no: item.delivery_no,
            depo: parseInt(item.depo),
            memo: item.memo,
            payment: parseInt(item.payment),
            total: parseInt(item.total ?? "0"),
            transaction_date: item.transaction_date,
            trx_code: item.trx_code
          }
        }))
      }).catch(() => {
        resolve(defaultData)
      }).finally(() => {
        setLoading(false)
      })
    })

    promise.then(setData)

    return () => {
      setData(defaultData)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}