import {
  Button,
  Card,
  FooterMenu,
  HeaderMenu,
  PermissionLink
} from "components"
import { Fragment } from "react"
import { TableSection } from "./components/TableSection"
import { TbChevronRight } from "react-icons/tb"

export default function OpeningStock() {
  return (
    <Fragment>
      <section className="container my-2">
        <section>
          <HeaderMenu title="DATA ENTRY | OPENING STOCK" />
          <section className="mt-2">
            <Card>
              <Card.Body>
                <div className="mb-3 justify-end flex">
                  <PermissionLink
                    permission={"IT081"}
                    to={"/setup/inventory/opening-stock/create"}>
                    <Button permission="IT081" color="primary">
                      OPENING STOCK <TbChevronRight />
                    </Button>
                  </PermissionLink>
                </div>
                <TableSection />
              </Card.Body>
            </Card>
          </section>
          <section className="mt-2">
            <FooterMenu hasCancelButton />
          </section>
        </section>
      </section>
    </Fragment>
  )
}
