// React
import { useState } from "react"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { FormatType, HeaderType } from "../../types"

// Utils
import { headerList } from "../vars"

export function useDataFormat(): FormatType {
  // Hooks
  const [tab, setTab] = useState<number>(0)
  const [headers, setHeaders] = useState<HeaderType[]>(headerList.filter(item => item.required))

  const assignHeaders = (headers: HeaderType[]): void => {
    if (!headers.length) {
      toast.error("No headers selected")
      return
    }

    setHeaders(headers)
    nextTab()
  }

  const nextTab = (): void => {
    setTab(prev => prev + 1)
  }

  const prevTab = (): void => {
    if (tab === 1) {
      setTab(prev => prev - 1)
      return
    }
  }

  return { headers, tab, assignHeaders, nextTab, prevTab }
}