import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { FaPlus } from "react-icons/fa"
import { Button, Input, Select } from "../../../../../../components"
import { CreateModal as CreateModalBank } from "../../../../../Setup/Preference/Bank/components"
import type { BankList } from "../../types"
import { useBank } from "./utils"

interface MapProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: boolean
}

export const FormBank: React.FC<MapProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<BankList>()

  return (
    <div>
      <section>
        <Bank isDisabled={action === "DETAIL"} />
        <Controller
          control={control}
          name="branch"
          render={({ field, fieldState }) =>
            <Input
              {...field}
              label="BRANCH"
              className="w-full"
              error={fieldState.error?.message}
              placeholder="Input branch"
              value={field.value ?? ""}
              disabled={action === "DETAIL"}
            />
          }
        />
        <Controller
          control={control}
          name="account_number"
          render={({ field, fieldState }) =>
            <Input
              {...field}
              label="ACCOUNT BANK NUMBER"
              className="w-full"
              error={fieldState.error?.message}
              placeholder="Input account bank number"
              value={field.value ?? ""}
              onChange={(e) => {
                const result = e.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '')
                field.onChange(result)
              }}
              disabled={action === "DETAIL"}
            />
          }
        />
        <Controller
          control={control}
          name="account_name"
          render={({ field, fieldState }) =>
            <Input
              {...field}
              label="ACCOUNT BANK NAME"
              className="w-full"
              error={fieldState.error?.message}
              placeholder="Input account bank name"
              value={field.value ?? ""}
              disabled={action === "DETAIL"}
            />
          }
        />
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button
            type="button"
            className="!none mt-5"
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            permission={"CR011"}
            onClick={handleSubmit(() => onSubmit(getValues()), (err) => console.log(err))}
            disabled={isActiveSubmit}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}

function Bank(props: { isDisabled: boolean }) {
  const { control } = useFormContext<BankList>()
  const { data, isLoading, refetch } = useBank()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="bank_id"
        render={({ field, fieldState }) => (
          <Select
            label="BANK"
            placeholder="Select bank..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled &&
        <div className="mt-[36px]">
          <Button
            type="button"
            color="primary"
            className="!rounded-r-lg"
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalBank toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}