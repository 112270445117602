/* eslint-disable react-hooks/exhaustive-deps */
import { BaseTable, Loading, Modal } from "components"
import moment from "moment"
import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { convertNumber, useApi } from "utils"
import { FormInvoiceType, JournalList } from "../types"
import { generateJournalInvoice } from "../utils/functions"
import { useTaxCodeList } from "pages/Finance/Transaction/GeneralEntry/utils/hooks"

type ModalJournalInvoiceProps = {
  isOpen: boolean
  toggle: () => void
}

export const ModalJournalInvoice = ({ isOpen, toggle }: ModalJournalInvoiceProps) => {
  const api = useApi()
  const taxes = useTaxCodeList()
  const methods = useFormContext<FormInvoiceType>()
  const [isLoading, setLoading] = useState(false)
  const [journalList, setJournalList] = useState<JournalList[]>([])
  const [
    invoice_detail,
    transaction_date,
    trx_code,
    freight
  ] = useWatch({ control: methods.control, name: ['invoice_detail', "transaction_date", "trx_code", "freight"] })

  useEffect(() => {
    if (isOpen && invoice_detail?.length) {

      (async () => {
        setLoading(true)

        const journals = await generateJournalInvoice({
          api,
          taxes: taxes.data,
          freight: freight ?? 0,
          date: transaction_date,
          trx_code: trx_code ?? "",
          invoiceDetails: invoice_detail ?? [],
        })

        setJournalList(journals)
        setLoading(false)
      })()
    }
  }, [invoice_detail, isOpen])

  return (
    <Modal isOpen={isOpen} title="JOURNAL" size="full" closeModal={toggle}>
      <Modal.Body>
        {isLoading ? (
          <Loading loading />
        ) : (
          <BaseTable className="border-collapse border-transparent">
            <thead>
              <tr className="uppercase">
                <th>Date</th>
                <th>IDM</th>
                <th>Account</th>
                <th>Debet</th>
                <th>Credit</th>
              </tr>
            </thead>

            <tbody>
              {journalList
                .filter(item => item.credit || item.debet)
                .map((item, key: number) => {
                return (
                  <tr key={key}>
                    <td>
                      {moment(item.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </td>
                    <td>{item.idm}</td>
                    <td>{item.coa} </td>
                    <td className="text-right">{item.debet > 0 ? convertNumber(item.debet).intoCurrency : ''} </td>
                    <td className="text-right">{item.credit > 0 ? convertNumber(item.credit).intoCurrency : ''} </td>
                  </tr>
                )
              })}
            </tbody>
          </BaseTable>

        )}
      </Modal.Body>
    </Modal>
  )
}
