import { ActionButton, PermissionLink, TableNumber } from "components";
import moment from "moment";
import { DetailCustomerQuotation, FormCustomerQuotationDetailType } from "pages/Sales/Transaction/CustomerQuotation/types";
import { Fragment } from "react";
import { IoSendSharp } from "react-icons/io5";
import { FilterType } from "../types";
import { APPROVAL } from "pages/Sales/Register/utils/vars";
import { calculateSummaryCQ } from "pages/Sales/Transaction/CustomerQuotation/utils/functions";
import { useToggle } from "utils";
import { ModalDelete } from "pages/Sales/Transaction/CustomerQuotation/components";

export function Item(props: {
  data: DetailCustomerQuotation
  index: number,
  dataPaging: Pick<FilterType, 'page' | 'limit' | 'pages' | 'total'>
  onDeleteSuccess: () => void
}) {
  const modalDelete = useToggle(false);
  const sumQtyOrder = props.data.detail.reduce((total, item) => total + item.qty_order, 0);
  const mapDetail = props.data.detail?.map((item): FormCustomerQuotationDetailType => ({
    customer_quotation_detail_id: item.customer_quotation_detail_id,
    product_id: item.product.product_id,
    product_name: item.product.product_name,
    product_barcode: item.product.product_barcode,
    qty_order: item.qty_order,
    received: item.received ?? 0,
    mr_unit_id: item.mr_unit.mr_unit_id,
    mr_unit_name: item.mr_unit.mr_unit_name,
    price_measure: item.price_measure,
    discount: item.discount,
    total: item.total,
    project_id: item.project.project_id,
    project_name: item.project.project_name,
    tax_id: item.tax.tax_id,
    tax_name: item.tax.tax_name,
    tax_rate: item.tax.rate,
    location_id: item.location.location_id,
    location_name: item.location.location_name,
    soh: item.soh
  }))
  const summary = calculateSummaryCQ({ detail: mapDetail, freight: props.data.freight })

  return (
    <Fragment>
      <tr >
        <td>{TableNumber({ dataLength: props.dataPaging.limit, pageCurrent: props.dataPaging.page, index: props.index, limit: props.dataPaging.limit, totalData: props.dataPaging.total })}</td>
        <td>{props.data.trx_code}</td>
        <td>-</td>
        <td>{moment(props.data.transaction_date).format("DD/MM/YYYY")}</td>
        <td>{props.data.employee.employee_name}</td>
        <td>{props.data.customer.customer_name}</td>
        <td>{props.data.location.location_name}</td>
        <td>{APPROVAL[props.data.approve_status ?? '1']}</td>
        <td className="text-right">{sumQtyOrder.toLocaleString('id-ID')}</td>
        <td className="text-right">{summary.totalAmount.toLocaleString()}</td>
        <td className="cursor-pointer">
          <div className="flex items-center gap-2">
            <PermissionLink 
              permission="SR063" 
              target="_blank" 
              rel="noopener noreferrer" 
              to={`/sales/transaction/customer-quotation/edit/${props.data.customer_quotation_id}`} 
              className="mt-1"
            >
              <IoSendSharp />
            </PermissionLink>
            {props.data.approve_status === APPROVAL.PENDING ? (
              <ActionButton.Delete color='red' onClick={modalDelete.toggle} />
            ) : null}
          </div>
        </td>
      </tr>

      <ModalDelete
        data={{
          approval_status: props.data.approve_status,
          customer_quotation_id: props.data.customer_quotation_id,
          transaction_date: props.data.transaction_date,
          trx_code: props.data.trx_code,
          customer_id: props.data.customer.customer_id,
          customer_name: props.data.customer.customer_name
        }}
        modalState={modalDelete}
        onSuccess={props.onDeleteSuccess}
      />
    </Fragment>
  )
}