// Types
import type { MenuType } from "../types"

export const accountsMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/accounts/register",
      permission: "AR"
    },
    {
      label: "TRANSACTION",
      link: "#",
      permission: "AT",
      children: [
        { label: "OPENING BALANCE", link: "/accounts/transaction/opening-balance", permission: "AT01" },
        { label: "GENERAL ENTRY", link: "/accounts/transaction/general-entry", permission: "AT02" },
        { label: "CHART OF REGISTER", link: "/accounts/transaction/chart-of-register", permission: "AT03" },
        { label: "TAX RECORD", link: "/accounts/transaction/task-record", permission: "AT05" },
      ]
    },
    {
      label: "REPORT",
      link: "#",
      permission: "AP",
      children: [
        { label: "BALANCE SHEET", link: "/accounts/report/balance-sheet", permission: "AP01" },
        { label: "PROFIT AND LOSS", link: "/accounts/report/profit-loss", permission: "AP02" },
        { label: "ACCOUNT LIST SUMMARY", link: "/accounts/report/account-list-summary", permission: "AP03" },
        { label: "ACCOUNT LIST DETAIL", link: "/accounts/report/account-list-detail", permission: "AP04" },
        { label: "GENERAL LEDGER SUMMARY", link: "/accounts/report/general-ledger-summary", permission: "AP05" },
        { label: "GENERAL LEDGER DETAIL", link: "/accounts/report/general-ledger-detail", permission: "AP06" },
        { label: "BUGGET ANALIST", link: "/accounts/report/bugget-analist", permission: "AP07" },
        { label: "GENERAL JOURNAL TRANSACTION", link: "/accounts/report/general-journal-transactions", permission: "AP08" },
        { label: "TRANSACTION JOURNAL", link: "/accounts/report/transaction-journal", permission: "AT04" },
        { label: "DASHBOARD", link: "/accounts/report/dashboard", permission: "AT06" }
      ]
    },
    {
      label: "JOURNAL",
      link: "/accounts/journal",
      permission: "AJ",
    },
    {
      label: "FIND",
      link: "/accounts/find",
      permission: "AF"
    }
  ] as MenuType[]
}