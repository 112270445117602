// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton, ErrorText, NumberInput } from "components"
import { ItemView } from "../../ItemView"

// Contexts
import { FilterContext } from "../../../contexts"
import { EditContext } from ".."

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { FormType, ItemType } from "../../../utils"

// Utils
import { convertNumber, useApi, useToggle } from "utils"
import { getHistoryBalance, getOpeningBalance, useHistoryCoa } from "../../../utils"

export function ListItem(params: {
  index: number
  item: ItemType
  level: number
}) {
  // Hooks
  const api = useApi()
  const { account_number, sub_header, zero_value } = useContext(FilterContext)
  const { edit, setEdit } = useContext(EditContext)
  const { isActive, setActive } = useToggle(false)

  // Form
  const { control, formState, getValues, setValue, trigger } =
    useFormContext<FormType>()

  const updateItem = () => {
    // Vars
    const list = getValues("list")
    const item = getValues(`list.${params.index}`)

    if (
      getOpeningBalance(list, "01") - getOpeningBalance(list, "02") !==
      getOpeningBalance(list, "03")
    ) {
      return toast.error("NET ASSETS must be balance with EQUITY")
    }

    setActive(true)

    toast
      .promise(
        api.post(
          `/openbalance/savesingleob?BalanceDate=${getValues("balance_date")}`,
          {
            coa_id: item.coa_id,
            opening_balance: item.opening_balance,
            normal_pos: item.normal_pos,
            linked_code: item.linked_code
          }
        ),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      )
      .then(() => {
        setValue(`list.${params.index}`, {
          ...item,
          old_opening_balance: item.opening_balance
        })

        setEdit(null)
      })
      .catch(() => {})
      .finally(() => {
        setActive(false)
      })
  }

  const calculateParent = (list: ItemType[], parent_id: number) => {
    // Vars
    // eslint-disable-next-line
    const selected_item = list.find((item) => item.coa_id === parent_id)!
    // eslint-disable-next-line
    const selected_item_index = list.findIndex(
      (item) => item.coa_id === parent_id
    )!

    setValue(
      `list.${selected_item_index}`,
      {
        ...selected_item,
        opening_balance: list
          .filter((item) => {
            return item.parent_id === selected_item.coa_id
          })
          .reduce((acc, { opening_balance }) => {
            return acc + opening_balance
          }, 0)
      },
      { shouldValidate: true }
    )

    return selected_item.parent_id
  }

  return (
    <Fragment>
      {params.item.linked_code === "LA03" ? (
        <HistoryBalance />
      ) : Boolean(
          params.item.header_level === 2 ? sub_header.isActive : true
        ) &&
        Boolean(
          params.item.opening_balance === 0 ? zero_value.isActive : true
        ) ? (
        <Controller
          control={control}
          name={`list.${params.index}`}
          render={({ field }) => {
            // Vars
            const is_header = field.value.header_level !== 3
            const is_view: boolean = edit === null && edit !== "date"

            const calculateCoaParent = () => {
              // Vars
              const list = getValues("list")

              //  ** START: Recalculate all of it's parent ** //
              // Vars
              let parent_id = field.value.parent_id

              while (parent_id !== -1) {
                parent_id = calculateParent(list, parent_id)
              }
              //  ** END: Recalculate all of it's parent ** //

              //  ** START: Recalculate HISTORY BALANCE and all of it's parent ** //
              // Vars
              const history_balance = getHistoryBalance(list)
              const assets = getOpeningBalance(list, "01")
              const liability = getOpeningBalance(list, "02")
              const coa_equity_detail = list
                .filter((item) => {
                  return (
                    item.group_coa_code === "03" &&
                    item.header_level === 3 &&
                    item.linked_code !== "LA03"
                  )
                })
                .reduce((acc, { opening_balance }) => {
                  return acc + opening_balance
                }, 0)

              if (history_balance.item) {
                setValue(`list.${history_balance.index}`, {
                  ...history_balance.item,
                  opening_balance: assets - liability - coa_equity_detail
                })

                // Vars
                let history_balance_parent_id = history_balance.item.parent_id

                while (history_balance_parent_id !== -1) {
                  history_balance_parent_id = calculateParent(
                    list,
                    history_balance_parent_id
                  )
                }
              }
              //  ** END: Recalculate HISTORY BALANCE and all of it's parent ** //
            }

            return (
              <Fragment>
                <tr key={params.index}>
                  <td>
                    <div
                      className={`flex ${
                        field.value.header_level === 3 && "font-normal"
                      }`}
                      style={{ paddingLeft: 16 + 10 * params.level }}>
                      {account_number.isActive && (
                        <Fragment>
                          <div>{field.value.coacode}</div>
                          <div className="divider divider-horizontal m-0" />
                        </Fragment>
                      )}

                      <div>{field.value.coa_name}</div>
                    </div>
                  </td>
                  <td className={`${!is_view && "p-0"} w-[100px] text-right ${!is_header && "font-normal"}`}>
                    {is_view ? convertNumber(field.value.opening_balance).intoCurrency : (
                      <NumberInput
                        decimalScale={0}
                        inputClass={`text-right ${
                          is_header && "font-extrabold"
                        }`}
                        disabled={
                          is_header ||
                          params.item.linked_code === "LA01" ||
                          Boolean(
                            edit !== "date" && Number(edit) !== params.index
                          )
                        }
                        value={field.value.opening_balance}
                        onValueChange={(value) => {
                          field.onChange({
                            ...field.value,
                            opening_balance: value.floatValue
                          })

                          calculateCoaParent()
                        }}
                      />
                    )}

                    {!is_header && (
                      <ErrorMessage
                        errors={formState.errors}
                        name={`list.${params.index}.opening_balance`}
                        render={({ message }) => <ErrorText text={message} />}
                      />
                    )}
                  </td>

                  {params.item.header_level === 3 &&
                  params.item.linked_code !== "LA01" ? (
                    <td>
                      {edit === null ? (
                        <ActionButton.Update
                          className="btn-sm"
                          permission={"AT013"}
                          onClick={() => {
                            setEdit(params.index)
                          }}
                        />
                      ) : (
                        edit === params.index && (
                          <section className="flex justify-center">
                            <ActionButton.Check
                              className="btn-sm"
                              permission="AT013"
                              loading={isActive ? "true" : undefined}
                              onClick={() => {
                                trigger(`list.${params.index}`).then((res) => {
                                  if (res) {
                                    updateItem()
                                  }
                                })
                              }}
                            />

                            <ActionButton.Close
                              loading={isActive ? "true" : undefined}
                              onClick={() => {
                                field.onChange({
                                  ...field.value,
                                  opening_balance:
                                    field.value.old_opening_balance
                                })

                                calculateCoaParent()
                                setEdit(null)
                              }}
                            />
                          </section>
                        )
                      )}
                    </td>
                  ) : (
                    <td />
                  )}
                </tr>
              </Fragment>
            )
          }}
        />
      ) : (
        <tr />
      )}

      {getValues("list").map((item, key) => {
        if (item.parent_id === params.item.coa_id) {
          return (
            <ListItem
              key={key}
              index={key}
              item={item}
              level={params.level + 1}
            />
          )
        }

        return <Fragment key={key} />
      })}
    </Fragment>
  )
}

function HistoryBalance() {
  // Hooks
  const coa = useHistoryCoa()
  const { edit } = useContext(EditContext)
  const { zero_value } = useContext(FilterContext)

  // Form
  const { control } = useFormContext<FormType>()
  const list = useWatch({
    control,
    name: "list"
  })

  // Vars
  const history_balance = list.find((item) => item.linked_code === "LA03")
  const amount =
    list.find((item) => item.linked_code === "LA03")?.opening_balance ?? 0

  if (Boolean(amount === 0 ? zero_value.isActive : true)) {
    return (
      <ItemView
        coa_code={coa.coa_code}
        label={coa.coa_name}
        labelClassName="font-normal"
        level={3}
        amount={amount}
        code={history_balance?.coacode}
        isAmountOnly={edit === null}
      />
    )
  }

  return <tr />
}
