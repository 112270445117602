import { useContext } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { DataList } from "../types"
import { useApi } from "../../../../../utils"
import { Render } from "../utils"

export function CreateModal(props: {
  project_name?: string
  address?: string
  note?: string,
  country_id?: string,
  province_id?: string,
  regency_id?: string,
  subdistrict_id?: string,
  village_id?: string,
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)

  type CreateData = Omit<DataList, "project_id" | "country_name" | "province_name" | "regency_name" | "subdistrict_name" | "village_name" | "countries_id"> 

  const defaultValues= {
    project_name: "",
    address: "",
    note: "",
    country_id: Number(props.country_id) ?? null,
    province_id: Number(props.province_id) ?? null,
    regency_id: Number(props.regency_id) ?? null,
    subdistrict_id: Number(props.subdistrict_id) ?? null,
    village_id: Number(props.village_id) ?? null,
  }
  const validationSchema = yup.object().shape({ 
    project_name: yup.string().label("Project name").required().max(100, "Must be 100 characters or less"),
    address: yup.string().label("Address").optional().max(100, "Must be 100 characters or less"),
    note: yup.string().label("Note").optional()
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/project/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        props?.refetch && props.refetch()
        toggle()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="SETUP | CREATE PROJECT" closeModal={props.toggle}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
          hintText={(
            <HintList
              title="Creating a Project"
              bottomList={[
                {
                  label: "Button: Save Record",
                  list: [
                    {
                      label: "Purpose",
                      value: "To save the project details after entering all necessary information."
                    },
                    {
                      label: "Action",
                      value: "Click this button to store the project data and integrate it into the system."
                    },
                  ]
                },
                {
                  label: "Button: Tutorial",
                  list: [
                    {
                      label: "Purpose",
                      value: "Provides guidance or a step-by-step walkthrough on how to create and manage projects."
                    },
                    {
                      label: "Action",
                      value: "Click this button to access a tutorial or help section that explains the project setup process in detail."
                    },
                  ]
                },
              ]}
              list={[
                {
                  label: "Project Name",
                  list: [
                    {
                      label: "Definition",
                      value: "The name of the project."
                    },
                    {
                      label: "Purpose",
                      value: "Provides a clear and descriptive title for easy identification and reference."
                    },
                    {
                      label: "Example",
                      value: "New Office Build, Marketing Campaign Q4, Product Launch 2024."
                    }
                  ]
                },
                {
                  label: "Country",
                  list: [
                    {
                      label: "Definition",
                      value: "The country where the project is being executed."
                    },
                    {
                      label: "Purpose",
                      value: "Helps in categorizing and managing projects based on their geographical location."
                    },
                    {
                      label: "Options",
                      value: "Select from a list of countries."
                    },
                  ]
                },
                {
                  label: "Province",
                  list: [
                    {
                      label: "Definition",
                      value: "The province or state within the country where the project is based."
                    },
                    {
                      label: "Purpose",
                      value: "Provides more specific geographical information within the country."
                    },
                    {
                      label: "Options",
                      value: "Select from a list of provinces or states."
                    },
                  ]
                },
                {
                  label: "Regency",
                  list: [
                    {
                      label: "Definition",
                      value: "The regency or district within the province where the project is situated."
                    },
                    {
                      label: "Purpose",
                      value: "Further refines the location details for better project management."
                    },
                    {
                      label: "Options",
                      value: "Select from a list of regencies or districts."
                    },
                  ]
                },
                {
                  label: "Subdistrict",
                  list: [
                    {
                      label: "Definition",
                      value: "The subdistrict or smaller administrative unit within the regency."
                    },
                    {
                      label: "Purpose",
                      value: "Provides additional granularity for location details."
                    },
                    {
                      label: "Options",
                      value: "Select from a list of subdistricts."
                    },
                  ]
                },
                {
                  label: "Village",
                  list: [
                    {
                      label: "Definition",
                      value: "The village or neighborhood where the project is located."
                    },
                    {
                      label: "Purpose",
                      value: "Offers the most precise location information."
                    },
                    {
                      label: "Options",
                      value: "Select from a list of villages or neighborhoods."
                    },
                  ]
                },
                {
                  label: "Full Address",
                  list: [
                    {
                      label: "Definition",
                      value: "The complete address for the project, including street number and name."
                    },
                    {
                      label: "Purpose",
                      value: "Ensures accurate location details for logistics, correspondence, and operations."
                    },
                    {
                      label: "Example",
                      value: "456 Elm Street, Building 789."
                    },
                  ]
                },
                {
                  label: "Note",
                  list: [
                    {
                      label: "Definition",
                      value: "Any additional information or remarks about the project."
                    },
                    {
                      label: "Purpose",
                      value: "Provides context or special instructions relevant to the project."
                    },
                    {
                      label: "Example",
                      value: "Construction starts in June, Marketing team contact: Jane Doe."
                    },
                  ]
                }
              ]}
            />
          )}
        >
          <FormProvider {...methods}>
            <FormSection />
          </FormProvider>
        </Modal.Body>

        <Modal.Footer className="text-end">
          <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
        </Modal.Footer>
        </form>
    </Modal>
  )
}