import { useEffect, useState } from "react"
import type { SelectOptionType, SuccessFetch } from "../../../../../../../../types"
import { useApi } from "../../../../../../../../utils"

export function useCOAExpences() {
  const api = useApi()
  const [data, setData] = useState<SelectOptionType[]>([])
  const [isLoading, setLoading] = useState(true)

  const refetch = () => {
    setLoading(true)

    api.get("/coa/detailcoabygroupcode?group_code=06")
      .then((res: SuccessFetch<{
        payload: {
          coa_id: number
          coa_code: string
          coa_name: string
        }[]
      }>) => { setData(res.data.payload.map(item => { return { label: `${item.coa_code} ${item.coa_name}`, value: item.coa_id.toString() } })) })
      .catch(() => setData([]))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refetch()

    return () => {
      setData([])
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading, refetch }
}