/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from "react"
import { ApproveType, FilterQuotation } from "../types"
import { ApproveAction } from "./ApproveAction"
import moment from "moment"
import { APPROVAL } from "utils"
import { TableNumber } from "components"
// import { ApproveAction } from "./ApproveAction"

type rowType = {
  data: ApproveType
  index: number
  pagination: FilterQuotation
}

export function TableList({
  data,
  index,
  pagination
}: rowType) {
  return (
    <Fragment>
      <tr key={index}>
        <td>
          {TableNumber({
            pageCurrent: pagination?.page,
            dataLength: pagination?.size!,
            index: index,
            limit: pagination?.limit,
            totalData: pagination?.total
          })}
        </td>
        <td>
          <ApproveAction data={data} />
        </td>
        <td>{data.trx_code}</td>
        <td>{moment(data.transaction_date).format("DD/MM/YYYY")}</td>
        <td>{data.customer.customer_name}</td>
        <td>{data.location.location_name}</td>
        <td>{data.memo}</td>
        <td>{APPROVAL[data.approve_status]}</td>
        <td>
          {data?.approve_date
            ? moment(data.approve_date).format("DD/MM/YYYY")
            : ""}
        </td>
      </tr>
    </Fragment>
  )
}
