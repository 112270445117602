// React
import { useState } from "react"

// Components
import { Button, PermissionLink } from "components"
import { TabSection } from "pages/Purchase/Transaction/components"
import { Delivery, Journal, Payment, PaymentBill, Rejected } from "./components"

// Third-Party Libraries
import { useParams } from "react-router-dom"

// Utils
import { tab_list } from "./utils"

export function Tab() {
  // Vars
  const content = [Delivery, Payment, PaymentBill, Rejected, Journal]

  // Hooks
  const { id } = useParams()
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <section>
      <TabSection
        activeIndex={currentTab}
        list={tab_list}
        onChangeTab={setCurrentTab}
        customElement={(
          <>
            {id && (
              <PermissionLink
                to={`/purchase/transaction/close-purchase/${id}`}
                className="min-w-[126px]"
              >
                <Button
                  size="sm"
                  color="ghost"
                  className="no-animation w-full bg-[#3c3c3c] hover:bg-primary hover:text-black whitespace-nowrap text-white"
                >
                  CLOSE PURCHASE
                </Button>
              </PermissionLink>
            )}
          </>
        )}
      />

      {content.map((Element, key) => {
        if (currentTab === key) {
          return <Element key={key} />
        }

        return null
      })}
    </section>
  )
}