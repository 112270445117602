/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { today } from "utils"
import { FormCustomerQuotationType } from "../types"
import { useDetailCustomerQuotation } from "../utils/hooks"
import { FormSection } from './FormSection'
import { APPROVAL } from "pages/Sales/Register/utils/vars"

type CustomerQuotationDetailProps = {
  customer_quotation_id: string
}

export default function CustomerQuotationDetail({ customer_quotation_id }: CustomerQuotationDetailProps) {
  const quotation = useDetailCustomerQuotation({ customer_quotation_id })

  const defaultValues: FormCustomerQuotationType = useMemo(
    () => ({
      trx_code: quotation.data?.trx_code ?? "",
      customer_quotation_id: quotation.data?.customer_quotation_id ?? NaN,
      customer_id: quotation.data?.customer.customer_id ?? NaN,
      customer_name: quotation.data?.customer.customer_name ?? "",
      employee_id: quotation.data?.employee.employee_id ?? NaN,
      payment_term: quotation.data?.payment_term.payment_term_id ?? NaN,
      location_id: quotation.data?.location.location_id ?? NaN,
      memo: quotation.data?.memo ?? "Customer Quotation transaction",
      promize_date: quotation.data?.promise_date ?? today(),
      referensi: quotation.data?.referensi ?? "",
      tax_inclusive: true,
      transaction_date: quotation.data?.transaction_date ?? today(),
      shipped: quotation.data?.address ?? "",
      payment_term_name: quotation.data?.payment_term.payment_term_name?.toString() ?? "",
      price_level_name: quotation.data?.price_level_name ?? "",
      detail:
        quotation.data?.detail?.map((item) => ({
          customer_quotation_detail_id: item.customer_quotation_detail_id,
          product_id: item.product.product_id,
          product_name: item.product.product_name,
          product_barcode: item.product.product_barcode,
          qty_order: item.qty_order,
          received: item.received ?? 0,
          mr_unit_id: item.mr_unit.mr_unit_id,
          mr_unit_name: item.mr_unit.mr_unit_name,
          price_measure: item.price_measure,
          discount: item.discount,
          total: item.total,
          project_id: item.project.project_id,
          project_name: item.project.project_name,
          tax_id: item.tax.tax_id,
          tax_name: `${item.tax.tax_name} (${item.tax_rate}%)`,
          tax_rate: item.tax_rate,
          location_id: item.location.location_id,
          location_name: item.location.location_name,
          soh: item?.soh ?? 0
        })) ?? [],
      freight: quotation.data?.freight ?? 0,
      approved_by: quotation.data?.approve_by ? quotation.data?.approve_by.employee_name : "",
      approved_by_name: quotation.data?.approve_by ? quotation.data?.approve_by.employee_name : "",
      approval_status: quotation.data?.approve_status ?? 1,
      approval_status_text: APPROVAL[quotation.data?.approve_status ?? 1],
      reminder: Object.is(quotation.data?.reminder, null)
        ? null
        : {
          card_group: quotation.data?.reminder?.card_group ?? NaN,
          card_id: quotation.data?.reminder?.card_id ?? NaN,
          memo: quotation.data?.reminder?.memo ?? "",
          promise_date: quotation.data?.reminder?.promise_date ?? today(),
          transaction_date: quotation.data?.reminder?.transaction_date ?? today(),
          trx_code: quotation.data?.reminder?.trx_code ?? "",
          vendor_id: quotation.data?.reminder?.card_id ?? NaN
        }
    }),
    [quotation.data]
  )

  const methods = useForm<FormCustomerQuotationType>({ defaultValues })

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <FormSection
        isEdit
        approvalView
        onSubmit={() => null}
      />
    </FormProvider>
  )
}
