/* eslint-disable react-hooks/exhaustive-deps */
import { BaseTable } from "components"
import moment from "moment"
import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { convertNumber, useAccounts, useCoaByCode } from "utils"
import {
  JournalPaymentDepositCustomer,
  PaymentDepositCustomerType
} from "../../../types"
import { generateJournalPaymentDepositCustomer } from "../../../utils/functions"

type TableJournalEntryProps = {
  approvalStatus?: number
  display?: boolean
  displayCreateButton?: boolean
}

export const TableJournalEntry = ({
  display = true
}: TableJournalEntryProps) => {
  const methods = useFormContext<PaymentDepositCustomerType>()
  const accounts = useAccounts()
  const [data, setData] = useState<JournalPaymentDepositCustomer[]>([])
  const la06 = useCoaByCode("LA06")
  const [amount, balance, date, trx_code, memo, coa_id] = useWatch({
    control: methods.control,
    name: [
      "amount",
      "coa_balance",
      "transaction_date",
      "trx_code",
      "memo",
      "coa_id"
    ]
  })

  useEffect(() => {
    if (coa_id) {
      const coa_payment =
        accounts.data.find((item) => item.value === coa_id.toString())?.label ??
        ""

      const journal = generateJournalPaymentDepositCustomer({
        coa_la06: la06.data,
        coa_payment,
        amount,
        balance: balance ?? 0,
        date,
        trx_code: trx_code ?? "",
        memo
      })

      setData(journal ?? [])
    }
  }, [amount, balance, date, trx_code, memo])

  return (
    <div className={`${display ? "" : "hidden"} overflow-x-auto`}>
      <div className="font-bold uppercase text-lg mb-2">Journal Entry</div>

      <BaseTable>
        <thead className="uppercase">
          <tr>
            <th>NO</th>
            <th>Date</th>
            <th>Id#</th>
            <th>Account</th>
            <th>Memo</th>
            <th>Debet</th>
            <th>Credit</th>
          </tr>
        </thead>

        <tbody>
          {data
            .filter(item => item.credit || item.debet)
            .map((item, key) => {
            return (
              <tr>
                <td className="text-center">{key + 1}</td>
                <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                <td>{item.idm}</td>
                <td>{item.coa}</td>
                <td>{item.memo}</td>
                <td className="text-right">
                  {item.debet > 0 ? convertNumber(item.debet).intoCurrency : ""}
                </td>
                <td className="text-right text-red-500">
                  {item.credit > 0
                    ? convertNumber(item.credit).intoCurrency
                    : ""}
                </td>
              </tr>
            )
          })}
        </tbody>
      </BaseTable>
    </div>
  )
}
