/* eslint-disable react-hooks/exhaustive-deps */
import { JournalTable } from "components"
import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { FormType } from "../types"
import { getCoaTaxJournal, totalAmountJournal } from "../utils/function"
import moment from "moment"
import { convertNumber } from "utils"

export const TableJournal = () => {
  const methods = useFormContext<FormType>()
  const [datajournal, transaction_date, trx_code] = methods.watch([
    "datajournal",
    "transaction_date",
    "trx_code"
  ])

  const journalList = useMemo(
    () => getCoaTaxJournal(datajournal),
    [JSON.stringify(datajournal)]
  )

  const total = totalAmountJournal(datajournal)
  const amountWidth: string = "!w-[150px]"

  return (
    <JournalTable>
      <JournalTable.Content>
        <thead>
          <tr>
            <th className="w-[10px]">DATE</th>
            <th>IDM</th>
            <th>ACCOUNT</th>
            <th className={amountWidth}>DEBET</th>
            <th className={amountWidth}>CREDIT</th>
          </tr>
        </thead> 

        <tbody>
          {journalList
            .sort((a, b) => a.position - b.position)
            .filter(item => item.amount)
            .map((item, key) => (
            <tr key={key}>
              <td>{moment(transaction_date).format("DD/MM/YYYY")}</td>
              <td>{trx_code}</td>
              <td>{item.coa_name}</td>
              <td className="text-right">{item.position === 1 ? (convertNumber(item.amount).intoCurrency ?? "0") : ""}</td>
              <td className="text-right">{item.position === 2 ? (convertNumber(item.amount).intoCurrency ?? "0") : ""}</td>
            </tr>
          ))}
        </tbody>
      </JournalTable.Content>

      <JournalTable.Amount>
        <tfoot>
          <tr className="text-xl font-bold">
            <th className="text-right">TOTAL</th>
            <th className={`text-right ${amountWidth}`}>{convertNumber(total.debet).intoCurrency}</th>
            <th className={`text-right ${amountWidth}`}>{convertNumber(total.credit).intoCurrency}</th>
          </tr>
        </tfoot>
      </JournalTable.Amount>
    </JournalTable>
  )
}
