// React
import { Fragment, useContext } from "react"

// Contexts
import { AccountContext } from "contexts"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"

// Types
import type { FormType } from "../utils"

// Utils
import { convertNumber, useCoaByCode } from "utils"

export function JournalList(): JSX.Element {
  return <></>
}

function PaymentAccount(): JSX.Element {
  // Hooks
  const account = useContext(AccountContext)
  const tracking = useCoaByCode("LA07")

  // Form
  const { control } = useFormContext<FormType>()
  const coa_id = useWatch({
    control,
    name: "coa_id"
  })
  const memo = useWatch({
    control,
    name: "memo"
  })
  const payment_amount = useWatch({
    control,
    name: "payment_amount"
  })
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  // Vars
  const formattedDate = moment(transaction_date).format("DD/MM/YYYY")
  const coa = account.data.find(item => item.value === coa_id)?.label ?? "-"
  const formattedAmount = convertNumber(payment_amount).intoCurrency

  if (coa_id && payment_amount) {
    return (
      <Fragment>
        <tr>
          <td>{formattedDate}</td>
          <td>{trx_code}</td>
          <td>{tracking.data}</td>
          <td>{memo}</td>
          <td className="text-right">{formattedAmount}</td>
          <td className="text-right"></td>
        </tr>

        <tr>
          <td>{formattedDate}</td>
          <td>{trx_code}</td>
          <td>{coa}</td>
          <td>{memo}</td>
          <td className="text-right"></td>
          <td className="text-right">{formattedAmount}</td>
        </tr>
      </Fragment>
    )
  }

  return (
    <Fragment />
  )
}

function PaymentDeposit(): JSX.Element {
  // Hooks
  const tracking = useCoaByCode("LA07")
  const asset = useCoaByCode("LA09")

  // Form
  const { control } = useFormContext<FormType>()
  const memo = useWatch({
    control,
    name: "memo"
  })
  const deposit_amount = useWatch({
    control,
    name: "deposit_amount"
  })
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  // Vars
  const formattedDate = moment(transaction_date).format("DD/MM/YYYY")
  const formattedAmount = convertNumber(deposit_amount).intoCurrency

  if (deposit_amount) {
    return (
      <Fragment>
        <tr>
          <td>{formattedDate}</td>
          <td>{trx_code}</td>
          <td>{tracking.data}</td>
          <td>{memo}</td>
          <td className="text-right">{formattedAmount}</td>
          <td className="text-right"></td>
        </tr>

        <tr>
          <td>{formattedDate}</td>
          <td>{trx_code}</td>
          <td>{asset.data}</td>
          <td>{memo}</td>
          <td className="text-right"></td>
          <td className="text-right">{formattedAmount}</td>
        </tr>
      </Fragment>
    )
  }

  return (
    <Fragment />
  )
}

function Total(): JSX.Element {
  // Form
  const { control } = useFormContext<FormType>()
  const deposit_amount = useWatch({
    control,
    name: "deposit_amount"
  })
  const payment_amount = useWatch({
    control,
    name: "payment_amount"
  })

  // Vars
  const formattedAmount = convertNumber(deposit_amount + payment_amount).intoCurrency

  return (
    <tr>
      <td colSpan={4} />
      <td className="text-right">{formattedAmount}</td>
      <td className="text-right">{formattedAmount}</td>
    </tr>
  )
}

JournalList.PaymentAccount = PaymentAccount
JournalList.PaymentDeposit = PaymentDeposit
JournalList.Total = Total