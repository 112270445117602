// React
import { PropsWithChildren } from "react"

export function JournalTable(params: PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="h-full flex flex-col">
      {params.children}
    </div>
  )
}

function Amount(params: PropsWithChildren<{}>): JSX.Element {
  return (
    <table className="table table-bordered">
      {params.children}
    </table>
  )
}

function Content(params: PropsWithChildren<{}>): JSX.Element {
  return (
    <section className="grow">
      <table className="table table-bordered">
        {params.children}
      </table>
    </section>
  )
}

JournalTable.Amount = Amount
JournalTable.Content = Content