import { Card, DateInput, Input, NumberInput, Textarea } from "components"
import moment from "moment"
import { Controller, useFormContext } from "react-hook-form"
import { PaymentDepositCustomerType } from "../types/PaymentDepositCustomer"
import { DropdownPay } from "./DropdownPay"

type FormPaymentDepositProps = {
  approvalView?: boolean
  isEdit?: boolean
}

export const FormPaymentDeposit = ({ approvalView, isEdit }: FormPaymentDepositProps) => {
  const { control } = useFormContext<PaymentDepositCustomerType>()

  return (
    <Card>
      <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <section>
            <section className="flex gap-3">
              <div className="grow">
                <DropdownPay isDisabled={approvalView} />
              </div>
            </section>
          </section>

          <Controller
            control={control}
            name="memo"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label="MEMO"
                disabled={approvalView}
                defaultValue={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="link"
            render={({ field }) => (
              <Input
                disabled
                label="LINK TO SALES ID"
                defaultValue={field.value}
              />
            )}
          />
        </section>

        <section className="flex flex-col gap-3">
          <Controller
            control={control}
            name="no_cheque"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                disabled={approvalView}
                label="CC | DEBIT NO. | CHAQUE NO."
                value={field.value}
                onChange={(data) => {
                  const value = data.target.value

                  if (!isNaN(Number(value))) {
                    field.onChange(value)
                  }
                }}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="transaction_date"
            render={({ field, fieldState }) => (
              <DateInput
                label="RECEIVE DATE"
                disabled={approvalView}
                error={fieldState.error?.message}
                selected={moment(field.value).toDate()}
                onChange={(date) => {
                  const selectedDate: string = moment(date).format("YYYY-MM-DD")
                  field.onChange(selectedDate)
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="amount"
            render={({ field, fieldState }) => (
              <NumberInput
                disabled={approvalView}
                label="PAYMENT AMOUNT"
                textRight
                value={field.value}
                ref={field.ref}
                onValueChange={(value) => field.onChange(value.floatValue)}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="shipped"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                disabled={approvalView}
                label="BRANCH LOCATION"
                defaultValue={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={fieldState.error?.message}
              />
            )}
          />
        </section>
      </Card.Body>
    </Card>
  )
}
