// React
import { useContext } from "react"

// Components
import { BaseTable, Button, Loading, Modal } from "components"

// Contexts
import { DataContext } from "../contexts"

// Third-Party Libraries
import toast from "react-hot-toast"
import { read, utils } from "xlsx"

// Utils
import { useToggle } from "utils"
import { headerBlankExample, useDataImport, useMasterData } from "../utils"

export function UploadFile(): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <>
      <Button
        color="primary"
        onClick={toggle}
      >
        UPLOAD FILE
      </Button>

      {isActive && <ModalSection toggle={toggle} />}
    </>
  )
}

function ModalSection(params: {
  toggle: () => void
}): JSX.Element {
  // Hooks
  const { assignData } = useContext(DataContext)
  const { data, setData } = useDataImport()
  const { isLoading, refetch } = useMasterData()

  return (
    <Modal
      isOpen
      title="UPLOAD FILE"
      closeModal={params.toggle}
    >
      <Modal.Body contentClassName="flex flex-col gap-3">
        <section>
          <label
            htmlFor="upload"
            className="btn btn-primary rounded-none"
          >
            SELECT FILE
          </label>

          <input
            hidden
            type="file"
            name="upload"
            id="upload"
            onChange={(e) => {
              if (e.target.files?.length) {
                const reader = new FileReader()
                reader.onload = (e) => {
                  if (e.target) {
                    const data = e.target.result
                    const workbook = read(data, { type: "array" })
                    const sheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[sheetName]
                    const headers: string[] = utils.sheet_to_json(worksheet, { header: 1 })[0] as string[]
                    const json = utils.sheet_to_json(worksheet)

                    if (!json.length) {
                      return toast.error("File is empty")
                    }

                    refetch().then((value) => {
                      const prev: any[] = json
                      const headerData: { [key: string]: any } = {}

                      headers.map(item => {
                        headerData[item] = headerBlankExample[item]
                        return null
                      })

                      const defaultSize = value.size.find(item => item.label === "m3")!

                      setData(prev.map(item => {
                        return {
                          ...headerData,
                          ...item,
                          length: item.length ?? "0",
                          width: item.width ?? "0",
                          height: item.height ?? "0",
                          mr_product_group_id: value.group.find(i => i.label.toLowerCase().toLowerCase() === item.mr_product_group?.toLowerCase())?.value ?? "",
                          mr_product_style_id: value.style.find(i => i.label.toLowerCase() === item.mr_product_style?.toLowerCase())?.value ?? "",
                          mr_product_colour_id: value.colour.find(i => i.label.toLowerCase() === item.mr_product_colour?.toLowerCase())?.value ?? "",
                          mr_product_category_id: value.product_category.find(i => i.label.toLowerCase() === item.mr_product_category?.toLowerCase())?.value ?? "",
                          mr_product_material_id: value.material.find(i => i.label.toLowerCase() === item.mr_product_material?.toLowerCase())?.value ?? "",
                          main_size_id: value.size.find(i => i.label.toLowerCase() === item.main_size?.toLowerCase())?.value ?? defaultSize.value,
                          mr_category_customer_id: value.category.find(i => i.label.toLowerCase() === item.mr_category_customer?.toLowerCase())?.value ?? "",
                          weight_unit_id: value.unit.find(i => i.label.toLowerCase() === item.weight_unit?.toLowerCase())?.value ?? "",
                          product_id: value.product.find(i => i.label.toLowerCase() === item.product_barcode?.toLowerCase())?.value ?? null,
                          size_type: item.main_size ? "1" : "2",
                          type: "1"
                        }
                      }))
                    })
                  }
                }

                reader.readAsArrayBuffer(e.target.files[0])
                e.target.value = ""
              }
            }}
          />
        </section>

        {isLoading ? (
          <Loading loading={isLoading} />
        ) : (
          <>
            {data.length ? (
              <section>
                <div className="font-bold">FILE PREVIEW</div>

                <div className="overflow-auto">
                  <BaseTable>
                    <thead>
                      <tr>
                        {Object.keys(data[0]).map((item, key) => <th key={key}>{item}</th>)}
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((item, key) => (
                        <tr key={key}>
                          {Object.keys(data[0]).map((i, key) => <td key={key}>{item[i]}</td>)}
                        </tr>
                      ))}
                    </tbody>
                  </BaseTable>
                </div>
              </section>
            ) : (
              <></>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          color="primary"
          onClick={() => {
            if (!data.length) {
              return toast.error("No data available")
            }

            assignData(data)
            params.toggle()
          }}
        >
          VALIDATE & SAVE
        </Button>
      </Modal.Footer>
    </Modal>
  )
}