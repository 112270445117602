// React
import { Fragment } from "react"

// Components
import { Button, HeaderMenu, Loading, Select } from "components"
import { TableSection } from "./components"

// Utils
import { useLocation } from "utils"
import { dummyYear, monthlyList, useDummyData, yearlyList } from "./utils"

export default function Key(): JSX.Element {
  // Hooks
  const dummyData = useDummyData()
  const location = useLocation()

  return (
    <section className="flex flex-col gap-3">
      <HeaderMenu title="8 KEY ANALYTIC" />

      <section className="flex flex-wrap justify-end gap-3">
        <div className="max-w-[200px] w-full">
          <Select
            placeholder="Select Year"
            options={dummyYear}
          />
        </div>

        <div className="max-w-[200px] w-full">
          <Select
            placeholder="Select Location"
            isLoading={location.isLoading}
            options={location.data}
          />
        </div>

        <Button
          color="primary"
          onClick={dummyData.refetch}
        >
          UPDATE
        </Button>
      </section>

      {dummyData.isLoading ? (
        <Loading loading />
      ) : (
        <Fragment>
          <TableSection
            headerList={["BUDGET", "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]}
            list={monthlyList}
          />
    
          <TableSection
            headerList={["BUDGET", "2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013"]}
            list={yearlyList}
          />
        </Fragment>
      )}
    </section>
  )
}