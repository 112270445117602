// Components
import { Loading } from "components/feedbacks"
import { Button } from "./Button"

// Icons
import { IconType } from "react-icons"
import { BsFillJournalBookmarkFill } from "react-icons/bs"
import { TbPrinter, TbMail, TbHelp, TbFileTypeXls, TbDownload, TbTrash, TbPaperclip, TbXboxX } from "react-icons/tb"
import { IoSearch } from "react-icons/io5";

// Types
import { CommandType } from "types"

// Vars
const type: {
  [key: string] : {
    icon: IconType
    text: string
    textClass?: string
  }
} = {
  attachment: {
    icon: TbPaperclip,
    text: "ATTACHMENT"
  },
  cancel: {
    icon: TbXboxX,
    text: "CANCEL",
    textClass: "text-error hover:!text-black"
  },
  delete: {
    icon: TbTrash,
    text: "DELETE RECORD",
    textClass: "text-error hover:!text-black"
  },
  email: {
    icon: TbMail,
    text: "E-MAIL"
  },
  export: {
    icon: TbFileTypeXls,
    text: "EXPORT TO"
  },
  help: {
    icon: TbHelp,
    text: "HELP TIP"
  },
  journal: {
    icon: BsFillJournalBookmarkFill,
    text: "JOURNAL"
  },
  print: {
    icon: TbPrinter,
    text: "PRINT"
  },
  save: {
    icon: TbDownload,
    text: "SAVE RECORD"
  },
  advanced: {
    icon: IoSearch,
    text: "ADVANCED"
  },
}

export function CommandButton(params: CommandType) {
  // Vars
  const Icon = type[params.actiontype].icon

  return (
    <Button
      {...params}
      className="flex flex-col h-16 bg-base-200 border-[1px] border-base-300 px-0 w-24 hover:bg-primary hover:text-black border-white"
      color="ghost"
      permission={params?.permission}
      disabled={params.loading === "true" || params.disabled}
      loading={undefined}
    >
      {params.loading ? <Loading loading /> : <Icon size={24} />}
      <div className={type[params.actiontype].textClass}>{type[params.actiontype].text}</div>
    </Button>
  )
}