// Pages
import Find from "pages/FindSection"
import PurchaseRegister from "pages/Purchase/Register"
import Journal from "pages/Finance/Journal"
import OpeningBalance from "pages/Accounts/Transaction/OpeningBalance"
import PurchaseOrder from "pages/Purchase/Transaction/PurchaseOrder"
import ClosePurchase from "pages/Purchase/Transaction/ClosePurchase"
import EditPurchaseOrder from "pages/Purchase/Transaction/PurchaseOrder/Edit"
import GeneralEntry from "pages/Finance/Transaction/GeneralEntry"
// import Dashboard from "pages/Purchase/Dashboard"
import ApproveBills from "pages/Purchase/Transaction/ApproveBills"
// ** Deposit Vendor
import DepositVendor from "pages/Purchase/Transaction/DepositVendor"
import EditDepositVendor from "pages/Purchase/Transaction/DepositVendor/Edit"
// ** Enter Bill
import EnterBill from "pages/Purchase/Transaction/EnterBill"
import EditEnterBill from "pages/Purchase/Transaction/EnterBill/Edit"
// ** Payment Bill
import EditPaymentBill from "pages/Purchase/Transaction/PaymentBill/Edit"
import PaymentBill from "pages/Purchase/Transaction/PaymentBill"
// ** Reject Item
import EditRejectItem from "pages/Purchase/Transaction/RejectItem/Edit"
import RejectItem from "pages/Purchase/Transaction/RejectItem"
// ** Record Reject
import RecordReject from "pages/Purchase/Transaction/RecordReject"
import EditRecordReject from "pages/Purchase/Transaction/RecordReject/Edit"

// Types
import type { RouteType } from "types"
import ApprovePo from "pages/Purchase/Transaction/ApprovePo"
import ApproveDepoVendor from "pages/Purchase/Transaction/ApproveDepoVendor"
import ApproveReject from "pages/Purchase/Transaction/ApproveReject"
import ApprovePaymentBill from "pages/Purchase/Transaction/ApprovePaymentBill"

export const purchaseRoutes: RouteType[] = [
  {
    path: "/purchase/register",
    element: <PurchaseRegister />,
    permission: "PR"
  },
  {
    path: "/purchase/transaction/opening-balance",
    element: <OpeningBalance />,
    permission: "AT01"
  },
  {
    path: "/purchase/transaction/purchase-order",
    element: <PurchaseOrder />,
    permission: "PT02"
  },
  {
    path: "/purchase/transaction/purchase-order/:id",
    element: <EditPurchaseOrder />,
    permission: "PT023"
  },
  {
    path: "/purchase/transaction/enter-bills/:id",
    element: <EnterBill />,
    permission: "PT05"
  },
  {
    path: "/purchase/transaction/enter-bills/:id/edit/:bill_id",
    element: <EditEnterBill />,
    permission: "PT05"
  },
  // {
  //   path: "/purchase/transaction/dashboard",
  //   element: <Dashboard/>,
  //   permission: "PT07"
  // },
  {
    path: "/purchase/transaction/approve-bills",
    element: <ApproveBills />,
    permission: "PT08"
  },
  {
    path: "/purchase/transaction/approve-po",
    element: <ApprovePo />,
    permission: "PT09"
  },
  {
    path: "/purchase/transaction/approve-depo-vendor",
    element: <ApproveDepoVendor />,
    permission: "PT10"
  },
  {
    path: "/purchase/transaction/approve-reject",
    element: <ApproveReject />,
    permission: "PT11"
  },
  {
    path: "/purchase/journal",
    element: <Journal />,
    // permission: "PJ"
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/find",
    element: <Find />,
    // permission: "PF"
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/transaction/general-entry",
    element: <GeneralEntry />,
    permission: "AT02"
  },
  {
    path: "/purchase/transaction/deposit-vendor/:purchase_order_id",
    element: <DepositVendor />,
    permission: "AT02" // belum diubah
  },
  {
    path: "/purchase/transaction/deposit-vendor/edit/:deposit_vendor_id",
    element: <EditDepositVendor />,
    permission: "AT02" // belum diubah
  },
  {
    path: "/purchase/transaction/payment-bill/:bill_id",
    element: <PaymentBill />,
    permission: "AT02" // belum diubah
  },
  {
    path: "/purchase/transaction/payment-bill/edit/:payment_bill_id",
    element: <EditPaymentBill />,
    permission: "AT02" // belum diubah
  },
  {
    path: "/purchase/transaction/reject-item/:purchase_order_id/:bill_id",
    element: <RejectItem />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/transaction/reject-item/edit/:bill_id/:reject_id",
    element: <EditRejectItem />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/transaction/approve-payment-bill",
    element: <ApprovePaymentBill />,
    permission: "PT12"
  },
  {
    path: "/purchase/transaction/record-reject",
    element: <RecordReject />,
    permission: "PT12" // BELUM
  },
  {
    path: "/purchase/transaction/record-reject/:id",
    element: <EditRecordReject />,
    permission: "PT12" // BELUM
  },
  {
    path: "/purchase/transaction/close-purchase/:id",
    element: <ClosePurchase />,
    permission: "PT05" // BELUM
  },
  {
    path: "/purchase/transaction/purchase-register",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/transaction/purchase-register",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/aging-summary",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/ageng-detail",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/purchase-summary",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/purchase-detail",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/analyse-purchase-periode",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/analyse-purchase-comparation",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/analyse-purchase-monthly",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/vendor-purchase-summary",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/vendor-purchase-detail",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/bill-summary",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/bill-detail",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/supplier-payment",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/supplier-payment-history",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/purchase-payable-journal",
    element: <div />,
    permission: "PT" // BELUM
  },
  {
    path: "/purchase/report/dashboard",
    element: <div />,
    permission: "PT" // BELUM
  },
]