import { CancelButton, Card, CommandButton } from "components"

type CommandButtonsProps = {
  approvalView?: boolean
  showDeleteButton?: boolean
  showSaveButton?: boolean
  onDelete?: () => void
  onSave?: () => void
}

export const CommandButtons = ({
  approvalView,
  showDeleteButton,
  showSaveButton,
  onDelete,
  onSave
}: CommandButtonsProps) => {
  return (
    <Card className={approvalView ? "hidden" : ""}>
      <Card.Body className="flex flex-col gap-5">
        <section className="flex flex-wrap justify-between gap-3">
          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="help" />
            <CommandButton actiontype="print" />
            <CommandButton actiontype="email" />
          </section>

          <section className="flex flex-wrap gap-3">
            <CancelButton />

            {showDeleteButton && (
              <CommandButton actiontype="delete" onClick={onDelete} />
            )}
            {showSaveButton && (
              <CommandButton
                actiontype="save"
                type="button"
                color="primary"
                permission="ST121"
                onClick={onSave}
              />
            )}
          </section>
        </section>
      </Card.Body>
    </Card>
  )
}
