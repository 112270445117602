import { Button } from "components"
import { TabNavCustomerOrderType } from "../types"
import { useNavigate, useParams } from "react-router-dom"

type TabNavProps = {
  activeTab: string
  setActiveTab: (path: TabNavCustomerOrderType) => void
  displayTabs?: TabNavCustomerOrderType[] | string[]
}

type Tabs = {
  label: string
  path: TabNavCustomerOrderType
}

export function TabNavCustomerOrder({
  activeTab,
  setActiveTab,
  displayTabs
}: TabNavProps) {
  const params = useParams()
  const navigate = useNavigate()
  const tabs: Tabs[] = [
    { label: "Shipped", path: "shipped" },
    { label: "Sales", path: "sales" },
    { label: "Payment Deposit", path: "payment-deposit" },
    { label: "Payment Invoice", path: "payment-invoice" },
    { label: "Returned Items", path: "returned-items" },
    { label: "Journal", path: "journal" },
    { label: "Logbook", path: "logbook" },
    { label: "Others", path: "others" },
    { label: "Close Sales", path: "close-sales" }
  ]

  const filterTabs = displayTabs?.length
    ? tabs.filter((tab) => displayTabs?.includes(tab.path))
    : tabs

  return (
    <section className="flex w-full md:w-1/2">
      {filterTabs.map((tab) => (
        <Button
          key={tab.path}
          type="button"
          color="ghost"
          size="sm"
          className={`grow bg-[#3c3c3c] border-y-0 border-x border-white no-animation hover:bg-primary hover:text-black whitespace-nowrap uppercase ${
            tab.path === activeTab ? "bg-primary text-black" : "text-white"
          }`}
          onClick={() => {
            if (tab.path === "close-sales" && params?.sales_order_id) {
              return navigate(
                `/sales/transaction/close-sales/${params.sales_order_id}`
              )
            }

            setActiveTab(tab.path)
          }}>
          {tab.label}
        </Button>
      ))}
    </section>
  )
}
