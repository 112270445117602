/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DateInput, Modal } from "components"
import moment from "moment"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Fragment, useContext, useState } from "react"
import toast from "react-hot-toast"
import { useApi, useToggle } from "utils"
import { ContextApproveQuotation } from ".."
import { IoSendSharp } from "react-icons/io5"
import { ApproveType } from "../types"
import CustomerQuotationDetail from "../../CustomerQuotation/components/Detail"

type ApproveActionProps = {
  data: ApproveType
}

export function ApproveAction({ data }: ApproveActionProps) {
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <Button
        type="button"
        color="ghost"
        permission={"ST10"}
        onClick={toggle}
        disabled={[APPROVAL.REJECT, APPROVAL.APPROVE].includes(
          data?.approve_status
        )}>
        <IoSendSharp />
      </Button>

      {isActive && (
        <ModalSection toggle={toggle} data={data} />
      )}
    </Fragment>
  )
}

type ModalSectionProps = {
  data: ApproveType
  toggle: () => void
}

function ModalSection({ toggle, data }: ModalSectionProps) {
  const api = useApi()
  const context = useContext(ContextApproveQuotation)
  const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"))

  const onSubmit = async (date: string, status: number) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.patch(`/customer-quotation/approve/${data.customer_quotation_id}`,
          {
            approval_status: status,
            approve_date: date,
          }),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        toggle()
        context.refetch()
      }).catch(() => { }).finally(resolve)
    })
  }

  return (
    <Modal isOpen title="APPROVE QUOTATION" closeModal={toggle} size="lg">
      <Modal.Body>
        <CustomerQuotationDetail customer_quotation_id={data.customer_quotation_id.toString()} />
      </Modal.Body>

      <Modal.Footer className="flex md:flex-row flex-col justify-between md:items-end gap-3">
        <DateInput
          placeholderText="Select Date"
          selected={moment(date).toDate()}
          className="w-full"
          label="APPROVAL DATE"
          onChange={(date) => {
            const selectDate = moment(date).format("YYYY-MM-DD")
            setDate(selectDate)
          }}
        />

        <section className="flex gap-3">
          <Button onClick={toggle}>CANCEL</Button>
          <Button color="error" onClick={() => onSubmit(date, 3)}>
            VOID
          </Button>
          <Button color="primary" onClick={() => onSubmit(date, 4)}>
            APPROVED
          </Button>
        </section>
      </Modal.Footer>
    </Modal>
  )
}
