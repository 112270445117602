// React
import { createContext } from "react"

// Types
import type { FormatType } from "../types"

export const FormatContext = createContext<FormatType>({
  headers: [],
  tab: 0,
  assignHeaders(_) {},
  nextTab() {},
  prevTab() {}
})