// Types
import type { MenuType } from "../types"

export const cardsMenu = {
  menu: [
    {
      label: "REGISTER",
      link: "/cards/register/all",
      permission: "CR"
    },
    {
      label: "TRANSACTION",
      link: "#",
      permission: "CT",
      children: [
        { label: "CREATE NEW EMPLOYEE", link: "/cards/transaction/create-new-employee", permission: "CT02" },
        { label: "CREATE NEW CUSTOMER", link: "/cards/transaction/create-new-customer", permission: "CT03" },
        { label: "CREATE NEW VENDOR", link: "/cards/transaction/create-new-vendor", permission: "CT04" },
        { label: "CREATE NEW INVESTOR", link: "/cards/transaction/create-new-investor", permission: "CT05" },
      ]
    },
    {
      label: "REPORT",
      link: "#",
      permission: "CP",
      children: [
        { label: "CARD LIST SUMMARY", link: "/cards/report/card-list-summary", permission: "CP01" },
        { label: "CARD LIST DETAIL", link: "/cards/report/card-list-detail", permission: "CP02" },
        { label: "CARD TRANSACTION", link: "/cards/report/card-transaction", permission: "CP03" },
        { label: "OVERDUE CONTACT", link: "/cards/report/overdue-contact", permission: "CP04" },
        { label: "DASHBOARD", link: "/cards/report/dashboard", permission: "CP05" },
      ]
    },
    {
      label: "JOURNAL",
      link: "/cards/journal",
      // permission: "CJ"
      permission: "CT" // BELUM
    },
    {
      label: "FIND",
      link: "/cards/find",
      // permission: "F"
      permission: "CT" // BELUM
    }
  ] as MenuType[],
}