/* eslint-disable react-hooks/exhaustive-deps */

// React
import { useContext, useEffect } from "react"

// Components
import { NumberInput } from "components"

// Contexts
import { ProductContext } from "../contexts"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Utils
import { DetailFormType } from "../../../utils"

export function Price() {
  // Hooks
  const { data } = useContext(ProductContext)

  // Form
  const { control, setValue } = useFormContext<DetailFormType>()
  const product_id = useWatch({
    control,
    name: "product_id"
  })

  useEffect(() => {
    setValue("price_measure", data.find(item => item.value === product_id)?.buying_price ?? 0)
  }, [product_id])

  return (
    <Controller
      name="price_measure"
      control={control}
      render={({ field, fieldState }) => (
        <NumberInput
          textRight
          ref={field.ref}
          value={field.value}
          onValueChange={(value) => field.onChange(value.floatValue)}
          error={fieldState.error?.message}
        />
      )}
    />
  )
}