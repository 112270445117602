// SweetAlert2
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

export function sweetAlert(
  title: string,
  params?: {
    icon: "success" | "error" | "info"
  }
): void {
  withReactContent(Swal).fire({
    icon: params?.icon ?? undefined,
    title: "Success",
    text: title,
    customClass: { confirmButton: "btn btn-primary" }
  })
}