import { useApi } from "utils";

type GenerateLinkTransactionParams = {
  api: ReturnType<typeof useApi>;
  data_source: number;
  data_source_id: number;
};

export const generateLinkTransaction = async ({
  api,
  data_source,
  data_source_id
}: GenerateLinkTransactionParams) => {

  if (data_source === 6) {
    const generated_path = await api.get(`probill/detailbill?bill_id=${data_source_id}`).then(res => {
      const purchase_order_id = res.data.payload.databill.purchase_order_id
      return `/purchase/transaction/enter-bills/${purchase_order_id}/edit/${data_source_id}`
    })

    return generated_path;
  }

  switch (data_source) {
    case 1:
      return `/finance/transaction/spend-money/edit/${data_source}/${data_source_id}`;
    case 2:
      return `/finance/transaction/receive-money/edit/${data_source}/${data_source_id}`;
    case 3:
      return `/finance/transaction/general-entry/edit/${data_source}/${data_source_id}`;
    case 4:
      return `/finance/transaction/transfer-money/edit/${data_source}/${data_source_id}`;
    case 7:
      return `/purchase/transaction/payment-bill/edit/${data_source_id}`;
    default:
      return "/";
  }
};
