// React
import { useState } from "react"

// DnD kit
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"

// Types
import type { HeaderType } from "../../types"

// Utils
import { headerList } from "../vars"

export function useHeaders(headers: HeaderType[]): {
  handleDragEnd: (
    event: DragEndEvent,
    type: "old" | "new"
  ) => void
  items: HeaderType[]
  itemsNew: HeaderType[]
  swapOrder: (
    index: number,
    type: "old" | "new"
  ) => void
} {
  // Hooks
  const [items, setItems] = useState<HeaderType[]>(headerList.filter(item => !headers.includes(item)))
  const [itemsNew, setItemsNew] = useState<HeaderType[]>(headers)

  // Functions
  function swapOrder(
    index: number,
    type: "old" | "new"
  ): void {
    if (type === "new") {
      // Vars
      const item = itemsNew.find((_, key) => key === index)

      setItemsNew((prev) => {
        // Vars
        const items = [...prev]

        items.splice(index, 1)
        return items
      })

      setItems((prev) => {
        // Vars
        const items = [...prev]

        items.push(item!)
        return items
      })
    } else {
      // Vars
      const item = items.find((_, key) => key === index)

      setItems((prev) => {
        // Vars
        const items = [...prev]

        items.splice(index, 1)
        return items
      })

      setItemsNew((prev) => {
        // Vars
        const items = [...prev]

        items.push(item!)
        return items
      })
    }
  }

  function handleDragEnd(
    event: DragEndEvent,
    type: "old" | "new"
  ): void {
    const {active, over} = event

    if (over && active.id !== over.id) {
      if (type === "new") {
        setItemsNew((items) => {
          const oldIndex = items.findIndex(item => item.value === active.id)
          const newIndex = items.findIndex(item => item.value === over.id)
          return arrayMove(items, oldIndex, newIndex)
        })

        return
      }

      setItems((items) => {
        const oldIndex = items.findIndex(item => item.value === active.id)
        const newIndex = items.findIndex(item => item.value === over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  return { items, itemsNew, handleDragEnd, swapOrder}
}