import { Select } from "components"
import { Controller, useFormContext } from "react-hook-form"
import { useDropdownProduct } from "../utils/hooks/useDropdownProduct"
import { getPriceLevel } from "../utils/functions"

type DropdownProductProps = {
  priceLevelName?: string
  products: ReturnType<typeof useDropdownProduct>
}

export const DropdownProduct = ({
  products,
  priceLevelName
}: DropdownProductProps) => {
  const { control, setValue } = useFormContext()

  return (
    <section>
      <Controller
        control={control}
        name="product_id"
        render={({ field, fieldState }) => (
          <Select
            isLoading={products.isLoading}
            controlClass="w-[250px]"
            placeholder="Select Item Order"
            error={fieldState.error?.message}
            ref={field.ref}
            options={products.dropdown}
            value={
              field.value
                ? products.dropdown.find(
                    (item) => item.value === field.value.toString()
                  )
                : null
            }
            onChange={(product: any) => {
              field.onChange(product?.value)

              const price = getPriceLevel({
                product,
                price_level_name: priceLevelName ?? ""
              })

              setValue("product_name", product?.product_name ?? "")
              setValue("product_barcode", product?.product_barcode ?? "")
              setValue("mr_unit_id", product?.unit_sell_measure_id ?? NaN)
              setValue("mr_unit_name", product?.unit_sell_measure_name ?? "")
              setValue("price_measure", (price ?? 0) * product.quantity_unit_sell_convention)
              setValue("soh", product?.soh ?? 0)
              setValue("tax_id", product?.tax_id)
              setValue("tax_rate", product?.tax_rate)

              setValue(
                "tax_name",
                `${product?.tax_name} (${product?.tax_rate}%)`
              )
            }}
          />
        )}
      />
    </section>
  )
}
