// React
import { createContext } from "react"

// Types
import type { DataType } from "../types"

export const DataContext = createContext<{
  data: DataType[]
  assignData: (data: DataType[]) => void
}>({
  data: [],
  assignData(_) {}
})