export const headerExample: { [key: string]: any } = {
  product_barcode: "1011012011",
  product_name: "Shoes",
  mr_product_group: "Finished Item",
  mr_product_style: "Modern",
  mr_product_colour: "Black",
  mr_product_category: "Fashion",
  mr_product_material: "Leather",
  main_size: "Large",
  mr_category_customer: "Loyal Customer",
  weight_unit: "Pc",
  weight: 2,
  length: 180,
  width: 100,
  height: 40
}

export const headerBlankExample: { [key: string]: any } = {
  product_barcode: "",
  product_name: "",
  mr_product_group: "",
  mr_product_style: "",
  mr_product_colour: "",
  mr_product_category: "",
  mr_product_material: "",
  main_size: "",
  mr_category_customer: "",
  weight_unit: "",
  weight: 0,
  length: 0,
  width: 0,
  height: 0
}