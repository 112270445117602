// React
import { Fragment, createContext } from "react"

// Components
import { Loading, Modal } from "../../../components"
import { CoaList } from "../CoaList"

// Contexts
import { LocationProvider } from "contexts"

// Types
import type { SelectedCoaType } from "types"

// Utils
import { useAccountGroup } from "../../../utils"

export const SuccessContext = createContext<() => void>(() => {})

export function AccountModal(props: {
  cheque_coa_condition?: boolean
  disabledCoaGroup?: boolean
  defaultGroup?: string
  onAddSuccess?: () => void
  onChange: (
    value: string,
    label: string,
    coa: SelectedCoaType
  ) => void
  toggle: () => void
}) {
  // Hooks
  const { data, isLoading } = useAccountGroup()

  return (
    <Modal isOpen title="" size="full" closeModal={props.toggle}>
      <Modal.Body>
        {isLoading || data.length === 0 ? (
          <section className="my-5">
            <Loading loading={isLoading} />
          </section>
        ) : (
          <Fragment>
            <section className="flex justify-center mb-3">
              <div className="border-b text-xl font-bold">ACCOUNT</div>
            </section>

            <SuccessContext.Provider
              value={() => {
                if (props.onAddSuccess) {
                  props.onAddSuccess()
                  props.toggle()
                }
              }}
            >
              <LocationProvider>
                <CoaList
                  cheque_coa_condition={props.cheque_coa_condition}
                  disabledCoaGroup={props.disabledCoaGroup}
                  defaultGroup={props.defaultGroup}
                  data={data}
                  onAddCoa={(value, label, coa) => {
                    props.onChange(value, label, coa)
                    props.toggle()
                  }}
                />
              </LocationProvider>
            </SuccessContext.Provider>
          </Fragment>
        )}
      </Modal.Body>
    </Modal>
  )
}
