export const getBasenamePath = () => {
  const pathName = window.location.pathname

  const splitPathName = pathName.split("/")

  const companyNamePath = splitPathName[1] // -> get company name in url path

  const typeIndustryPath = splitPathName[2] ? splitPathName[2] : "default" // -> get type industry

  const isSubscribePath = splitPathName.includes("subscribe") // -> check if 'subscribe' in path

  return {
    basename: isSubscribePath
      ? "/guest/default"
      : `/${companyNamePath}/${typeIndustryPath}`,
    companyName: companyNamePath,
    typeIndustry: typeIndustryPath
  }
}
