/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import {
  AccountModal,
  ActionButton,
  Button,
  Input,
  NumberInput,
  Select
} from "components"
import { Fragment, useEffect, useMemo } from "react"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from "react-hook-form"
import { TbEye } from "react-icons/tb"
import { PropsValue } from "react-select"
import { safeSubtract, useToggle } from "utils"
import { FormType } from "../types"
import { useProjectList, useTaxCodeList } from "../utils/hooks"
import {
  DataJournalType,
  validationSchemaJournal
} from "../utils/vars/validationSchemaJournal"
import { isCoaDuplicate } from "../utils/function"
import toast from "react-hot-toast"

type Action = "add" | "edit"

type BodyProps = {
  index?: number
  action: Action
  defaultValues?: DataJournalType
  isHideForm?: boolean
  onCancelEditing?: () => void
  onDeleteJournal?: () => void
  onSubmitJournal: (value: DataJournalType) => void
  project: ReturnType<typeof useProjectList>
  tax: ReturnType<typeof useTaxCodeList>
}

export function Body(props: BodyProps) {
  const { control } = useFormContext<FormType>()
  const isHideForm = useToggle(props.isHideForm ?? true)
  const [memo, reference_no, datajournal] = useWatch({
    control,
    name: ["memo", "reference_no", "datajournal"]
  })

  const defaultValues: DataJournalType = useMemo(
    () => ({
      amount: props.defaultValues?.amount ?? 0,
      coa_id: props.defaultValues?.coa_id ?? "",
      coa_name: props.defaultValues?.coa_name ?? "",
      memo: props.defaultValues?.memo ?? memo,
      project_id: props.defaultValues?.project_id ?? "",
      project_name: props.defaultValues?.project_name ?? "",
      referensi: props.defaultValues?.referensi ?? reference_no,
      position: props?.defaultValues?.position ?? 0,
      tax_id: props?.defaultValues?.tax_id ?? "",
      tax_name: props?.defaultValues?.tax_name ?? "",
      tax_coa_collect_id: props?.defaultValues?.tax_coa_collect_id ?? "",
      tax_coa_collect: props?.defaultValues?.tax_coa_collect ?? "",
      tax_coa_paid_id: props?.defaultValues?.tax_coa_paid_id ?? "",
      tax_coa_paid: props?.defaultValues?.tax_coa_paid ?? "",
      rate: props?.defaultValues?.rate ?? 0
    }),
    [props.defaultValues, memo, reference_no]
  )

  const methods = useForm<DataJournalType>({
    defaultValues,
    resolver: yupResolver(validationSchemaJournal)
  })

  const [position, coa_id] = methods.watch(["position", "coa_id"])

  useEffect(() => {
    if (props.action === "add") {
      methods.setValue("referensi", reference_no)
    }
  }, [reference_no])

  useEffect(() => methods.reset(defaultValues), [defaultValues])

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td className="text-center">
          {isHideForm.isActive && (
            <Button
              color="transparent"
              onClick={isHideForm.toggle}
              permission="AT021">
              <TbEye className="size-5" />
            </Button>
          )}
        </td>
        {isHideForm.isActive ? (
          <td colSpan={8} />
        ) : (
          <Fragment>
            <td>
              <Assets datajournal={datajournal} />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="memo"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    placeholder="Enter Memo"
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="amount"
                render={({ field, fieldState }) => (
                  <NumberInput
                    decimalScale={0}
                    textRight
                    value={position === 1 ? field.value : 0}
                    ref={field.ref}
                    onValueChange={(value) => {
                      field.onChange(value.floatValue)
                      methods.setValue("position", 1)
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="amount"
                render={({ field, fieldState }) => (
                  <NumberInput
                    decimalScale={0}
                    textRight
                    value={position === 2 ? field.value : 0}
                    ref={field.ref}
                    onValueChange={(value) => {
                      field.onChange(value.floatValue)
                      methods.setValue("position", 2)
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td>
              <Project project={props.project} />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="referensi"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    value={field.value}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </td>
            <td>
              <Tax tax={props.tax} action={props.action} />
            </td>
            <td className="text-center">
              <section className="flex justify-center items-center">
                {props.action === "edit" && (
                  <ActionButton.Close
                    permission="AT021"
                    onClick={props?.onCancelEditing}
                  />
                )}

                <ActionButton.Add
                  permission="AT021"
                  onClick={() => {
                    const isDuplicate = isCoaDuplicate(
                      datajournal,
                      coa_id,
                      props.index
                    )

                    if (isDuplicate) {
                      return toast.error("Duplicate Account Number")
                    }

                    methods.handleSubmit((data) =>
                      props.onSubmitJournal(data)
                    )()
                  }}
                />

                {props.action === "add" && (
                  <ActionButton.Close
                    permission="AT021"
                    onClick={isHideForm.toggle}
                  />
                )}
              </section>
            </td>
          </Fragment>
        )}
      </tr>
    </FormProvider>
  )
}

function Assets({ datajournal }: { datajournal: DataJournalType[] }) {
  const { isActive, toggle } = useToggle(false)
  const { control, setValue } = useFormContext<DataJournalType>()
  const coa_name = useWatch({
    control,
    name: "coa_name"
  })

  return (
    <Fragment>
      <Controller
        control={control}
        name="coa_id"
        render={({ field, fieldState }) => {
          let value: PropsValue<{
            value: string
            label: string
          }> = null

          if (field.value && coa_name) {
            value = {
              label: coa_name,
              value: field.value
            }
          }

          return (
            <section onClick={toggle}>
              <Select
                placeholder="Select Account"
                error={fieldState.error?.message}
                ref={field.ref}
                value={value}
              />
            </section>
          )
        }}
      />

      {isActive && (
        <AccountModal
          defaultGroup="01"
          toggle={toggle}
          onChange={(value, label, coa) => {
            const isDuplicate = isCoaDuplicate(datajournal, value)
            
            if (isDuplicate) {
              return toast.error("Duplicate Account Number")
            }
            
            setValue("coa_id", value, { shouldValidate: true })
            setValue("coa_name", label)

            // Vars
            const sameGroupCoa = datajournal.find(item => item.coa_name.slice(0, 2) === coa.group_coa_code)
            const amountSummary: {
              credit: number
              debit: number
            } = datajournal.reduce((acc, item) => {
              return {
                credit: acc.credit + (item.position === 2 ? item.amount : 0),
                debit: acc.debit + (item.position === 1 ? item.amount : 0),
              }
            }, {
              credit: 0,
              debit: 0
            })
            const isDebitBigger: boolean = amountSummary.debit >= amountSummary.credit

            if (sameGroupCoa) {
              // Functions
              const setAmount = (): number => {
                if (sameGroupCoa.position === 1) {
                  return safeSubtract(amountSummary.credit, amountSummary.debit)
                }
                
                return safeSubtract(amountSummary.debit, amountSummary.credit)
              }

              setValue("position", sameGroupCoa.position)
              setValue("amount", setAmount())
            } else {
              setValue("position", isDebitBigger ? 2 : 1)
              setValue("amount", isDebitBigger ? safeSubtract(amountSummary.debit, amountSummary.credit) : safeSubtract(amountSummary.credit, amountSummary.debit))
            }
          }}
        />
      )}
    </Fragment>
  )
}

function Project({ project }: Pick<BodyProps, "project">) {
  const { dropdown } = project
  const { control, setValue } = useFormContext<DataJournalType>()

  return (
    <Fragment>
      <Controller
        control={control}
        name="project_id"
        render={({ field, fieldState }) => {
          return (
            <section>
              <Select
                placeholder="Select Project"
                error={fieldState.error?.message}
                ref={field.ref}
                options={dropdown}
                value={
                  field.value
                    ? dropdown.find((item) => item.value === field.value)
                    : null
                }
                onChange={(e) => {
                  field.onChange(e?.value)
                  setValue("project_name", e?.label ?? "")
                }}
              />
            </section>
          )
        }}
      />
    </Fragment>
  )
}

function Tax({ action, tax }: Pick<BodyProps, "action" | "tax">) {
  const { dropdown, isLoading } = tax
  const { control, setValue } = useFormContext<DataJournalType>()

  useEffect(() => {
    if (!isLoading && action === "add") {
      const defaultTax = dropdown.find((item) => item.rate === 0)

      setValue("tax_id", defaultTax?.value ?? "")
      setValue("tax_name", defaultTax?.label ?? "")
      setValue("tax_coa_collect", defaultTax?.tax_coa_collect ?? "")
      setValue(
        "tax_coa_collect_id",
        defaultTax?.tax_coa_collect_id.toString() ?? ""
      )
      setValue("tax_coa_paid", defaultTax?.tax_coa_paid ?? "")
      setValue("tax_coa_paid_id", defaultTax?.tax_coa_paid_id.toString() ?? "")
      setValue("tax_name", defaultTax?.label ?? "")
    }
  }, [dropdown.length])

  return (
    <Fragment>
      <Controller
        control={control}
        name="tax_id"
        render={({ field, fieldState }) => {
          return (
            <section>
              <Select
                placeholder="Select Tax"
                error={fieldState.error?.message}
                ref={field.ref}
                options={dropdown}
                value={
                  field.value
                    ? dropdown.find((item) => item.value === field.value)
                    : null
                }
                onChange={(e: any) => {
                  field.onChange(e?.value)
                  setValue("rate", e.rate)
                  setValue("tax_coa_collect", e.tax_coa_collect)
                  setValue(
                    "tax_coa_collect_id",
                    e.tax_coa_collect_id.toString()
                  )
                  setValue("tax_coa_paid", e.tax_coa_paid)
                  setValue("tax_coa_paid_id", e.tax_coa_paid_id.toString())
                  setValue("tax_name", e?.label ?? "")
                }}
              />
            </section>
          )
        }}
      />
    </Fragment>
  )
}
