// React
import { useContext } from "react"

// Components
import { NumberInput } from "components"

// Contexts
import { DisabledContext } from "../../contexts"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { FormType } from "../utils"

export function Amount(): JSX.Element {
  // Hooks
  const disabled = useContext(DisabledContext)

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      name="amount"
      control={control}
      render={({ field, fieldState }) => (
        <NumberInput
          inputClass="text-right"
          label="PAYMENT AMOUNT"
          disabled={disabled}
          error={fieldState.error?.message}
          ref={field.ref}
          value={field.value}
          onValueChange={e => field.onChange(e.floatValue ?? 0)}
        />
      )}
    />
  )
}