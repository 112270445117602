// Types
import type { SelectOptionType } from "types"

// Types
interface Type extends SelectOptionType {
  required: boolean
}

export const headerList: Type[] = [
  {
    label: "Item Barcode",
    value: "product_barcode",
    required: true
  },
  {
    label: "Item Name",
    value: "product_name",
    required: true
  },
  {
    label: "Item Group",
    value: "mr_product_group",
    required: true
  },
  {
    label: "Style",
    value: "mr_product_style",
    required: true
  },
  {
    label: "Colour",
    value: "mr_product_colour",
    required: true
  },
  {
    label: "Category",
    value: "mr_product_category",
    required: true
  },
  {
    label: "Material",
    value: "mr_product_material",
    required: true
  },
  {
    label: "Size",
    value: "main_size",
    required: true
  },
  {
    label: "Market Segment",
    value: "mr_category_customer",
    required: true
  },
  {
    label: "Unit",
    value: "weight_unit",
    required: true
  },
  {
    label: "Weight",
    value: "weight",
    required: true
  },
  {
    label: "Length",
    value: "length",
    required: false
  },
  {
    label: "Width",
    value: "width",
    required: false
  },
  {
    label: "Height",
    value: "height",
    required: false
  }
]