export const dataSource: { [key: number]: { nama: string; group: string } } = {
  1: {
    nama: "Spend Money",
    group: "Disbursements"
  },
  2: {
    nama: "Receive Money",
    group: "Reiceipts"
  },
  3: {
    nama: "General Entry",
    group: "General"
  },
  4: {
    nama: "Transfer Money",
    group: "Transfer Money"
  },
  5: {
    nama: "Purchase Order",
    group: "Purchases"
  },
  6: {
    nama: "Create Bill",
    group: "Purchases"
  },
  7: {
    nama: "Payment Deposit Vendor",
    group: "Purchases"
  },
  8: {
    nama: "Opening Balance Account",
    group: "Account Transactions"
  },
  9: {
    nama: "Payment Bill",
    group: "Purchases"
  },
  10: {
    nama: "Sales Order",
    group: "Sales"
  },
  11: {
    nama: "Create Invoice",
    group: "Sales"
  },
  12: {
    nama: "Payment Deposit Customer",
    group: "Sales"
  },
  13: {
    nama: "Payment Invoice",
    group: "Sales"
  },
  14: {
    nama: "Opening Stock",
    group: "Inventory"
  },
  15: {
    nama: "Create Bill Detail",
    group: ""
  },
  16: {
    nama: "Create Invoice Detail",
    group: ""
  },
  17: {
    nama: "Average Price Real",
    group: ""
  },
  18: {
    nama: "Reject Item",
    group: "Purchases"
  },
  19: {
    nama: "Reject Item Detail",
    group: ""
  },
  20: {
    nama: "Return Item",
    group: "Sales"
  },
  21: {
    nama: "Return Item Detail",
    group: ""
  },
  22: {
    nama: "Transfer Inventory",
    group: "Inventory"
  },
  23: {
    nama: "Transfer Inventory Detail",
    group: ""
  },
  24: {
    nama: "Received Inventory",
    group: "Inventory"
  },
  25: {
    nama: "Received Inventory Detail",
    group: ""
  },
  26: {
    nama: "Reject Transfer",
    group: ""
  },
  27: {
    nama: "Adjustment Inventory",
    group: "Inventory"
  },
  28: {
    nama: "Adjustment Inventory Detail",
    group: ""
  },
  29: {
    nama: "Average Price Last",
    group: ""
  },
  30: {
    nama: "POS",
    group: "Sales"
  },
  31: {
    nama: "Payment POS",
    group: "Sales"
  }
}
