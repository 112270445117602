// React
import { useContext } from "react"

// Components
import {
  BaseTable,
  Button,
  Card,
  Checkbox,
  CommandButton,
  DateInput,
  FooterMenu,
  HeaderMenu,
  Input,
  Loading,
  Select,
  Textarea
} from "components"
import { TableSection } from "../components"

// Contexts
import { LocationContext, LocationProvider } from "contexts"

// Icons
import { TbChevronRight } from "react-icons/tb"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Types
import type { DataType, DetailType } from "./types"

// Utils
import { convertNumber, useApi, useProduct, useToggle } from "utils"
import { default_option } from "pages/Purchase/utils"
import { useSupplier } from "../components/Supplier/utils"
import { billStatusList } from "../EnterBill/utils"
import { useDetail } from "./utils"

export default function EditRecordReject(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading } = useDetail(id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  // Vars
  const { datareject, detailreject } = data
  const sum: {
    subtotal: number
    tax: number
  } = detailreject.reduce(
    (acc, item) => {
      return {
        subtotal: acc.subtotal + item.total,
        tax: acc.tax + (item.total * item.tax_rate) / 100
      }
    },
    {
      subtotal: 0,
      tax: 0
    }
  )

  return (
    <div className="container my-5 flex flex-col gap-3">
      <HeaderMenu title="DATA ENTRY | REJECT ITEM">
        {datareject.trx_code}
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
        <Supplier vendor_id={datareject.vendor_id} />

        <section className="lg:justify-self-end mb-3">
          <Checkbox readOnly value>
            TAX-INCLUSIVE
          </Checkbox>
        </section>
      </section>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="flex flex-col gap-3">
            <section>
              <LocationProvider>
                <Deliver location_id={datareject.location_id} />
              </LocationProvider>

              <Textarea disabled value={datareject.address} />
            </section>

            <Input disabled label="MEMO" value={datareject.memo} />

            <Input
              disabled
              label="LINK TO BILL ID"
              value={datareject.trx_bill_code}
            />
          </section>

          <section className="flex flex-col gap-3">
            <DateInput
              disabled
              label="DATE"
              selected={moment(datareject.transaction_date).toDate()}
              onChange={() => {}}
            />

            <Input
              disabled
              label="SUPPLIER ID #"
              value={datareject.referensi}
            />
          </section>
        </Card.Body>
      </Card>

      <BaseTable>
        <thead>
          <tr>
            <th>QTY REJECTED</th>
            <th>UNIT</th>
            <th>ITEM CODE</th>
            <th>DESCRIPTION</th>
            <th>PRICE</th>
            <th>DISC (%)</th>
            <th>TOTAL</th>
            <th>TAX</th>
          </tr>
        </thead>

        <tbody>
          <BodyTable items={detailreject} />
        </tbody>
      </BaseTable>

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <section className="flex flex-col gap-3">
          <TableSection>
            <TableSection.Row
              label="REJECT STATUS"
              value={billStatusList[datareject.reject_status.toString()]}
            />

            <TableSection.Row
              label="APPROVE STATUS"
              value={
                default_option.find(
                  (item) => item.value === datareject.approve_status.toString()
                )?.label ?? "-"
              }
            />

            <TableSection.Row
              label="APPROVED BY"
              value={datareject.approve_by}
            />
          </TableSection>
        </section>

        <section className="flex flex-col lg:items-end gap-3">
          <BaseTable
            plain
            className="w-full min-w-[100px] md:max-w-[400px] text-right">
            <tbody>
              <tr>
                <td className="w-[1px] whitespace-nowrap">SUBTOTAL</td>
                <td>{convertNumber(sum.subtotal).intoCurrency}</td>
              </tr>
              <tr>
                <td className="w-[1px] whitespace-nowrap">TAX</td>
                <td>{convertNumber(sum.tax).intoCurrency}</td>
              </tr>
              <tr className="font-bold">
                <td className="w-[1px] whitespace-nowrap">TOTAL REJECT</td>
                <td>{convertNumber(sum.subtotal + sum.tax).intoCurrency}</td>
              </tr>
            </tbody>
          </BaseTable>
        </section>
      </section>

      <section className="flex flex-wrap gap-3">
        <Button color="primary">RECEIVE ITEM</Button>
        <Button color="primary">RECEIVE MONEY</Button>
        <Button color="primary">ADJUSTMENT</Button>
      </section>

      <FooterMenu
        hasCancelButton
        attachment={{ actiontype: "attachment" }}
        delete={{ actiontype: "delete" }}
        journal={{ actiontype: "journal" }}
        customElement={{
          save: <Save data={data} />
        }}
      />
    </div>
  )
}

function BodyTable(params: { items: DetailType[] }): JSX.Element {
  // Vars
  const items = params.items

  // Hooks
  const { data } = useProduct()

  if (!items.length) {
    return (
      <tr>
        <td colSpan={8} />
      </tr>
    )
  }

  return (
    <>
      {items.map((item, key) => {
        // Vars
        const product = data.find((i) => i.product_id === item.product_id)

        return (
          <tr key={key}>
            <td>{item.quantity_reject}</td>
            <td>{product?.product_name}</td>
            <td>{product?.product_barcode}</td>
            <td>{product?.product_name}</td>
            <td>{convertNumber(item.price_reject).intoCurrency}</td>
            <td>{`${convertNumber(item.discount).intoCurrency}%`}</td>
            <td>{convertNumber(item.total).intoCurrency}</td>
            <td>
              {item.tax_name} ({item.tax_rate}%)
            </td>
          </tr>
        )
      })}
    </>
  )
}

function Deliver(params: { location_id: number }): JSX.Element {
  // Hooks
  const { data, isLoading } = useContext(LocationContext)

  return (
    <Select
      isDisabled
      label="DELIVER TO"
      placeholder="Select Deliver"
      isLoading={isLoading}
      options={data}
      value={
        data.find((item) => item.value === params.location_id.toString()) ??
        null
      }
    />
  )
}

function Save(params: { data: DataType }) {
  // Vars
  const { datareject, detailreject } = params.data

  // Hooks
  const api = useApi()
  const isLoading = useToggle(false)
  const navigate = useNavigate()

  // Functions
  const onSubmit = () => {
    isLoading.setActive(true)

    toast
      .promise(
        api.post("/proreject/add", {
          bill_id: datareject.bill_id,
          trx_code: datareject.trx_code,
          vendor_id: datareject.vendor_id,
          payment_term_vendor_id: datareject.payment_term_vendor_id,
          location_id: datareject.location_id,
          address: datareject.address,
          memo: datareject.memo,
          transaction_date: datareject.transaction_date,
          referensi: datareject.referensi,
          prorejectdetail: detailreject.map((item) => {
            return {
              quantity_reject: item.quantity_reject,
              quantity_unit: item.quantity_unit,
              product_id: item.product_id,
              price_reject: item.price_reject,
              discount: item.discount,
              total: item.total,
              tax_id: item.tax_id,
              tax_rate: item.tax_rate,
              total_tax_paid: (item.total * item.tax_rate) / 100
            }
          })
        }),
        {
          loading: "Saving Record Reject...",
          success: (res) => res.data.message,
          error: (err) => err.response.data?.message ?? err.response.message
        }
      )
      .then(() => {
        navigate(-1)
      })
      .catch(() => {})
      .finally(() => {
        isLoading.setActive(false)
      })
  }

  return (
    <CommandButton
      actiontype="save"
      loading={isLoading.isActive ? "true" : undefined}
      onClick={onSubmit}
    />
  )
}

function Supplier(params: { vendor_id: number }): JSX.Element {
  // Hooks
  const { data, isLoading } = useSupplier()

  // Vars
  const selectedItem = data.find(
    (item) => item.value === params.vendor_id.toString()
  )

  return (
    <>
      <Select
        isDisabled
        label="SUPPLIER"
        placeholder="Select Supplier"
        isLoading={isLoading}
        options={data}
        value={selectedItem ?? null}
      />

      <div className="mb-3 flex flex-wrap">
        TRADING TERM{" "}
        <span className="mt-1">
          <TbChevronRight />
        </span>{" "}
        Net {selectedItem?.payment_term_vendor_name} days after OEM
      </div>
    </>
  )
}
