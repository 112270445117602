// React
import { useContext } from "react"

// Components
import { Button, Checkbox } from "components"
import { HeaderItem } from "./HeaderItem"

// Contexts
import { FormatContext } from "../contexts"

// Utils
import { exportFile, headerExample } from "../utils"
import { useToggle } from "utils"

export function FinalFormat() {
  // Hooks
  const { headers } = useContext(FormatContext)
  const { isActive, toggle } = useToggle(false)

  return (
    <section className="flex flex-col gap-3">
      <div className="font-bold">The header format will be changed into code, please do not change the text</div>

      <section className="grid grid-cols-2 gap-3">
        <section>
          <div className='text-xl font-bold text-center'>Current header labels</div>

          {headers.map((item, key) => (
            <HeaderItem
              key={key}
              label={item.label}
            />
          ))}
        </section>

        <section>
          <div className='text-xl font-bold text-center'>Header labels after import</div>

          {headers.map((item, key) => (
            <HeaderItem
              key={key}
              label={item.value}
            />
          ))}
        </section>
      </section>

      <Checkbox
        value={isActive}
        onChange={toggle}
      >
        Add an example data on the import (Please remove it before export the format file)
      </Checkbox>

      <Button
        color="primary"
        className="w-fit"
        onClick={() => {
          if (isActive) {
            // Vars
            const data: {
              [key: string]: string | number
            } = {}

            headers.map(item => {
              // @ts-ignore
              data[item.value] = headerExample[item.value]
              return null
            })

            exportFile([data])
            return
          }

          exportFile(headers.map(item => item.value))
        }}
      >
        DOWNLOAD
      </Button>
    </section>
  )
}