import { CancelButton, Card, CommandButton } from "components"
import { useToggle } from "utils"

type CommandButtonsProps = {
  approvalView?: boolean
  showSaveButton?: boolean
  showDeleteButton?: boolean
  onDelete?: () => void
  onSave?: () => void
}

export const CommandButtons = ({
  approvalView,
  showSaveButton,
  showDeleteButton,
  onDelete,
  onSave
}: CommandButtonsProps) => {
  const modalJournalInvoice = useToggle(false)

  return (
    <>
      <Card className={approvalView ? "hidden" : ""}>
        <Card.Body className="flex flex-col gap-5">
          <section className="flex flex-wrap justify-between gap-3">
            <section className="flex flex-wrap gap-3">
              <CommandButton actiontype="help" />
              <CommandButton actiontype="print" />
              <CommandButton actiontype="email" />
              <CommandButton
                actiontype="journal"
                onClick={modalJournalInvoice.toggle}
              />
              <CommandButton actiontype="attachment" />
            </section>

            <section className="flex flex-wrap gap-3">
              <CancelButton />

              {showDeleteButton && (
                <CommandButton actiontype="delete" onClick={onDelete} />
              )}

              {showSaveButton && (
                <CommandButton
                  actiontype="save"
                  type="button"
                  color="primary"
                  permission="ST121"
                  onClick={onSave}
                />
              )}
            </section>
          </section>
        </Card.Body>
      </Card>
    </>
  )
}
