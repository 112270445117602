// Components
import { BaseTable, Loading, PermissionLink } from "components"
import { DeleteItem } from "../../DeleteItem"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Types
import type { SelectedState, TableSectionProps } from "pages/Purchase/Register/types/Reference"

// Utils
import { convertNumber } from "utils"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"

export const TableSection = ({ data, isLoading, refetch, selected }: TableSectionProps) => {
  const approvalChecked = (data: number) => {
    if(data === 1) { return "PENDING" }
    if(data === 2) { return "REVISI" }
    if(data === 3) { return "REJECT" }
    if(data === 4) { return "APPROVE" }
    return "-"
  }
  const deliveredChecked = (data: number) => {
    if(data === 1) { return "PENDING" }
    if(data === 2) { return "PART DELIVERED" }
    if(data === 3) { return "FULL DELIVERED" }
    return "-"
  }

  return (
    <div className="overflow-x-auto">
      <BaseTable className="border-transparent">
        <thead>
          <tr className="uppercase">
            <th rowSpan={2}>No</th>
            <th rowSpan={2}>PO Id#</th>
            <th rowSpan={2}>Date</th>
            <th rowSpan={2}>Supplier</th>
            <th rowSpan={2}>Reference</th>
            <th colSpan={2}>Status</th>
            <th rowSpan={2}>Qty.<br />Order</th>
            <th rowSpan={2}>Qty.<br />Delivered</th>
            <th rowSpan={2}>Qty.<br />Back Order</th>
            <th rowSpan={2}>Total<br />Purchase</th>
            <th rowSpan={2}>Total<br />Payment</th>
            <th rowSpan={2}>Balance<br />Owing</th>
            <th rowSpan={2}>Action</th>
          </tr>
          <tr className="uppercase">
            <th>Approval</th>
            <th>Delivered</th>
          </tr>
        </thead>
        <tbody>
        {!data.length || isLoading 
                ? <tr><td colSpan={15} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
                : data.map((val, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{val?.trx_code ?? "-"}</td>
                    <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                    <td>{val?.suplier_name ?? "-"}</td>
                    <td>{val?.referensi ?? "-"}</td>
                    <td>{val?.approval_status ? approvalChecked(val?.approval_status) : "-"}</td>
                    <td>{val?.po_delivery_status ? deliveredChecked(val?.po_delivery_status) : "-"}</td>
                    <td className="text-center">{val?.qty_order ? convertNumber(val?.qty_order)?.intoNormalAmount : "-"}</td>
                    <td className="text-center">{val?.qty_delivered ? convertNumber(val?.qty_delivered)?.intoNormalAmount : "-"}</td>
                    <td className="text-center">{val?.qty_back_order ? convertNumber(val?.qty_back_order)?.intoNormalAmount : "-"}</td>
                    <td className="text-right">{val?.total_purchase ? convertNumber(val?.total_purchase)?.intoCurrency : "-"}</td>
                    <td className="text-right">{val?.total_payment ? convertNumber(val?.total_payment)?.intoCurrency : "-"}</td>
                    <td className="text-right">{val?.balance_owing ? convertNumber(val?.balance_owing)?.intoCurrency : "-"}</td>
                    <td>
                      <section className="flex items-center gap-2">
                        <PermissionLink permission="PR053" to={`/purchase/transaction/purchase-order/${val.purchase_order_id}`} className="mt-1">
                          <IoSendSharp />
                        </PermissionLink>
                        {(val?.approval_status === 1 || val?.po_delivery_status !== 1) && (
                          <DeleteItem
                            onSuccess={() => {
                              const filteredSelected: Partial<SelectedState> = {};

                              (Object.keys(selected!) as Array<keyof SelectedState>).forEach(key => {
                                if (selected![key]) { filteredSelected[key] = selected![key] }
                              })

                              refetch && refetch(filteredSelected)
                            }}
                            item={{
                              purchase_order_id: val.purchase_order_id.toString(),
                              transaction_date: val.transaction_date,
                              trx_code: val.trx_code,
                              vendor_name: ""
                            }}
                          />
                        )}
                      </section>
                    </td>
                  </tr>
                ))
            }
        </tbody>
      </BaseTable>
    </div>
  )
}