import axios from "axios"

type GetValidationSubdomain = {
  subdomain: string
}

export const getValidationSubdomain = ({
  subdomain
}: GetValidationSubdomain) => {
  const params = { subdomain }

  return axios.get(
    `${process.env.REACT_APP_BASE_URL_API}/companies/validation-subdomain/`,
    { params }
  )
}
