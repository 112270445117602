// React
import { useState } from "react"

// Components
import { Card, DashboardFilter, FooterMenu, HeaderMenu } from "components"
import {
  Efficiency,
  FinancialPosition,
  FinancialRatio,
  ProfitAndLost
} from "./components/Section"

// Types
import type { FillterType, GraphValueType } from "./types"

// Third-Party Libraries
import moment from "moment"
import { useExpense, useIncome } from "./utils"

export default function Dashboard() {
  // Hooks
  const [fill, setFill] = useState<FillterType>({
    type: "Date",
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    month: moment().format("MM"),
    year: moment().format("YYYY"),
    quarter: moment().format("Q")
  })

  // income
  const { dataIncome } = useIncome({
    type: fill.type,
    month: fill.month,
    year: fill.year,
    quarter: fill.quarter,
    start_date: fill.start_date,
    end_date: fill.end_date,
    location_id: fill.location_id
  })

  // expense
  const { dataExpense } = useExpense({
    type: fill.type,
    month: fill.month,
    year: fill.year,
    quarter: fill.quarter,
    start_date: fill.start_date,
    end_date: fill.end_date,
    location_id: fill.location_id
  })

  const [list, setList] = useState<GraphValueType[]>([
    {
      label: moment().format("DD/MM/YYYY"),
      value: Math.floor(Math.random() * 100)
    }
  ])

  const [list2, setList2] = useState<GraphValueType>({
    label:
      moment().format("DD/MM/YYYY") + " - " + moment().format("DD/MM/YYYY"),
    value: Math.floor(Math.random() * 100)
  })

  return (
    <section className="container my-5">
      <HeaderMenu title="ACCOUNT DASHBOARD" />

      <section className="mt-2">
        <div>
          <Card>
            <Card.Body>
              <DashboardFilter
                onChangeFilter={(res) => {
                  // Vars
                  const startDate = moment(res.date_from)
                  const endDate = moment(res.date_end)

                  if (res.type === "Date" || res.type === "Month") {
                    setList(
                      [...Array(endDate.diff(startDate, "days") + 1)].map(
                        () => {
                          // Vars
                          const label = startDate.format("DD/MM/YYYY")
                          startDate.add(1, "days")

                          return {
                            label,
                            value: Math.floor(Math.random() * 100)
                          }
                        }
                      )
                    )
                    setList2({
                      label:
                        moment(res.date_from).format("DD/MM/YYYY") +
                        " - " +
                        moment(res.date_end).format("DD/MM/YYYY"),
                      value: Math.floor(Math.random() * 100)
                    })
                    setFill({
                      ...fill,
                      type: res.type,
                      start_date: res.date_from,
                      end_date: res.date_end,
                      month: moment(res.date_from).format("MM"),
                      year: moment(res.date_from).format("YYYY"),
                      location_id: res.location_id
                    })
                  } else if (res.type === "Quarter") {
                    setList(
                      [...Array(endDate.diff(startDate, "months") + 1)].map(
                        () => {
                          // Vars
                          const label = startDate.format("MMMM YYYY")
                          startDate.add(1, "months")

                          return {
                            label,
                            value: Math.floor(Math.random() * 100)
                          }
                        }
                      )
                    )
                    setList2({
                      label: moment(res.date_from).format("MMMM YYYY"),
                      value: Math.floor(Math.random() * 100)
                    })

                    setFill({
                      ...fill,
                      type: res.type,
                      month: moment(res.date_from).format("MM"),
                      year: moment(res.date_from).format("YYYY"),
                      location_id: res.location_id,
                      quarter: moment(res.date_from).format("Q"),
                      start_date: res.date_from,
                      end_date: res.date_end
                    })
                  } else if (res.type === "Year") {
                    setList(
                      [...Array(endDate.diff(startDate, "months") + 1)].map(
                        () => {
                          // Vars
                          const label = startDate.format("MMMM YYYY")
                          startDate.add(1, "months")

                          return {
                            label,
                            value: Math.floor(Math.random() * 100)
                          }
                        }
                      )
                    )
                    setList2({
                      label: moment(res.date_from).format("YYYY"),
                      value: Math.floor(Math.random() * 100)
                    })
                    setFill({
                      ...fill,
                      type: res.type,
                      year: moment(res.date_from).format("YYYY"),
                      location_id: res.location_id
                    })
                  }
                }}
              />

              <div className="mt-3 px-2 overflow-x-auto">
                {/* Profitablity section */}
                <div>
                  <ProfitAndLost
                    listIncome={dataIncome.payload}
                    list2={list2}
                    start_date={fill.start_date}
                    end_date={fill.end_date}
                    listExpense={dataExpense.payload}
                  />
                </div>

                {/* Efficiency section */}
                <div className="mt-4">
                  <Efficiency list={list} />
                </div>

                {/* Financial position and cash */}
                <div className="mt-4">
                  <FinancialPosition />
                </div>

                {/* Financial ratio */}
                <div className="mt-4">
                  <FinancialRatio list={list} />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <section className="mt-2">
        <FooterMenu />
      </section>
    </section>
  )
}
