// React
import { Fragment, useContext, useEffect } from "react"

// Components
import {
  BaseTable,
  Button,
  Card,
  DateInput,
  FooterMenu,
  HeaderMenu,
  Input,
  Loading,
  NumberInput,
  Select,
  Textarea
} from "components"
import { PicturesSection } from "pages/Inventori/Register/ItemGroup/components"
import { Item } from "./Item"

// Contexts
import {
  LocationContext,
  LocationProductContext,
  LocationProductProvider,
  LocationProvider
} from "../contexts"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi, useEmployee } from "utils"
import { type FormType, getDifference, useDataCode } from "../utils"

export function FormSection(params: {
  redirectToAdjustment?: boolean
  onSuccess: () => void
}): JSX.Element {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const { id } = useParams()

  // Form
  const { control, formState, getValues, handleSubmit, reset } =
    useFormContext<FormType>()
  const onSubmit = (value: FormType) => {
    // Vars
    const { count_id, ...rest } = value

    // Functions
    const callApi = () => {
      if (count_id) {
        return api.put("/invcount/update_inv_count", {
          ...rest,
          count_id,
          invcountdetail: value.countdetail.map((item) => {
            return {
              ...item,
              different: getDifference(item.real_count, item.soh_count)
            }
          })
        })
      }

      return api.post("/invcount/add", {
        ...rest,
        countdetail: value.countdetail.map((item) => {
          return {
            ...item,
            different: getDifference(item.real_count, item.soh_count)
          }
        })
      })
    }

    return new Promise<number | null>((resolve, reject) => {
      toast
        .promise(callApi(), {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) =>
            err.response.data.detail?.message ?? err.response.data.message
        })
        .then(
          (
            res: SuccessFetch<{
              payload: { count_id: number }
            }>
          ) => {
            resolve(res.data.payload.count_id)
          }
        )
        .catch(() => {
          reject(null)
        })
    })
  }
  const count_id = useWatch({
    control,
    name: "count_id"
  })

  useEffect(() => {
    if (count_id && !id) {
      navigate(`edit/${count_id}`, { replace: true })
    }

    // eslint-disable-next-line
  }, [count_id])

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | COUNT INVENTORY">
        <Code />
      </HeaderMenu>

      <LocationProvider>
        <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="flex flex-col gap-3">
            <Location />
            <Employee />

            <Input disabled label="LINK ADJUSTMENT" />
          </section>

          <section className="flex flex-col gap-3">
            <Controller
              control={control}
              name="transaction_date"
              render={({ field, fieldState }) => (
                <DateInput
                  label="DATE"
                  error={fieldState.error?.message}
                  selected={moment(field.value).toDate()}
                  onChange={(date) => {
                    // Vars
                    const selectedDate: string =
                      moment(date).format("YYYY-MM-DD")

                    field.onChange(selectedDate)
                  }}
                />
              )}
            />

            <Input disabled label="STATUS" value="Count" />

            <Controller
              name="memo"
              control={control}
              render={({ field, fieldState }) => (
                <Textarea
                  {...field}
                  label="MEMO"
                  error={fieldState.error?.message}
                />
              )}
            />
          </section>
        </section>

        <Card>
          <Card.Body className="flex flex-col gap-3">
            <LocationProductProvider>
              <section className="flex gap-3">
                <section className="grow">
                  <Item />
                </section>

                <Button type="button" color="primary">
                  PRINT
                </Button>

                <Button type="button" color="primary">
                  EXCEL
                </Button>

                <Button type="button" color="primary">
                  PDF
                </Button>
              </section>

              <BaseTable>
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>ITEM</th>
                    <th>DESCRIPTION</th>
                    <th>UNIT</th>
                    <th>PICTURE</th>
                    <th>SOH</th>
                    <th>COUNT</th>
                    <th>DIFFERENT</th>
                  </tr>
                </thead>

                <Body />
              </BaseTable>
            </LocationProductProvider>

            <section className="flex justify-end gap-3">
              {params.redirectToAdjustment && (
                <Link
                  to={`/inventory/transaction/adjustment-inventory/create/${getValues(
                    "count_id"
                  )}`}>
                  <Button type="button" color="primary">
                    RECORD
                  </Button>
                </Link>
              )}

              {/* <Button
                type="button"
                color="primary"
                loading={methods.formState.isSubmitting ? "true" : undefined}
                onClick={methods.handleSubmit((value) => {
                  onSubmit(value).then(() => {
                    methods.reset()

                    // Scroll to Top
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                  })
                })}
              >
                SAVE
              </Button> */}
            </section>
          </Card.Body>
        </Card>
      </LocationProvider>

      <section className="mt-2">
        <FooterMenu
          hasCancelButton
          save={{
            actiontype: "save",
            type: "button",
            color: "primary",
            loading: formState.isSubmitting ? "true" : undefined,
            onClick: handleSubmit((value) => {
              onSubmit(value).then(() => {
                reset()
                params.onSuccess()
              })
            })
          }}
        />
      </section>
    </section>
  )
}

function Body(): JSX.Element {
  // Hooks
  const { data } = useContext(LocationProductContext)

  // Form
  const { control } = useFormContext<FormType>()
  const countdetail = useWatch({
    control,
    name: "countdetail"
  })

  return (
    <tbody>
      {!countdetail.length ? (
        <tr>
          <td colSpan={8}>
            <Loading errorText="No data available" />
          </td>
        </tr>
      ) : (
        countdetail.map((item, key) => {
          // Vars
          const contextData = data.find((i) => i.product_id === item.product_id)

          return (
            <Controller
              key={key}
              name={`countdetail.${key}`}
              control={control}
              render={({ field, fieldState }) => (
                <tr>
                  <td className="text-center">1</td>
                  <td>{contextData?.product_barcode}</td>
                  <td>{contextData?.product_name}</td>
                  <td>{contextData?.mr_unit_name}</td>

                  <td>
                    <section className="flex justify-center">
                      <div className="w-[400px] lg:w-[200px]">
                        <PicturesSection
                          className="h-[100px] w-full"
                          items={1}
                          dataPicture={[
                            {
                              description: "",
                              is_deleted: false,
                              product_picture_code: "",
                              product_picture_id: 0,
                              product_picture_name: "",
                              path: contextData?.product_foto ?? ""
                            }
                          ]}
                        />
                      </div>
                    </section>
                  </td>

                  <td className="text-right">{item.soh_count}</td>

                  <td>
                    <NumberInput
                      error={fieldState.error?.message}
                      value={field.value.real_count}
                      onValueChange={(res) =>
                        field.onChange({
                          ...field.value,
                          real_count: res.floatValue ?? 0
                        })
                      }
                    />
                  </td>

                  <td className="text-right">
                    {getDifference(item.soh_count, field.value.real_count)}
                  </td>
                </tr>
              )}
            />
          )
        })
      )}
    </tbody>
  )
}

function Code(): JSX.Element {
  // Hooks
  const { fetchCode } = useDataCode()

  // Form
  const { control, formState, setValue } = useFormContext<FormType>()
  const transaction_date = useWatch({
    control,
    name: "transaction_date"
  })
  const trx_code = useWatch({
    control,
    name: "trx_code"
  })

  useEffect(() => {
    if (!formState.defaultValues?.trx_code) {
      fetchCode(transaction_date)
        .then((res) => {
          setValue("trx_code", res)
        })
        .catch((err) => {
          setValue("trx_code", err)
        })
    }

    // eslint-disable-next-line
  }, [transaction_date])

  return <Fragment>{trx_code}</Fragment>
}

function Employee(): JSX.Element {
  // Hooks
  const { dataEmployee, isLoadingEmployee } = useEmployee()

  // Form
  const { control } = useFormContext<FormType>()

  return (
    <Controller
      name="employee_id"
      control={control}
      render={({ field, fieldState }) => (
        <Select
          label="EMPLOYEE"
          placeholder="Select Employee"
          error={fieldState.error?.message}
          isLoading={isLoadingEmployee}
          value={
            dataEmployee.find((item) => item.value === field.value) ?? null
          }
          options={dataEmployee}
          onChange={(res) => field.onChange(res?.value)}
        />
      )}
    />
  )
}

function Location(): JSX.Element {
  // Hooks
  const { data, isLoading } = useContext(LocationContext)

  // Form
  const { control, setValue } = useFormContext<FormType>()

  return (
    <Controller
      name="location_id"
      control={control}
      render={({ field, fieldState }) => (
        <Select
          label="LOCATION"
          placeholder="Select Location"
          error={fieldState.error?.message}
          isLoading={isLoading}
          value={data.find((item) => item.value === field.value) ?? null}
          options={data}
          onChange={(res) => {
            if (res) {
              field.onChange(res.value)
              setValue("countdetail", [])
            }
          }}
        />
      )}
    />
  )
}
