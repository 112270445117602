// Types
import type { ListType } from "../../types"

export const monthlyList: ListType[] = [
  {
    "category": "Liquidity Ratios",
    "ratios": [
      {
        "name": "Current Ratio",
        "values": {
          "BUDGET": 1.5,
          "JANUARY": 2.3,
          "FEBRUARY": 1.7,
          "MARCH": 2.1,
          "APRIL": 1.9,
          "MAY": 2,
          "JUNE": 2.4,
          "JULY": 2.2,
          "AUGUST": 1.8,
          "SEPTEMBER": 2.5,
          "OCTOBER": 2.6,
          "NOVEMBER": 2.1,
          "DECEMBER": 2.7
        }
      },
      {
        "name": "Quick Current Ratio",
        "values": {
          "BUDGET": 1.2,
          "JANUARY": 1.9,
          "FEBRUARY": 1.4,
          "MARCH": 2,
          "APRIL": 1.6,
          "MAY": 1.7,
          "JUNE": 2.3,
          "JULY": 1.8,
          "AUGUST": 2.1,
          "SEPTEMBER": 2.2,
          "OCTOBER": 1.3,
          "NOVEMBER": 2.4,
          "DECEMBER": 2.5
        }
      }
    ]
  },
  {
    "category": "Leverage Ratios",
    "ratios": [
      {
        "name": "Debt-to-equity Ratio",
        "values": {
          "BUDGET": 0.8,
          "JANUARY": 1.5,
          "FEBRUARY": 1,
          "MARCH": 1.2,
          "APRIL": 1.1,
          "MAY": 1.3,
          "JUNE": 1.4,
          "JULY": 1.7,
          "AUGUST": 1.6,
          "SEPTEMBER": 1.8,
          "OCTOBER": 1.9,
          "NOVEMBER": 2,
          "DECEMBER": 2.1
        }
      },
      {
        "name": "Financial Leverage",
        "values": {
          "BUDGET": 2.5,
          "JANUARY": 3.1,
          "FEBRUARY": 2.7,
          "MARCH": 2.8,
          "APRIL": 2.9,
          "MAY": 3,
          "JUNE": 3.2,
          "JULY": 3.3,
          "AUGUST": 3.4,
          "SEPTEMBER": 3.5,
          "OCTOBER": 3.6,
          "NOVEMBER": 3.7,
          "DECEMBER": 3.8
        }
      },
      {
        "name": "Total Leverage",
        "values": {
          "BUDGET": 3,
          "JANUARY": 3.5,
          "FEBRUARY": 3.2,
          "MARCH": 3.3,
          "APRIL": 3.4,
          "MAY": 3.6,
          "JUNE": 3.7,
          "JULY": 3.8,
          "AUGUST": 3.9,
          "SEPTEMBER": 4,
          "OCTOBER": 4.1,
          "NOVEMBER": 4.2,
          "DECEMBER": 4.3
        }
      }
    ]
  },
  {
    "category": "Profitability Ratios",
    "ratios": [
      {
        "name": "Gross Margin Percentage",
        "values": {
          "BUDGET": 0.4,
          "JANUARY": 0.45,
          "FEBRUARY": 0.42,
          "MARCH": 0.43,
          "APRIL": 0.44,
          "MAY": 0.46,
          "JUNE": 0.47,
          "JULY": 0.48,
          "AUGUST": 0.49,
          "SEPTEMBER": 0.5,
          "OCTOBER": 0.51,
          "NOVEMBER": 0.52,
          "DECEMBER": 0.53
        }
      },
      {
        "name": "Net Operating Margin Percentage",
        "values": {
          "BUDGET": 0.2,
          "JANUARY": 0.25,
          "FEBRUARY": 0.22,
          "MARCH": 0.23,
          "APRIL": 0.24,
          "MAY": 0.26,
          "JUNE": 0.27,
          "JULY": 0.28,
          "AUGUST": 0.29,
          "SEPTEMBER": 0.3,
          "OCTOBER": 0.31,
          "NOVEMBER": 0.32,
          "DECEMBER": 0.33
        }
      },
      {
        "name": "Net Profit Margin",
        "values": {
          "BUDGET": 0.15,
          "JANUARY": 0.2,
          "FEBRUARY": 0.17,
          "MARCH": 0.18,
          "APRIL": 0.19,
          "MAY": 0.21,
          "JUNE": 0.22,
          "JULY": 0.23,
          "AUGUST": 0.24,
          "SEPTEMBER": 0.25,
          "OCTOBER": 0.26,
          "NOVEMBER": 0.27,
          "DECEMBER": 0.28
        }
      }
    ]
  },
  {
    "category": "Efficiency Ratios",
    "ratios": [
      {
        "name": "Return on Asset (ROA)",
        "values": {
          "BUDGET": 0.1,
          "JANUARY": 0.15,
          "FEBRUARY": 0.12,
          "MARCH": 0.13,
          "APRIL": 0.14,
          "MAY": 0.16,
          "JUNE": 0.17,
          "JULY": 0.18,
          "AUGUST": 0.19,
          "SEPTEMBER": 0.2,
          "OCTOBER": 0.21,
          "NOVEMBER": 0.22,
          "DECEMBER": 0.23
        }
      },
      {
        "name": "Inventory Turnover Ratio",
        "values": {
          "BUDGET": 5,
          "JANUARY": 5.5,
          "FEBRUARY": 5.2,
          "MARCH": 5.3,
          "APRIL": 5.4,
          "MAY": 5.6,
          "JUNE": 5.7,
          "JULY": 5.8,
          "AUGUST": 5.9,
          "SEPTEMBER": 6,
          "OCTOBER": 6.1,
          "NOVEMBER": 6.2,
          "DECEMBER": 6.3
        }
      }
    ]
  },
  {
    "category": "Investment Ratios",
    "ratios": [
      {
        "name": "Return on Equity (ROE)",
        "values": {
          "BUDGET": 0.12,
          "JANUARY": 0.17,
          "FEBRUARY": 0.14,
          "MARCH": 0.15,
          "APRIL": 0.16,
          "MAY": 0.18,
          "JUNE": 0.19,
          "JULY": 0.2,
          "AUGUST": 0.21,
          "SEPTEMBER": 0.22,
          "OCTOBER": 0.23,
          "NOVEMBER": 0.24,
          "DECEMBER": 0.25
        }
      }
    ]
  },
  {
    "category": "Operating Ratios",
    "ratios": [
      {
        "name": "Operating Leverage",
        "values": {
          "BUDGET": 1.5,
          "JANUARY": 1.9,
          "FEBRUARY": 1.7,
          "MARCH": 1.8,
          "APRIL": 1.6,
          "MAY": 2.0,
          "JUNE": 2.2,
          "JULY": 2.5,
          "AUGUST": 2.3,
          "SEPTEMBER": 2.4,
          "OCTOBER": 2.1,
          "NOVEMBER": 2.6,
          "DECEMBER": 2.7
        }
      }
    ]
  }
]