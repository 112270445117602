/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderMenu, NumberInput } from "components"
import { useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useBalance, useToggle } from "utils"
import { DropdownAccount } from "../../PaymentDeposit/components"
import { PaymentInvoiceType } from "../types"
import { CodePaymentInvoice } from "./CodePaymentInvoice"
import { CommandButtons } from "./CommandButtons"
import { FormPaymentInvoice } from "./FormPaymentInvoice"
import { ModalDelete } from "./ModalDelete"
import { TableJournalEntry } from "./Tables/JournalEntry"

export type FormSectionProps = {
  approvalView?: boolean
  isEdit?: boolean
  onSubmit: () => void
}

export function FormSection({ approvalView, isEdit = false, onSubmit }: FormSectionProps) {
  const navigate = useNavigate()
  const methods = useFormContext<PaymentInvoiceType>()
  const modalDelete = useToggle(false)
  const { refetch } = useBalance("/spendmoney/balance")
  const [coa_id, transaction_date, payment_invoice_id, trx_code, memo] = useWatch({
    control: methods.control,
    name: ["coa_id", "transaction_date", "payment_invoice_id", "trx_code", "memo"
    ]
  })

  const deleteData = {
    payment_invoice_id: payment_invoice_id ?? NaN,
    transaction_date: transaction_date ?? "",
    trx_code: trx_code ?? "",
    memo: memo ?? ""
  }

  useEffect(() => {
    if (coa_id && transaction_date) {
      ; (async () => {
        const coaBalance = await refetch(coa_id.toString(), transaction_date)

        methods.clearErrors(["payment_amount", "deposit_amount"])
        methods.setValue("coa_balance", coaBalance ?? 0)
      })()
    }
  }, [coa_id, transaction_date])

  return (
    <section className="container my-5 flex flex-col gap-5">
      <section>
        <HeaderMenu title="DATA ENTRY | PAYMENT INVOICE">
          <CodePaymentInvoice isEdit={isEdit} />
        </HeaderMenu>

        <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="w-full">
            <Controller
              control={methods.control}
              name="coa_id"
              render={({ field, fieldState }) => (
                <DropdownAccount
                  approvalView={approvalView}
                  value={field.value.toString()}
                  displayBalance={false}
                  label="RECEIVED BY THE ACCOUNT"
                  error={fieldState.error?.message}
                  onChange={async (data) => {
                    field.onChange(data?.value)
                  }}
                />
              )}
            />
          </section>

          <section className="lg:justify-self-end self-end mb-3">
            <Controller
              control={methods.control}
              name="coa_balance"
              render={({ field }) => (
                <NumberInput
                  disabled
                  textRight
                  label="BALANCE"
                  value={field.value}
                />
              )}
            />
          </section>
        </section>

        <section className="mt-3">
          <FormPaymentInvoice approvalView={approvalView} isEdit={isEdit} />
        </section>

        <section className="mt-6">
          <TableJournalEntry />
        </section>
      </section>

      <CommandButtons
        approvalView={approvalView}
        showDeleteButton={isEdit}
        onSave={onSubmit}
        onDelete={() => modalDelete.setActive(true)}
      />

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          navigate(-1)
        }}
      />
    </section>
  )
}
