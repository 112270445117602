// Components
import { BaseTable, Card, FooterMenu, Loading } from "components"

// Icons
import { IoSend } from "react-icons/io5"

// Third-Party Libraries
import { Link } from "react-router-dom"

// Utils
import { useHandleAuth } from "utils"
import { useList } from "./utils"

export default function AdjustmentInventory(): JSX.Element {
  return (
    <section className="container my-5 flex flex-col gap-5">
      <IdentitySection />

      <Card>
        <Card.Body className="flex flex-col gap-3">
          <BaseTable>
            <thead>
              <tr>
                <th>NO</th>
                <th colSpan={2}>COUNT ID#</th>
                <th>DATE</th>
                <th>LOCATION</th>
                <th>TRADER</th>
                <th>MEMO</th>
                <th>QTY COUNT</th>
                <th>STATUS</th>
              </tr>
            </thead>

            <Datatable />
          </BaseTable>
        </Card.Body>
      </Card>

      <FooterMenu hasCancelButton />
    </section>
  )
}

function IdentitySection(): JSX.Element {
  // Hooks
  const auth = useHandleAuth().userTokenData()

  return (
    <section>
      <div className="font-bold">EMPLOYEE: {auth?.employee_name}</div>
      <div className="font-bold">LOCATION: {auth?.location_name}</div>
    </section>
  )
}

function Datatable(): JSX.Element {
  // Hooks
  const { data, isLoading } = useList()

  return (
    <tbody>
      {isLoading || !data.length ? (
        <tr>
          <td colSpan={9}>
            <Loading errorText="No data available" loading={isLoading} />
          </td>
        </tr>
      ) : (
        data.map((item, key) => (
          <tr key={key}>
            <td className="text-center">{key + 1}</td>

            <td>
              <Link
                to={`/inventory/transaction/count-inventory/edit/${item.count_id}`}>
                <div className="flex justify-center">
                  <IoSend />
                </div>
              </Link>
            </td>

            <td>{item.trx_code}</td>
            <td>{item.transaction_date}</td>
            <td>{item.location_name}</td>
            <td>{item.employee_name}</td>
            <td>{item.memo}</td>
            <td>{item.real_count}</td>
            <td>{item.count_status}</td>
          </tr>
        ))
      )}
    </tbody>
  )
}
