// Types
import type { ListType } from "../../types"

export const yearlyList: ListType[] = [
  {
    "category": "Liquidity Ratios",
    "ratios": [
      {
        "name": "Current Ratio",
        "values": {
          "BUDGET": 1.8,
          "2024": 2.3,
          "2023": 1.9,
          "2022": 2.1,
          "2021": 2.7,
          "2020": 2.0,
          "2019": 2.5,
          "2018": 2.2,
          "2017": 2.6,
          "2016": 2.4,
          "2015": 2.8,
          "2014": 2.9,
          "2013": 3.0
        }
      },
      {
        "name": "Quick Current Ratio",
        "values": {
          "BUDGET": 1.5,
          "2024": 2.0,
          "2023": 1.8,
          "2022": 2.3,
          "2021": 1.9,
          "2020": 2.1,
          "2019": 2.4,
          "2018": 2.2,
          "2017": 2.6,
          "2016": 2.5,
          "2015": 2.7,
          "2014": 2.8,
          "2013": 2.9
        }
      }
    ]
  },
  {
    "category": "Leverage Ratios",
    "ratios": [
      {
        "name": "Debt-to-equity Ratio",
        "values": {
          "BUDGET": 1.0,
          "2024": 1.5,
          "2023": 1.2,
          "2022": 1.8,
          "2021": 1.3,
          "2020": 1.7,
          "2019": 1.4,
          "2018": 1.9,
          "2017": 1.6,
          "2016": 2.0,
          "2015": 1.1,
          "2014": 2.1,
          "2013": 2.2
        }
      },
      {
        "name": "Financial Leverage",
        "values": {
          "BUDGET": 2.8,
          "2024": 3.3,
          "2023": 3.0,
          "2022": 3.6,
          "2021": 3.1,
          "2020": 3.5,
          "2019": 3.2,
          "2018": 3.7,
          "2017": 3.4,
          "2016": 3.8,
          "2015": 2.9,
          "2014": 3.9,
          "2013": 4.0
        }
      },
      {
        "name": "Total Leverage",
        "values": {
          "BUDGET": 3.3,
          "2024": 3.8,
          "2023": 3.5,
          "2022": 4.0,
          "2021": 3.6,
          "2020": 3.9,
          "2019": 3.7,
          "2018": 4.1,
          "2017": 3.4,
          "2016": 4.2,
          "2015": 3.1,
          "2014": 4.3,
          "2013": 4.4
        }
      }
    ]
  },
  {
    "category": "Profitability Ratios",
    "ratios": [
      {
        "name": "Gross Margin Percentage",
        "values": {
          "BUDGET": 0.45,
          "2024": 0.50,
          "2023": 0.47,
          "2022": 0.53,
          "2021": 0.48,
          "2020": 0.52,
          "2019": 0.49,
          "2018": 0.54,
          "2017": 0.46,
          "2016": 0.55,
          "2015": 0.51,
          "2014": 0.56,
          "2013": 0.57
        }
      },
      {
        "name": "Net Operating Margin Percentage",
        "values": {
          "BUDGET": 0.25,
          "2024": 0.30,
          "2023": 0.27,
          "2022": 0.33,
          "2021": 0.28,
          "2020": 0.32,
          "2019": 0.29,
          "2018": 0.34,
          "2017": 0.26,
          "2016": 0.35,
          "2015": 0.31,
          "2014": 0.36,
          "2013": 0.37
        }
      },
      {
        "name": "Net Profit Margin",
        "values": {
          "BUDGET": 0.20,
          "2024": 0.25,
          "2023": 0.22,
          "2022": 0.28,
          "2021": 0.23,
          "2020": 0.27,
          "2019": 0.24,
          "2018": 0.29,
          "2017": 0.21,
          "2016": 0.30,
          "2015": 0.26,
          "2014": 0.31,
          "2013": 0.32
        }
      }
    ]
  },
  {
    "category": "Efficiency Ratios",
    "ratios": [
      {
        "name": "Return on Asset (ROA)",
        "values": {
          "BUDGET": 0.15,
          "2024": 0.20,
          "2023": 0.17,
          "2022": 0.23,
          "2021": 0.18,
          "2020": 0.22,
          "2019": 0.19,
          "2018": 0.24,
          "2017": 0.16,
          "2016": 0.25,
          "2015": 0.21,
          "2014": 0.26,
          "2013": 0.27
        }
      },
      {
        "name": "Inventory Turnover Ratio",
        "values": {
          "BUDGET": 5.5,
          "2024": 6.0,
          "2023": 5.7,
          "2022": 6.3,
          "2021": 5.8,
          "2020": 6.2,
          "2019": 5.9,
          "2018": 6.4,
          "2017": 5.6,
          "2016": 6.5,
          "2015": 6.1,
          "2014": 6.6,
          "2013": 6.7
        }
      }
    ]
  },
  {
    "category": "Investment Ratios",
    "ratios": [
      {
        "name": "Return on Equity (ROE)",
        "values": {
          "BUDGET": 0.17,
          "2024": 0.22,
          "2023": 0.19,
          "2022": 0.25,
          "2021": 0.20,
          "2020": 0.24,
          "2019": 0.21,
          "2018": 0.26,
          "2017": 0.18,
          "2016": 0.27,
          "2015": 0.23,
          "2014": 0.28,
          "2013": 0.29
        }
      }
    ]
  },
  {
    "category": "Operating Ratios",
    "ratios": [
      {
        "name": "Operating Leverage",
        "values": {
          "BUDGET": 1.8,
          "2024": 2.3,
          "2023": 2.0,
          "2022": 2.6,
          "2021": 2.1,
          "2020": 2.5,
          "2019": 2.2,
          "2018": 2.7,
          "2017": 1.9,
          "2016": 2.8,
          "2015": 2.4,
          "2014": 2.9,
          "2013": 3.0
        }
      }
    ]
  }
]
