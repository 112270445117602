// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

// Types
interface Type {
  reject_id: number
  trx_code: string
  transaction_date: string
  trx_bill_code: string
  vendor_name: string
  location_name: string
  memo: string
  approve_status: number
  qty: number
  total: number
  reject_status: number
}

export function useList(): {
  data: Type[]
  isLoading: boolean
  statusReject: string
  setStatusReject: React.Dispatch<React.SetStateAction<string>>
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<Type[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const [statusReject, setStatusReject] = useState<string>("")

  useEffect(() => {
    setLoading(true)

    api.post(
      `/proreject/getdata${statusReject ? `?Approval=${statusReject}` : ""}`,
    ).then((res: SuccessFetch<{
      payload: Type[]
    }>) => {
      setData(res.data.payload)
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [statusReject])

  return { data, isLoading, statusReject, setStatusReject }
}