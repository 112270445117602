import { Button, CommandButton, FooterMenu } from "components"
import { Fragment } from "react"

type CommandButtonsProps = {
  display?: boolean
  isSubmitting?: boolean
  showSaveButton?: boolean
  onSave?: () => void
}

export const CommandButtons = ({
  display = true,
  isSubmitting,
  showSaveButton,
  onSave
}: CommandButtonsProps) => {
  return (
    <div className={display ? "" : "hidden"}>
      <FooterMenu
        hasCancelButton
        customElement={{
          save: (
            <Fragment>
              {showSaveButton && (
                <Button
                  disabled={isSubmitting}
                  className="uppercase"
                  color="primary"
                  onClick={onSave}>
                  Close Sales
                </Button>
              )}
            </Fragment>
          ),
          journal: <CommandButton actiontype="journal" />,
          attachment: <CommandButton actiontype="attachment" />
        }}
      />
    </div>
  )
}
