import { useEffect, useState } from "react"
import type { ErrorFetch, SuccessFetch } from "../../../../../../../../../../types"
import { BuyingList, ComponentList, PictureList, ProductList, ProfileList, SellingList } from "../../../../../../types"
import { useApi } from "../../../../../../../../../../utils"
import toast from "react-hot-toast"

export function useDetail() {
  const api = useApi()
  const [data, setData] = useState<ProductList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/productcode")
    .then((res: SuccessFetch<{ payload: ProductList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}


export function useDefaultSize() {
  const api = useApi()
  const [dataDefault, setData] = useState<ProductList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/default-size")
    .then((res: SuccessFetch<{ payload: ProductList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataDefault, dataError, isLoading }
}
export function useDetailProfile(id: number | null) {
  const api = useApi()
  const [data, setData] = useState<ProfileList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if(id) {
      api.get("/employee/profile", { params: { employee_id: id } })
      .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
      .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
      .finally(() => setLoading(false))
    
      return () => {
        setData(null)
        setError(undefined)
        setLoading(true)
      }
    }
    // eslint-disable-next-line
  }, [])

  return { data, dataError, isLoading }
}
export function useProductByGroupId(id: string) {
  const api = useApi()
  const [dataGroupById, setData] = useState<ProductList[] | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/bygroup", { params: { group: id } })
    .then((res: SuccessFetch<{ payload: ProductList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataGroupById, dataError, isLoading }
}
export function useDetailPersonalData(id: number) {
  const api = useApi()
  const [dataDetail, setData] = useState<ProductList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  const refetchDetail = () => {
    setLoading(true)

    api.get("/product/byid", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ProductList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/byid", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ProductList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataDetail, dataError, isLoading, refetchDetail }
}
export function usePriceUnit(id: number) {
  const api = useApi()
  const [dataPriceUnit, setData] = useState<any | null>(null)
  const [errorPriceUnit, setError] = useState<string | undefined>(undefined)
  const [isLoadingPriceUnit, setLoading] = useState(true)

  const refetchPriceUnit = () => {
    setLoading(true)

    api.get("/product/priceunit", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: any }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/priceunit", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: any }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [id])

  return { dataPriceUnit, errorPriceUnit, isLoadingPriceUnit, refetchPriceUnit }
}
export function useDetailProfileData(id: number) {
  const api = useApi()
  const [dataProfile, setData] = useState<ProfileList | null>(null)
  const [errorProfile, setError] = useState<string | undefined>(undefined)
  const [isLoadingProfile, setLoading] = useState(true)

  const refetchProfile = () => {
    setLoading(true)

    api.get("/product/profile", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/profile", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ProfileList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataProfile, errorProfile, isLoadingProfile, refetchProfile }
}
export function useDetailBuyingData(id: number) {
  const api = useApi()
  const [dataBuying, setData] = useState<BuyingList | null>(null)
  const [errorBuying, setError] = useState<string | undefined>(undefined)
  const [isLoadingBuying, setLoading] = useState(true)

  const refetchBuying = () => {
    setLoading(true)

    api.get("/product/priceunit", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: BuyingList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/priceunit", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: BuyingList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataBuying, errorBuying, isLoadingBuying, refetchBuying }
}
export function useDetailSellingData(id: number) {
  const api = useApi()
  const [dataSelling, setData] = useState<SellingList[]>([])
  const [errorSelling, setError] = useState<string | undefined>(undefined)
  const [isLoadingSelling, setLoading] = useState(true)

  const refetchSelling = () => {
    setLoading(true)

    api.get("/product/selling", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: SellingList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/selling", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: SellingList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData([])
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataSelling, errorSelling, isLoadingSelling, refetchSelling }
}
export function useDetailBuyingDataTable(id: number) {
  const api = useApi()
  const [dataList, setData] = useState<BuyingList | null>(null)
  const [errorList, setError] = useState<string | undefined>(undefined)
  const [isLoadingList, setLoading] = useState(true)

  const refetchBuyingTable = () => {
    setLoading(true)

    api.get("/product/buying", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: BuyingList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/buying", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: BuyingList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataList, errorList, isLoadingList, refetchBuyingTable }
}
export function useDetailPictureData(id: number) {
  const api = useApi()
  const [dataPicture, setData] = useState<PictureList[]>([])
  const [errorPicture, setError] = useState<string | undefined>(undefined)
  const [isLoadingPicture, setLoading] = useState(false)

  const refetchPicture = () => {
    setLoading(true)

    api.get("/product/picture", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: PictureList[] }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/picture", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: PictureList[] }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  return { dataPicture, errorPicture, isLoadingPicture, refetchPicture }
}
export function useItemProductTable(id: number) {
  const api = useApi()
  const [dataList, setData] = useState<ComponentList[] | null>(null)
  const [errorList, setError] = useState<string | undefined>(undefined)
  const [isLoadingList, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/builditem", { params: { group: id } })
    .then((res: SuccessFetch<{ payload: ComponentList[] }>) => setData(res?.data?.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err?.response?.data?.detail?.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [id])

  return { dataList, errorList, isLoadingList }
}
export function useIngredientFormulaTable(id: number) {
  const api = useApi()
  const [dataComponentTable, setData] = useState<ComponentList[] | null>(null)
  const [errorComponentTable, setError] = useState<string | undefined>(undefined)
  const [isLoadingComponentTable, setLoading] = useState(true)

  const refetchComponentTable = () => {
    setLoading(true)

    api.get("/product/component", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ComponentList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/component", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ComponentList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [id])

  return { dataComponentTable, errorComponentTable, isLoadingComponentTable, refetchComponentTable }
}
export function useCOGSManufacture(id: number) {
  const api = useApi()
  const [dataCOGS, setData] = useState<ComponentList[] | null>(null)
  const [errorCOGS, setError] = useState<string | undefined>(undefined)
  const [isLoadingCOGS, setLoading] = useState(true)

  const refetchCOGSManufacture = () => {
    setLoading(true)

    api.get("/product/componentcost", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ComponentList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    api.get("/product/componentcost", { params: { product_id: id } })
    .then((res: SuccessFetch<{ payload: ComponentList[] }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [id])

  return { dataCOGS, errorCOGS, isLoadingCOGS, refetchCOGSManufacture }
}
export function useProductCode() {
  const api = useApi()
  const [dataCode, setData] = useState<ProductList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/productcode")
    .then((res: SuccessFetch<{ payload: ProductList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { dataCode, dataError, isLoading }
}
export function usePictureCode() {
  const api = useApi()
  const [pictureCode, setData] = useState<PictureList | null>(null)
  const [dataError, setError] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    api.get("/product/genproductpicturecode")
    .then((res: SuccessFetch<{ payload: PictureList }>) => setData(res.data.payload))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => setError(err.response.data.detail.message))
    .finally(() => setLoading(false))
  
    return () => {
      setData(null)
      setError(undefined)
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [])

  return { pictureCode, dataError, isLoading }
}
export function useProductBarcode() {
  // Hooks
  const api = useApi()
  const [isLoading, setLoading] = useState(false)

  // Functions
  const refetchBarcode = async (
    material?: string,
    category?: string,
    style?: string,
    colour?: string,
    group?: string
  ): Promise<string> => {
    setLoading(true)

    return new Promise<string>(async (resolve) => {
      return api.get(
        "/product/generatebarcode",
        {
          params: {
            mr_product_group_id: group,
            mr_product_material_id: material,
            mr_product_category_id: category,
            mr_product_style_id: style,
            mr_product_colour_id: colour
          }
        }
      ).then((res: SuccessFetch<{
        payload: ProductList
      }>) => {
        resolve(res.data.payload.productbarcode)
      }).catch(() => {
        toast.error("Fetch Code Error")
        resolve("")
      }).finally(() => {
        setLoading(false)
      })
    })
  }

  return { isLoading, refetchBarcode }
}
export function useProductName() {
  // Hooks
  const api = useApi()
  const [isLoading, setLoading] = useState(false)

  // Functions
  const refetchName = async (
    material?: string,
    category?: string,
    style?: string,
    colour?: string,
    length?: string,
    width?: string,
    height?: string
  ): Promise<string> => {
    setLoading(true)

    return new Promise<string>((resolve) => {
      return api.get(
        "/product/generateproductname",
        {
          params: {
            length: length !== "" ? length : undefined,
            width: width !== "" ? width : undefined,
            height: height !== "" ? height : undefined,
            mr_product_material_id: material,
            mr_product_category_id: category,
            mr_product_style_id: style,
            mr_product_colour_id: colour
          }
        }
      )
      .then((res: SuccessFetch<{
        payload: {
          productname: string
        }
      }>) => {
        resolve(res.data.payload.productname)
      }).catch(() => {
        toast.error("Fetch Code Error")
        resolve("")
      }).finally(() => {
        setLoading(false)
      })
    })
  }

  return { isLoading, refetchName }
}
export function useImages() {
  const api = useApi()
  const [dataImages, setData] = useState<string | null>(null)
  const [errorImages, setError] = useState<string | undefined>(undefined)
  const [isLoadingImages, setLoading] = useState(false)

  const refetchImages = (path?: string) => {
    setLoading(true)

    const params: { [key: string]: number | string | undefined } = {}; 
    if (path) params.imgpath = path;

    api.get("getimage/", { params }) 
    .then(res => setData(res?.request?.responseURL))
    .catch((err: ErrorFetch<{ detail: { message: string } }>) => {
      setError(err?.response?.data?.detail?.message)
      setData('')
    })
    .finally(() => setLoading(false))
  }

  return { dataImages, errorImages, isLoadingImages, refetchImages }
}