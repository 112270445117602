// React
import { createContext } from "react"

// Types
import type { DataBillType } from "../types"

export const DataBillContext = createContext<DataBillType>({
  approve_status: 0,
  approval_status: "",
  approved_by: "",
  bill_status: "",
  po_code: ""
})