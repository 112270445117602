// React
import { Fragment } from "react"

// Components
import { Modal } from "components"

// Icons
import { TbChartBar } from "react-icons/tb"

// Third-Party Libraries
import { BarChart, Bar, XAxis, YAxis, Tooltip, ReferenceLine, ResponsiveContainer } from 'recharts'
import { random } from "uniqolor"

// Types
import type { ListType } from "../types"

// Utils
import { useToggle } from "utils"

export function TableSection(params: {
  headerList: string[]
  list: ListType[]
}): JSX.Element {
  return (
    <table className="table table-bordered">
      <thead>
        <tr className="bg-black text-white">
          <th>KEY DESCRIPTION</th>
          {params.headerList.map((item, key) => <th key={key}>{item}</th>)}
          <th />
        </tr>
      </thead>

      <tbody>
        {params.list.map((item, key) => (
          <Fragment key={key}>
            <tr
              key={key}
              className="bg-base-200"
            >
              <td>{item.category}</td>
              <td colSpan={15} />
            </tr>

            {item.ratios.map((item, key) => {
              // Vars
              const values = Object.entries(item.values).map(([_, value]) => value)

              return (
                <tr key={key}>
                  <td>{item.name}</td>

                  {values.map((value, key) => (
                    <td
                      key={key}
                      className="text-right"
                    >
                      {value}
                    </td>
                  ))}

                  <td>
                    <ChartSection
                      headerList={params.headerList}
                      list={values}
                      title={item.name}
                    />
                  </td>
                </tr>
              )
            })}
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

function ChartSection(params: {
  headerList: string[]
  list: number[]
  title: string
}) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  // Vars
  const data: {
    name: string
    value: number
    fill: string
  }[] = [...Array(params.headerList.length - 1)].map((_, key) => {
    return {
      name: params.headerList[key + 1],
      value: params.list[key + 1],
      fill: random().color
    }
  })

  return (
    <Fragment>
      <td>
        <button onClick={toggle}>
          <TbChartBar />
        </button>
      </td>

      {isActive && (
        <Modal
          isOpen
          title={`${params.title.toUpperCase()} CHART`}
          closeModal={toggle}
        >
          <Modal.Body>
            <ResponsiveContainer
              width={"100%"}
              height={"100%"}
            >
              <BarChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <YAxis />
                <Bar dataKey="value" />
                <Tooltip formatter={(value) => [`${value}`, params.title]} />

                <XAxis
                  dataKey="name"
                  interval={0}
                  fontSize={12}
                />
                
                <ReferenceLine
                  y={params.list[0]}
                  stroke="red"
                  strokeDasharray="3 3"
                  width={2}
                  label={{
                    fill: 'red',
                    fontWeight: 'bold',
                    position: 'left',
                    value: params.headerList[0]
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  )
}