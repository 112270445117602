// React
import { useState } from "react"

// Types
interface ErrorType {
  index: number,
  message: string[]
}
type StatusType = "success" | "error"

export function useRowStatus(): {
  error: ErrorType[]
  index: number
  success: number
  toggle: (status?: StatusType, messages?: string[]) => void
} {
  // Hooks
  const [index, setIndex] = useState<number>(-1)
  const [success, setSuccess] = useState<number>(0)
  const [error, setError] = useState<ErrorType[]>([])

  const toggle = (status?: StatusType, messages?: string[]): void => {
    if (status === "error") {
      setError(prev => {
        // Vars
        const value = [...prev]

        value.push({
          index,
          message: messages ?? []
        })

        return value
      })
    } else if (status === "success") {
      setSuccess(prev => prev + 1)
    }

    setTimeout(() => {
      setIndex(prev => prev + 1)
    }, 200)
  }

  return { error, index, success, toggle }
}