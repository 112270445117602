// Types
import type { SelectOptionType } from "types"

export const statusList: SelectOptionType[] = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Open",
    value: "1"
  },
  {
    label: "Close",
    value: "2"
  },
  {
    label: "Credit",
    value: "3"
  }
]