// React
import { Fragment, useContext } from "react"

// Components
import { BaseTable, Button, Card, Checkbox, ErrorText, HeaderMenu, Input, Loading, Select, Textarea } from "components"
import { Date } from "../../components"
import { Code } from "./Code"
import { TableRow } from "./TableRow"

// Contexts
import { DataContext, ListContext, ParamsContext } from "../contexts"

// Form
import { ErrorMessage } from "@hookform/error-message"
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Icons
import { IoSendSharp } from "react-icons/io5"
import { TbChevronRight } from "react-icons/tb"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import type { SelectOptionType } from "types"
import type { DetailType, FormType } from "../utils"

// Utils
import { convertNumber } from "utils"

export function InputSection(params: {
  actionbutton?: JSX.Element
}): JSX.Element {
  // Hooks
  const { data } = useContext(DataContext)
  const { isDisabled } = useContext(ParamsContext)

  // Vars
  const defaultLocation: SelectOptionType[] = [
    {
      label: data.location_name,
      value: data.location_id
    }
  ]
  const defaultSupplier: SelectOptionType[] = [
    {
      label: data.vendor_name,
      value: data.vendor_id
    }
  ]

  // Form
  const { control, formState } = useFormContext<FormType>()

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | REJECTED ITEM">
        <Code />
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
        <Select
          isDisabled
          label="SUPPLIER"
          value={defaultSupplier[0]}
          options={defaultSupplier}
        />

        <div className="mb-3 flex flex-wrap">
          TRADING TERM <span className="mt-1"><TbChevronRight /></span> Net {data.payment_term} days after OEM
        </div>

        <section className="mb-3">
          <Checkbox
            readOnly
            value={data.tax_inclusive}
          >
            TAX-INCLUSIVE
          </Checkbox>
        </section>
      </section>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="flex flex-col gap-3">
            <section>
              <Select
                isDisabled
                label="DELIVER TO"
                value={defaultLocation[0]}
                options={defaultLocation}
              />

              <Textarea
                disabled
                value={data.address}
              />
            </section>

            <Controller
              name="memo"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label="MEMO"
                  error={fieldState.error?.message}
                  disabled={isDisabled}
                />
              )}
            />

            <Input
              disabled
              label="LINK TO PURCHASE ID"
              value={data.trx_code}
            />
          </section>

          <section className="flex flex-col gap-3">
            <Date
              name="transaction_date"
              disabled={isDisabled}
            />

            <Input
              disabled
              label="DELIVERY"
              value={`#${data.delivery_no}`}
            />

            <Controller
              name="referensi"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label="REFERENCE"
                  error={fieldState.error?.message}
                  disabled={isDisabled}
                />
              )}
            />
          </section>
        </Card.Body>
      </Card>

      <section>
        <BaseTable>
          <thead>
            <tr>
              <th>BACK DELIVERED</th>
              <th>QTY REJECTED</th>
              <th>ITEM CODE</th>
              <th>DESCRIPTION</th>
              <th>UNIT</th>
              <th>PRICE</th>
              <th>DISC(%)</th>
              <th>TOTAL</th>
              <th>TAX</th>
              <th>ACTION</th>
            </tr>
          </thead>

          <tbody>
            <RejectDetail />
          </tbody>
        </BaseTable>

        <ErrorMessage
          name="prorejectetail"
          errors={formState.errors}
          render={({ message }) => <ErrorText text={message} />}
        />
      </section>

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <Approval />
        <Acc />
      </section>

      <section>
        <section className="min-w-[126px]">
          <Button
            size="sm"
            color="ghost"
            className="no-animation w-fit bg-[#3c3c3c] hover:bg-primary hover:text-black whitespace-nowrap bg-primary text-black"
          >
            DETAIL BILL
          </Button>
        </section>

        <BaseTable>
          <thead>
            <tr>
              <th></th>
              <th>DELIVERED</th>
              <th>QTY REJECTED</th>
              <th>BACK DELIVERED</th>
              <th>ITEM CODE</th>
              <th>DESCRIPTION</th>
              <th>UNIT</th>
              <th>PRICE</th>
              <th>DISC(%)</th>
              <th>TOTAL</th>
              <th>TAX</th>
            </tr>
          </thead>

          <tbody>
            <DetailBill />
          </tbody>
        </BaseTable>
      </section>

      {params.actionbutton}
    </section>
  )
}

function Acc(): JSX.Element {
  // Form
  const { control } = useFormContext<FormType>()
  const prorejectdetail = useWatch({
    control,
    name: "prorejectdetail"
  })

  // Functions
  const getSubtotalItem = (item: DetailType): number => {
    // Vars
    const subtotal = item.quantity_reject * item.price_reject

    return subtotal - (subtotal * item.discount / 100)
  }

  // Vars
  const subtotal = prorejectdetail.reduce((acc, item) => {
    return acc + getSubtotalItem(item)
  }, 0)
  const tax = prorejectdetail.reduce((acc, item) => {
    return acc + (getSubtotalItem(item) * item.tax_rate / 100)
  }, 0)
  const total = subtotal + tax

  return (
    <section className="flex flex-col lg:items-end gap-3">
      <BaseTable className="w-full min-w-[100px] md:max-w-[400px] text-right">
        <tbody className="text-lg">
          <tr className="border-dotted border-b-2">
            <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">SUBTOTAL</td>
            <td className="pt-5">{convertNumber(subtotal).intoCurrency}</td>
          </tr>
          <tr className="border-dotted border-b-2">
            <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">TAX</td>
            <td className="pt-5">{convertNumber(tax).intoCurrency}</td>
          </tr>
          <tr className="border-dotted border-b-2">
            <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">TOTAL REJECT</td>
            <td className="pt-5">{convertNumber(total).intoCurrency}</td>
          </tr>
          <tr className="border-dotted border-b-2">
            <td className="w-[1px] whitespace-nowrap pr-3 pt-5 uppercase font-bold">BALANCE</td>
            <td className="pt-5">{convertNumber(total).intoCurrency}</td>
          </tr>
        </tbody>
      </BaseTable>
    </section>
  )
}

function Approval(): JSX.Element {
  // Hooks
  const { data } = useContext(DataContext)

  return (
    <section className="flex flex-col gap-3">
      <Input
        disabled
        label="REJECT STATUS"
        value={data.reject_status}
      />

      <Input
        disabled
        label="APPROVE STATUS"
        value={data.approve_status}
      />

      <Input
        disabled
        label="APPROVE BY"
        value={data.approve_by_name}
      />
    </section>
  )
}

function DetailBill(): JSX.Element {
  // Hooks
  const { detail } = useContext(DataContext)
  const { isDisabled } = useContext(ParamsContext)

  // Form
  const { getValues, setValue } = useFormContext<FormType>()

  return (
    <Fragment>
      {!detail.length ? (
        <tr>
          <td colSpan={12}>
            <Loading errorText="No data available" />
          </td>
        </tr>
      ) : detail.map((item, key) => (
        <tr key={key}>
          <td>
            {!isDisabled && (
              <button
                className="-rotate-90"
                onClick={() => {
                  // Vars
                  const value = [...getValues("prorejectdetail")]
                  const product = value.find(i => i.product_id === item.product_id)

                  if (product) {
                    toast.error("Product is already on Reject Detail Table")
                    return null
                  }

                  value.push({
                    discount: item.discount,
                    price_reject: item.price_bill,
                    product_id: item.product_id,
                    quantity_bill: item.quantity_bill,
                    quantity_rate: item.quantity_rate,
                    quantity_reject: item.total_qty_reject,
                    tax_id: item.tax_id,
                    tax_rate: item.tax_rate
                  })

                  setValue("prorejectdetail", value)
                }}
              >
                <IoSendSharp />
              </button>
            )}
          </td>
          <td>{item.quantity_bill}</td>
          <td>{item.total_qty_reject}</td>
          <td>{item.quantity_bill - item.total_qty_reject}</td>
          <td>{item.product_barcode}</td>
          <td>{item.product_name}</td>
          <td>{item.unit_measure}</td>
          <td className="text-right">{item.price_bill.toLocaleString()}</td>
          <td>{item.discount}%</td>
          <td className="text-right">{item.total.toLocaleString()}</td>
          <td>{item.tax_name}</td>
        </tr>
      ))}
    </Fragment>
  )
}

function RejectDetail(): JSX.Element {
  // Form
  const { control, getValues, setValue } = useFormContext<FormType>()
  const prorejectdetail = useWatch({
    control,
    name: "prorejectdetail"
  })

  return (
    <Fragment>
      {prorejectdetail.map((item, key) => {
        return (
          <ListContext.Provider
            value={{
              item,
              index: key
            }}
          >
            <TableRow
              key={`${item.product_id}-${key}`}
              remove={() => {
                // Vars
                const prorejectdetail = [...getValues("prorejectdetail")]

                prorejectdetail.splice(key, 1)
                setValue("prorejectdetail", prorejectdetail)
              }}
            />
          </ListContext.Provider>
        )
      })}
    </Fragment>
  )
}