// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton } from "components"
import { ModalDelete } from "../../Invoice/components"

// Contexts
import { dataContext } from ".."

// Utils
import { useToggle } from "utils"

export function Delete(props: {
	data: any
}) {
	// Hooks
	const showToggle = useToggle(false)
	const { refetch } = useContext(dataContext)

	return (
		<Fragment>
			{props?.data?.status_approval !== '4' && <ActionButton.Delete onClick={showToggle.toggle} />}

			{showToggle.isActive && (
				<ModalDelete
					data={props.data}
					modalState={showToggle}
					onSuccess={refetch}
				/>
			)}
		</Fragment>
	)
}