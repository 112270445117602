// React
import { Fragment } from "react"

// Components
import { AccountModal, Button } from "components"

// Icons
import { FaHistory, FaPlus } from "react-icons/fa"

// Utils
import { useToggle } from "utils"

export function AddAccount(params: {
  isDetail?: boolean
  defaultGroup: string
  onSuccess: () => void
  refetch?: () => void
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  if (params.isDetail) {
    return <Fragment />
  }

  return (
    <Fragment>
      <div className="mt-1 flex">
        <Button
          type="button"
          color="primary"
          onClick={params.refetch}
        >
          <FaHistory />

        </Button>
        <Button
          type="button"
          color="primary"
          className="!rounded-r-lg"
          onClick={toggle}
        >

          <FaPlus />
        </Button>
      </div>

      {isActive && (
        <AccountModal
          onChange={() => {}}
          onAddSuccess={params.onSuccess}
          toggle={toggle}
          defaultGroup={params.defaultGroup}
        />
      )}
    </Fragment>
  )
}