// React
import { useContext, useEffect, useState } from "react"

// Components
import {
  BaseTable,
  Button,
  CancelButton,
  Card,
  CommandButton,
  ErrorText,
  HeaderMenu,
  Input,
  Loading
} from "components"
import {
  Acc,
  ActionButton,
  Date,
  Deliver,
  Freight,
  Memo,
  PromisedDate,
  Supplier,
  SupplierId,
  TabSection,
  TableSection,
  TaxInclusive,
  TradingTerm
} from "../components"
import {
  Code,
  Detail,
  Journal,
  JournalModals,
  Payment,
  Purchases
} from "./components"

// Contexts
import { DataJournalContext } from "contexts"
import { ProductContext } from "../PurchaseOrder/components/DetailBody/contexts"
import { DataContext, ProductUnitProvider, ProbilContext } from "./contexts"

// Form
import { ErrorMessage } from "@hookform/error-message"
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Icons
import { TbPlus } from "react-icons/tb"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Types
import type { DetailFormType, FormType } from "./utils"

// Utils
import { freightAlert, useApi, useProject, useTaxCode } from "utils"
import { useProduct } from "../PurchaseOrder/components/DetailBody/utils"
import {
  tab_list,
  validationSchema,
  useDetail,
  duplicateChecker
} from "./utils"

export default function EnterBill(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading } = useDetail(id!)

  if (isLoading || !data) {
    return <Loading errorText="No data available" loading={isLoading} />
  }

  return (
    <DataContext.Provider value={data}>
      <FormSection />
    </DataContext.Provider>
  )
}

export function FormSection() {
  // Hooks
  const api = useApi()
  const dataBill = useContext(DataContext)
  const navigate = useNavigate()
  const project = useProject()
  const tax_code = useTaxCode()
  const { id } = useParams()
  const { data, isLoading, refetch } = useProduct()

  // Vars
  const datapo = dataBill

  // Form
  const defaultValues: FormType = {
    memo: "Bill transaction",
    probildetail: [],
    referensi: "",
    trx_code: "",
    address: datapo.address,
    delivery_no: (datapo.delivered + 1).toString(),
    freight: datapo.sugest_freight,
    location_id: datapo.location_id,
    payment_term: datapo.payment_term,
    promise_date: datapo.promize_date,
    purchase_order_id: id!,
    reminder: dataBill.reminder,
    tax_inclusive: datapo.tax_inclusive,
    transaction_date: moment().format("YYYY-MM-DD"),
    vendor_id: datapo.vendor_id
  }
  const onSubmit = (value: FormType) => {
    // Vars
    const final_value = {
      purchase_order_id: id,
      trx_code: value.trx_code,
      vendor_id: value.vendor_id,
      location_id: value.location_id,
      address: value.address,
      memo: value.memo,
      transaction_date: value.transaction_date,
      referensi: value.referensi,
      delivery_no: value.delivery_no,
      freight: value.freight,
      promise_date: value.promise_date,
      reminder: value.reminder,
      probildetail: value.probildetail.map((item) => {
        return {
          quantity_bill: item.quantity_bill,
          quantity_unit: item.quantity_unit,
          product_id: item.product_id,
          price_bill: item.price_bill,
          discount: item.discount,
          total: item.total,
          tax_id: item.tax_id
        }
      })
    }

    return new Promise<void>((resolve) => {
      const submitForm = (): void => {
        toast
          .promise(api.post("/probill/add", final_value), {
            loading: "Loading...",
            success: (res) => res.data.message,
            error: (err) =>
              err.response.data.detail?.message ?? err.response.data.message
          })
          .then(() => {
            navigate(-1)
          })
          .catch(() => {})
          .finally(resolve)
      }

      if (!value.freight) {
        freightAlert("Bill")
          .then(() => {
            submitForm()
          })
          .catch(() => {
            return resolve()
          })
      } else {
        submitForm()
      }
    })
  }
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const vendor_id = useWatch({
    control: methods.control,
    name: "vendor_id"
  })
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "probildetail"
  })

  useEffect(() => {
    if (vendor_id) {
      refetch(vendor_id)
    }

    // eslint-disable-next-line
  }, [vendor_id])

  return (
    <FormProvider {...methods}>
      <section className="container my-5 flex flex-col gap-2">
        <HeaderMenu title="DATA ENTRY | DELIVERY BILL">
          <Code />
        </HeaderMenu>

        <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
          <Supplier
            disabled
            name="vendor_id"
            trading_term_name="payment_term"
            vendor_name="_vendor_name"
          />

          <TradingTerm name="payment_term" />
          <TaxInclusive name="tax_inclusive" />
        </section>

        <Card>
          <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
            <section className="flex flex-col gap-3">
              <Deliver deliver_name="location_id" detail_name="address" />

              <Memo name="memo" />
              <LinkToPurchase />
            </section>

            <section className="flex flex-col gap-3">
              <Date name="transaction_date" />
              <SupplierId name="referensi" />

              <PromisedDate disabled name="promise_date" />

              <Delivery />

              <section className="flex lg:justify-end">
                <Button className="w-fit">
                  <TbPlus /> ADD REMINDER
                </Button>
              </section>
            </section>
          </Card.Body>
        </Card>

        <DataJournalContext.Provider value={{ project, tax_code }}>
          <ProductContext.Provider value={{ data, isLoading }}>
            <ProductUnitProvider>
              <BaseTable>
                <thead>
                  <tr>
                    <th>BACKORDER</th>
                    <th>DELIVERED</th>
                    <th>ITEM CODE</th>
                    <th>DESCRIPTION</th>
                    <th>UNIT</th>
                    <th>PRICE</th>
                    <th>DISC (%)</th>
                    <th>TOTAL</th>
                    <th>JOB</th>
                    <th>TAX</th>
                    <th>ACTION</th>
                  </tr>
                </thead>

                <tbody>
                  {fields.map((_, key) => (
                    <Detail
                      key={key}
                      index={key}
                      remove={remove}
                    />
                  ))}
                </tbody>
              </BaseTable>

              <ErrorMessage
                errors={methods.formState.errors}
                name="probildetail"
                render={({ message }) => <ErrorText text={message} />}
              />
            </ProductUnitProvider>

            <ErrorMessage
              name="probildetail"
              errors={methods.formState.errors}
              render={({ message }) => <ErrorText text={message} />}
            />

            <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
              <section className="flex flex-col gap-3">
                <TableSection>
                  <TableSection.Row label="BILL STATUS" value="-" />
                  <TableSection.Row label="APPROVE STATUS" value="-" />
                  <TableSection.Row label="APPROVED BY" value="-" />
                </TableSection>

                <Freight name="freight" />
              </section>

              <Acc
                name={{
                  detail: "probildetail",
                  freight: "freight"
                }}
              />
            </section>

            <ProbilContext.Provider
              value={{
                append: (value: DetailFormType) => {
                  // Vars
                  const data = duplicateChecker(
                    value,
                    methods.getValues("probildetail"),
                    false
                  )

                  if (data) {
                    append(value)
                  }
                }
              }}>
              <Tab />
            </ProbilContext.Provider>
          </ProductContext.Provider>
        </DataJournalContext.Provider>

        <ActionButton>
          <section className="flex flex-wrap gap-3">
            <CommandButton actiontype="help" />
            <CommandButton actiontype="print" />
            <CommandButton actiontype="email" />
            <CommandButton actiontype="export" />

            <DataJournalContext.Provider value={{ project, tax_code }}>
              <ProductContext.Provider value={{ data, isLoading }}>
                <JournalModals />
              </ProductContext.Provider>
            </DataJournalContext.Provider>

            <CommandButton actiontype="attachment" />
          </section>

          <section className="flex flex-wrap gap-3">
            <CancelButton />

            <CommandButton
              actiontype="save"
              permission="PT051"
              loading={methods.formState.isSubmitting ? "true" : undefined}
              onClick={methods.handleSubmit(onSubmit)}
            />
          </section>
        </ActionButton>
      </section>
    </FormProvider>
  )
}

function Delivery(): JSX.Element {
  // Form
  const { getValues } = useFormContext<FormType>()

  return (
    <Input disabled label="DELIVERY" value={`#${getValues("delivery_no")}`} />
  )
}

function LinkToPurchase() {
  // Hooks
  const data = useContext(DataContext)

  return <Input disabled label="LINK TO PURCHASE ID" value={data.trx_code} />
}

function Tab(): JSX.Element {
  // Vars
  const content = [Purchases, Payment, Journal]

  // Hooks
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <section>
      <TabSection
        activeIndex={currentTab}
        list={tab_list}
        onChangeTab={setCurrentTab}
      />

      {content.map((Element, key) => {
        if (currentTab === key) {
          return <Element key={key} />
        }

        return null
      })}
    </section>
  )
}
