import { Modal } from 'components';
import { Fragment, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useToggle } from 'utils';
import { PicturesType } from 'pages/Inventori/Transaction/AddNewItem/types';


export function PicturesSection(props: {
    items: number
    dataPicture?: PicturesType[]
    className?: string 
}) {
    const { isActive, toggle } = useToggle(false)
    const [imageDetail, setImageDetail] = useState<string>()
    const { REACT_APP_BASE_URL_API } = process.env

    const responsive = {
        mobile: {
            breakpoint: { max: 9999, min: 0 },
            items: 1
        }
    };

    const selectedImage = (value: string) => {
        setImageDetail(value)
        toggle()
    }
    return (
        <Fragment>
            <Carousel
                additionalTransfrom={0}
                arrows={true}
                autoPlaySpeed={3000}
                centerMode={false}
                className={props.className}
                containerClass=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
                customLeftArrow={<div className='absolute top-[50%] transform translate-y-[-50%] left-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg ml-2'>❮</div>}
                customRightArrow={<div className='absolute top-[50%] transform translate-y-[-50%] right-[10px] z-[5] px-2 py-2 bg-slate-300 bg-opacity-25 cursor-pointer rounded-lg mr-2'>❯</div>}
            >
                    {props?.dataPicture ? (
                    props.dataPicture.map((item, i) => (
                        <img
                            src={`${REACT_APP_BASE_URL_API}/getimage/?imgpath=${item?.path}`}
                            alt=""
                            onClick={() => selectedImage(item?.path)}
                            className='cursor-pointer object-cover w-full h-full object-fit'
                        />
                    ))
                ) : (
                    <div></div>
                )}
            </Carousel>

            {isActive && <DetailImage toggle={toggle} image={imageDetail} />}
        </Fragment>
    )
}

function DetailImage(props: {
    toggle: () => void,
    image: string | undefined
}) {
    const { REACT_APP_BASE_URL_API } = process.env
    return (
        <Modal isOpen title='' size='lg' closeModal={props.toggle}>
            <Modal.Body>
                <img src={`${REACT_APP_BASE_URL_API}/getimage/?imgpath=${props?.image}`} alt="" className='w-full' />
            </Modal.Body>
        </Modal>
    )
}