// Pages
import Find from "pages/FindSection"
import Journal from "pages/Finance/Journal"
import FinanceRegister from "pages/Finance/Register"
import Dashboard from "pages/Finance/Transaction/Dashboard"
import GeneralEntryEdit from "pages/Finance/Transaction/GeneralEntry/Edit"
import ReceiveMoney from "pages/Finance/Transaction/ReceiveMoney"
import EditReceiveMoney from "pages/Finance/Transaction/ReceiveMoney/Edit"
import SpendMoney from "pages/Finance/Transaction/SpendMoney"
import EditSpendMoney from "pages/Finance/Transaction/SpendMoney/Edit"
import TransferMoney from "pages/Finance/Transaction/TransferMoney"
import TransferMoneyEdit from "pages/Finance/Transaction/TransferMoney/Edit"
import { ReconcileAccount } from "pages/Finance/Transaction/ReconcileAccount"

// Types
import type { RouteType } from "types"

export const financeRoutes: RouteType[] = [
  {
    path: "/finance/register/",
    element: <FinanceRegister />,
    permission: "FR"
  },
  {
    path: "/finance/transaction/reconcile-account",
    element: <ReconcileAccount />,
    permission: "FT04"
  },
  {
    path: "/finance/report/dashboard",
    element: <Dashboard />,
    permission: "FT06"
  },
  {
    path: "/finance/journal",
    element: <Journal />,
    permission: "FT" // BELUM
  },
  {
    path: "/finance/transaction/general-entry/edit/:data_source/:data_source_id",
    element: <GeneralEntryEdit />,
    permission: "AT023"
  },
  {
    path: "/finance/transaction/receive-money",
    element: <ReceiveMoney />,
    permission: "FT02"
  },
  {
    path: "/finance/transaction/receive-money/edit/:data_source/:data_source_id",
    element: <EditReceiveMoney />,
    permission: "FT023"
  },
  {
    path: "/finance/transaction/spend-money",
    element: <SpendMoney />,
    permission: "FT01"
  },
  {
    path: "/finance/transaction/spend-money/edit/:data_source/:data_source_id",
    element: <EditSpendMoney />,
    permission: "FT013"
  },
  {
    path: "/finance/transaction/transfer-money",
    element: <TransferMoney />,
    permission: "FT03"
  },
  {
    path: "/finance/transaction/transfer-money/edit/:data_source/:data_source_id",
    element: <TransferMoneyEdit />,
    permission: "FT033"
  },
  {
    path: "/finance/register",
    element: <FinanceRegister />,
    permission: "FR"
  },
  {
    path: "/finance/find",
    element: <Find />,
    permission: "FT" // BELUM
  },
  {
    path: "/finance/report/transaction-journal",
    element: <div />,
    permission: "FT"
  }, 
  {
    path: "/finance/report/bank-register",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/reconsilition-report",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/cast-flow-analysis",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/account-balance",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/enquery",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/cast-disbursement",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/cast-receipts",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report/by-contact",
    element: <div />,
    permission: "FT"
  },
  {
    path: "/finance/report",
    element: <div />,
    permission: "FT"
  },
]
