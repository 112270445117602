// Components
import { NumberInput } from "components"

// Form
import { Controller, useFormContext } from "react-hook-form"

// Types
import type { SellingList } from "pages/Inventori/Transaction/AddNewItem/types"

export function PriceSection(params: {
  childClass: string
  disabled?: boolean
  fieldName: "distributor_price" | "factory_price" | "online_price" | "special_arrangement_price" | "wholesales_price"
  handleChange: (value: number) => void
  inputClass: string
  retailPrice: string
}): JSX.Element {
  // Vars
  const retailPrice = parseFloat(params.retailPrice ?? "0")

  // Form
  const { control } = useFormContext<SellingList>()

  return (
    <Controller
      control={control}
      name={params.fieldName}
      render={({ field, fieldState }) => (
        <>
          <td className="w-full !max-w-[150px]">
            <NumberInput
              value={(parseFloat(field.value) / retailPrice) * 100}
              onValueChange={value => {
                field.onChange(((value.floatValue ?? 0) / 100) * retailPrice)
              }}
            />
          </td>

          <td className={params.childClass}>
            <NumberInput
              inputClass={params.inputClass}
              value={field.value}
              error={fieldState.error?.message}
              onValueChange={value => {
                field.onChange(value.floatValue)
                params.handleChange(value.floatValue ?? 0)
              }}
              disabled={params.disabled}
            />
          </td>
        </>
      )}
    />
  )
}