// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton, BaseTable, Button, Loading } from "components"
import { DeleteModal } from "pages/Purchase/Transaction/DepositVendor/components"

// Contexts
import { DisableBillContext, PurchaseOrderContext, PurchaseTabContext } from "../../../contexts"

// Form
import { useFormContext } from "react-hook-form"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import moment from "moment"
import { Link, useParams } from "react-router-dom"

// Types
import type { FormType } from "../../../utils"

// Utils
import { convertNumber, useToggle } from "utils"

export function Payment() {
  // Hooks
  const { deposit } = useContext(DisableBillContext)
  const { disabled } = useContext(PurchaseOrderContext)
  const { id } = useParams()
  const { deposit: { data, isLoading, refetch } } = useContext(PurchaseTabContext)

  // Form
  const { getValues } = useFormContext<FormType>()

  // Vars
  const isApproved: boolean = getValues("approval_status") === 4

  return (
    <Fragment>
      <BaseTable>
        <thead>
          <tr>
            <th></th>
            <th>ACCOUNT ID#</th>
            <th>DATE</th>
            <th>STATUS</th>
            <th>MEMO</th>
            <th>DEPOSIT</th>
            <th>USED</th>
            <th>BALANCE</th>
            <th>ACTION</th>
          </tr>
        </thead>

        <tbody>
          {isLoading || !data ? (
            <tr>
              <td colSpan={9}>
                <Loading loading={isLoading} />
              </td>
            </tr>
          ) : data.map((item, key) => {
            // Vars
            const balance: number = item.amount - item.deposit_used

            return (
              <tr key={key}>
                <td className="text-center">
                  {!disabled && (
                    <Link to={`/purchase/transaction/deposit-vendor/edit/${item.deposit_vendor_id}`}>
                      <IoSendSharp />
                    </Link>
                  )}
                </td>

                <td>{item.trx_code}</td>
                <td>{moment(item.transaction_date).format("DD/MM/YYY")}</td>
                <td>{item.approve_status}</td>
                <td>{item.memo}</td>
                <td className="text-right">{convertNumber(item.amount).intoCurrency}</td>
                <td className="text-right">{convertNumber(item.deposit_used).intoCurrency}</td>
                <td className="text-right">{convertNumber(balance).intoCurrency}</td>
                <td className="text-center">
                  {!disabled && item.approve_status !== "Approved" && (
                    <Delete
                      deposit_vendor_id={item.deposit_vendor_id}
                      onSuccess={refetch}
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </BaseTable>

      <section className="flex justify-end">
        {!disabled && !deposit && (
          <Link to={isApproved ? `/purchase/transaction/deposit-vendor/${id}` : "#"}>
            <Button
              className="w-fit"
              color="primary"
              disabled={!isApproved}
            >
              CREATE DEPOSIT
            </Button>
          </Link>
        )}
      </section>
    </Fragment>
  )
}

function Delete(params: {
  deposit_vendor_id: number
  onSuccess: () => void
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete onClick={toggle} />

      {isActive && (
        <DeleteModal
          deposit_vendor_id={params.deposit_vendor_id.toString()}
          toggle={toggle}
          onSuccess={params.onSuccess}
        />
      )}
    </Fragment>
  )
}