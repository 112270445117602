// Third-Party Libraries
import { saveAs } from "file-saver"
import { type WorkSheet, utils, write } from "xlsx"

// Utils
import { arrayDataChecker } from "utils"

// Types
type DataType = { [key: string]: string | number | null }[] | string[]

export function exportFile(data: DataType): void {
  // Vars
  const worksheet = getWorksheet(data)
  const workbook = utils.book_new()

  utils.book_append_sheet(workbook, worksheet, 'Sheet1')

  // Vars
  const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})

  saveAs(blob, "export.xlsx")
}

function getWorksheet(data: DataType): WorkSheet {
  if (arrayDataChecker(data).isObjectArray) {
    return utils.json_to_sheet(data)
  }

  // Vars
  const aoaData = (data as string[]).map(item => [item])
  return utils.aoa_to_sheet([aoaData])
}