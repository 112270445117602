/* eslint-disable no-template-curly-in-string */

// Form
import * as yup from "yup"

export const productValidationSchema = yup.object().shape({
  product_code: yup.string().required(),
  product_barcode: yup.string().required(),
  product_name: yup.string().required(),
  mr_product_group_id: yup.string().label("mr_product_group").required("${label} is not registered on master data"),
  mr_product_style_id: yup.string().label("mr_product_style").required("${label} is not registered on master data"),
  mr_product_colour_id: yup.string().label("mr_product_colour").required("${label} is not registered on master data"),
  mr_product_category_id: yup.string().label("mr_product_category").required("${label} is not registered on master data"),
  mr_product_material_id: yup.string().label("mr_product_material").required("${label} is not registered on master data"),
  main_size_id: yup.string().label("main_size").optional().required("${label} is not registered on master data"),
  mr_category_customer_id: yup.string().label("mr_category_customer").required("${label} is not registered on master data"),
  weight_unit_id: yup.string().label("weight_unit").required("${label} is not registered on master data"),
  size_type: yup.string().required(),
  type: yup.string().required(),
  length: yup
    .string()
    .optional()
    .matches(
      /^\d{1,4}(\.\d{1,2})?$/,
      "Length must have maximum 4 digits with decimal maximum 2 digits"
    ),
  width: yup
    .string()
    .optional()
    .matches(
      /^\d{1,4}(\.\d{1,2})?$/,
      "Width must have maximum 4 digits with decimal maximum 2 digits"
    ),
  height: yup
    .string()
    .optional()
    .matches(
      /^\d{1,4}(\.\d{1,2})?$/,
      "Height must have maximum 4 digits with decimal maximum 2 digits"
    ),
  weight: yup
    .string()
    .required()
    .matches(
      /^\d{1,4}(\.\d{1,2})?$/,
      "Weight must have maximum 4 digits with decimal maximum 2 digits"
    )
})