// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import axios from "axios"

// Types
import type { SuccessFetch } from "types"
import type { PoFetchType } from "pages/Purchase/Transaction/EnterBill/types"
import type { BillDetail } from "../../types"

// Utils
import { useApi } from "utils"
import { default_option } from "pages/Purchase/utils"

export function useDetailReject(
  bill_id: string,
  reject_id: string
) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<BillDetail | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios.all([
      api.get("/probill/detailbill", {
        params: { bill_id }
      }),
      api.get("/proreject/detailreject", {
        params: { reject_id }
      }),
      api.get("/product/"),
      api.get("/vendor/")
    ]).then(axios.spread((res, resreject, resproduct, resvendor) => {
      // Vars
      const data: SuccessFetch<{
        payload: {
          databill: {
            purchase_order_id: number
            vendor_id: number
            vendor_name: string
            location_id: number
            location_name: string
            address: string
            trx_code: string
            delivery_no: number
          }
          detailbill: {
            quantity_bill: number
            quantity_unit: number
            product_id: number
            product_name: string
            unit_measure: string
            price_bill: number
            discount: number
            total: number
            total_qty_reject: number
            tax_id: number
            tax_rate: number
            tax_name: string
          }[]
        }
      }> = res
      const dataproduct: SuccessFetch<{
        payload: {
          product_barcode: string
          product_id: number
        }[]
      }> = resproduct
      const datavendor: SuccessFetch<{
        payload: {
          vendor_id: number
          payment_term_vendor_id: number
        }[]
      }> = resvendor
      const datareject: SuccessFetch<{
        payload: {
          datareject: {
            memo: string
            referensi: string
            transaction_date: string
            trx_code: string
            approve_status: number
            approve_by_name: string | null
            reject_status: number
          }
          detailreject: {
            quantity_reject: number
            quantity_unit: number
            product_id: number
            price_reject: number
            discount: number
            tax_id: number
            tax_rate: number
          }[]
        }
      }> = resreject
      const payload = data.data.payload
      const payloadreject = datareject.data.payload
      const payment_term_vendor_id: string = datavendor.data.payload.find(item => item.vendor_id === payload.databill.vendor_id)?.payment_term_vendor_id.toString() ?? ""

      api.get("/probill/detailbill_bypo", {
        params: { purchase_order_id: payload.databill.purchase_order_id }
      }).then((res: SuccessFetch<{
        payload: PoFetchType
      }>) => {
        // Vars
        const payloadpo = res.data.payload

        setData({
          data: {
            payment_term_vendor_id,
            vendor_id: payload.databill.vendor_id.toString(),
            vendor_name: payload.databill.vendor_name,
            location_id: payload.databill.location_id.toString(),
            location_name: payload.databill.location_name,
            address: payload.databill.address,
            trx_code: payload.databill.trx_code,
            delivery_no: payload.databill.delivery_no.toString(),
            tax_inclusive: payloadpo.datapo.tax_inclusive,
            payment_term: payloadpo.datapo.payment_term.toString(),
            trx_code_reject: payloadreject.datareject.trx_code,
            memo: payloadreject.datareject.memo,
            referensi: payloadreject.datareject.referensi,
            transaction_date: payloadreject.datareject.transaction_date,
            approve_by_name: payloadreject.datareject.approve_by_name ?? "-",
            approve_status: default_option.find(item => item.value === payloadreject.datareject.approve_status.toString())?.label ?? "-",
            reject_status:default_option.find(item => item.value === payloadreject.datareject.reject_status.toString())?.label ?? "-"
          },
          detail: payload.detailbill.map(item => {
            // Vars
            const product_barcode = dataproduct.data.payload.find(i => i.product_id === item.product_id)?.product_barcode ?? "-"
  
            return {
              product_barcode,
              discount: item.discount,
              price_bill: item.price_bill,
              product_id: item.product_id,
              product_name: item.product_name,
              quantity_bill: item.quantity_bill,
              quantity_rate: item.quantity_unit / item.quantity_bill,
              tax_id: item.tax_id,
              tax_name: item.tax_name,
              tax_rate: item.tax_rate,
              total: item.total,
              total_qty_reject: item.total_qty_reject,
              unit_measure: item.unit_measure
            }
          }),
          detail_reject: payloadreject.detailreject.map(item => {
            // Vars
            const detailbill = payload.detailbill.find(i => i.product_id === item.product_id)!
  
            return {
              ...item,
              quantity_bill: detailbill.quantity_bill,
              quantity_rate: item.quantity_unit / item.quantity_reject
            }
          })
        })
      }).catch(() => {
        setData(null)
      }).finally(() => {
        setLoading(false)
      })
    })).catch(() => {
      setData(null)
    })

    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}