/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { today, useApi } from "utils"
import { useDetailInvoice } from "../Invoice/utils/hooks/useDetailInvoice"
import { FormSection } from "./components"
import { paymentInvoiceSchema, PaymentInvoiceType } from "./types"
import { submitMap } from "./utils/functions"
import { Loading } from "components"
import { DetailInvoice } from "../Invoice/types"

export default function PaymentInvoice() {
  // Hooks
  const params = useParams()
  const invoice = useDetailInvoice({
    invoice_id: params?.invoice_id ?? ""
  })

  if (invoice.isLoading || !invoice.data) {
    return <Loading loading={invoice.isLoading} />
  }

  return (
    <DataSection data={invoice.data} />
  )
}

function DataSection(params: {
  data: DetailInvoice
}) {
  // Vars
  const data = params.data
  const balanceInvoice: number = data.datainvoice.balance ?? 0
  const totalDeposit: number = data.datainvoice.saldo_deposit ?? 0

  // Hooks
  const navigate = useNavigate()
  const api = useApi()

  // Form
  const defaultValues: PaymentInvoiceType = {
    invoice_id: data.datainvoice.invoice_id,
    customer_id: data.datainvoice.customer_id ?? NaN,
    location_id: data.datainvoice.location_id ?? NaN,
    coa_id: NaN,
    coa_name: "",
    coa_balance: 0,
    balance_invoice: balanceInvoice,
    balance: 0,
    link: data.datainvoice?.trx_code ?? "",
    trx_code: "",
    transaction_date: today(),
    deposit_amount: totalDeposit < 0 ? 0 : totalDeposit > balanceInvoice ? balanceInvoice : totalDeposit,
    payment_amount: 0,
    memo: "Payment Invoice transaction",
    shipped: data.datainvoice?.address ?? "",
    referensi: ""
  }

  const onSubmit = async (value: PaymentInvoiceType) => {
    await toast
      .promise(api.post("/paymentinvoice/add", submitMap(value)), {
        loading: "Loading...",
        success: (res) => res.data.message,
        error: (err) =>
          err.response.data.detail?.message ?? err.response.data.message
      })
      .then(() => {
        navigate(-1)
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  const methods = useForm<PaymentInvoiceType>({
    defaultValues,
    resolver: yupResolver(paymentInvoiceSchema)
  })

  return (
    <FormProvider {...methods}>
      <FormSection onSubmit={methods.handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
