import { BaseTable, Input, NumberInput, Select } from "components"
import { useProductUnit } from "pages/Inventori/Transaction/AddNewItem/components/FormSection/utils"
import { ComponentList } from "pages/Inventori/Transaction/AddNewItem/types"
import { Controller, useFormContext } from "react-hook-form"
import { convertNumber } from "utils"

interface ManufactureProps {
    action?: "CREATE" | "UPDATE" | "DETAIL"
    selected?: any 
    fieldValue?: any 
    setFieldValue: ( newState: any[] ) => void
    setComponentCost: (newState: string) => void
    refetchCOGSManufacture: () => void
    refetchBuying: () => void
}
export const Manufacture = ({ action, selected, fieldValue, setFieldValue, setComponentCost, refetchCOGSManufacture, refetchBuying }: ManufactureProps) => {
    const { control } = useFormContext<ComponentList>()
    const { dataUnit, isLoadingUnit } = useProductUnit("name")
  
    const total = selected.reduce((acc: any, curr: any) => { return acc + ((curr.qty || 0) * curr.price_unit_convention) }, 0);
    const grandTotal = () => {
      const totalAmount = selected?.reduce((prevValue: any, currentValue: any) => {
        const checkTotal = currentValue.amount ? parseFloat(currentValue.amount) : 0
        return prevValue + checkTotal
      }, 0)
  
      return { 
        amount: total ? total : totalAmount,
        cost_production: total ? (fieldValue?.cost_production/100 || 0) * total : "0",
        wasted: total ? (fieldValue?.wasted/100 || 0) * total : "0",
        conponent_at_cost: total ? total + ((fieldValue?.cost_production/100 || 0) * total) + ((fieldValue?.wasted/100 || 0) * total) : "0",
      }
    }
    const selectedData = dataUnit.find(item => item.value === fieldValue.build_item?.toString())
    if(grandTotal()?.conponent_at_cost) { setComponentCost(grandTotal()?.conponent_at_cost) }
  
    return (
      <div className="w-full">
        <label className="label">
          <span className="label-text font-bold">COMPONENT AT COST</span>
        </label>
        <BaseTable className="border-collapse">
          <tbody className="text-[15px]">
            <tr>
              <td colSpan={2} className="text-[14px]"><b>SUMMARY</b></td>
              <td className="text-right min-w-[100px]">
                {convertNumber(grandTotal()?.amount)?.intoCurrency}
              </td>
            </tr>
            <tr>
              <td>Cost Production (%)</td>
              <td className={action === "DETAIL" ? "text-center" : ""}>
                {action !== "DETAIL" 
                  ? <NumberInput
                      inputClass="w-[120px] my-[-10px] mx-[-13px]"
                      value={fieldValue?.cost_production}
                      onValueChange={e => {
                        setFieldValue({ ...fieldValue, cost_production: e.value })
                        action === "UPDATE" && refetchCOGSManufacture()
                      }}
                    />
                  : fieldValue?.cost_production ?? "0"
                }
              </td>
              <td className="text-right">
                {convertNumber(Number(grandTotal()?.cost_production))?.intoCurrency}
              </td>
            </tr>
            <tr>
              <td>Wasted (%)</td>
              <td className={action === "DETAIL" ? "text-center" : ""}>
                {action !== "DETAIL" 
                  ? <NumberInput
                      inputClass="w-[120px] my-[-10px] mx-[-13px]"
                      value={fieldValue?.wasted}
                      onValueChange={e => {
                        setFieldValue({ ...fieldValue, wasted: e.value })
                        action === "UPDATE" && refetchCOGSManufacture()
                      }}
                    />
                  : fieldValue?.wasted ?? "0"
                }
              </td>
              <td className="text-right">
                {convertNumber(Number(grandTotal()?.wasted))?.intoCurrency}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="text-[14px]"><b>COMPONENT AT COST</b></td>
              <td className="text-right">
                {convertNumber(grandTotal()?.conponent_at_cost)?.intoCurrency}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div className="flex items-baseline justify-between">
                  <div className="w-[120px] mr-5">Quantity Build</div>
                  {action !== "DETAIL" 
                    ? <Input 
                        type="number"
                        inputClass="w-[200px] my-[-10px] mx-[-13px]" 
                        value={fieldValue?.quantity} 
                        onChange={(e) => setFieldValue({ ...fieldValue, quantity: e.target.value })}
                        disabled
                      />
                    : fieldValue?.quantity ?? "-"
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <div className="flex items-baseline justify-between">
                  <div className="w-[120px] mr-5">Unit Build</div>
                  <div>
                    {action !== "DETAIL" 
                      ? <Controller
                          control={control}
                          name="unit_build_id"
                          render={({ field, fieldState }) => {
                            return(
                            <Select
                              controlClass="w-[200px] my-[-10px] mx-[-13px]"
                              isLoading={isLoadingUnit}
                              options={dataUnit}
                              value={dataUnit.find(item => item.value === fieldValue.build_item?.toString())}
                              error={fieldState.error?.message}
                              onChange={e => {
                                field.onChange(e?.value)
                                setFieldValue({ ...fieldValue, build_item: e?.value })
                                action === "UPDATE" && refetchBuying()
                                action === "CREATE" && setComponentCost(grandTotal()?.conponent_at_cost?.toLocaleString("id-ID", { maximumFractionDigits: 0 }))
                              }}
                            />
                          )}}
                        />
                      : selectedData?.label ?? "-"
                    }
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </BaseTable>
      </div>
    )
}