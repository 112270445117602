// React
import { useEffect, useState } from "react"

export function useDummyData(): {
  isLoading: boolean
  refetch: () => void
} {
  // Hooks
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [])

  // Functions
  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  const refetch = async () => {
    setLoading(true)

    await delay(2000)

    setLoading(false)
  }

  return { isLoading, refetch }
}