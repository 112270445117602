// React
import { Fragment, createContext, useContext } from "react"

// Components
import { ActionButton } from "components/actions"
import { CreateModal } from "pages/Accounts/Register/components"
import { ChildItem } from "./ChildItem"

// Contexts
import { CoaContext, RefetchContext } from ".."

// Icons
import { TbChevronRight, TbCircleCheck } from "react-icons/tb"

// Types
import type { CoaGroup } from "../../../../types"

// Utils
import { convertNumber, useToggle } from "../../../../utils"
import { FormType } from "pages/Accounts/Register/types"
import { SuccessContext } from "components/widgets/AccountModal"

const DataContext = createContext<CoaGroup>({
  account_type: "",
  account_type_id: "",
  account_type_name: "",
  budget_coa: false,
  cheque_coa: false,
  childcount: 0,
  coa_code: "",
  coa_id: 0,
  coa_level: 0,
  coa_name: "",
  group_coa_code: "",
  group_coa_id: "",
  header_level: 1,
  location_id: 0,
  note: "",
  opening_balance: 0,
  parent_id: 0,
  report_type: "",
})

export function Item(props: {
  index: number
  item: CoaGroup
  level: number
  parent_id?: number
  cheque_coa_condition?: boolean
}) {
  // Hooks
  const { selected, setSelected } = useContext(CoaContext)
  const { isActive, toggle } = useToggle(props.item.header_level !== 3)
  const isChequeCoa = props.item.header_level === 3 && props.item.cheque_coa
  const isNotDefineChequeCondition = props.cheque_coa_condition === undefined

  return (
    <Fragment>
      <tr
        style={{
          display:
            isChequeCoa ||
            isNotDefineChequeCondition ||
            props.item.header_level !== 3
              ? "table-row"
              : "none"
        }}
        className={`${selected === props.item && "!bg-blue-400/50"} ${
          props.item.header_level === 3
            ? "font-normal cursor-pointer"
            : "font-bold"
        }`}
        onClick={() => {
          if (props.item.header_level === 3) {
            setSelected(props.item)
          }
        }}>
        <td>
          {props.item.header_level !== 3 && (
            <button
              color="primary"
              className="rounded-full"
              onClick={toggle}
              title="Collapse">
              <TbChevronRight
                className={`transition ${isActive && "rotate-90"}`}
              />
            </button>
          )}
        </td>
        <td style={{ paddingLeft: `${16 * (props.item.coa_level + 1)}px` }}>
          <span className="whitespace-nowrap">{props.item.coa_code}</span>{" "}
          {props.item.coa_name}
        </td>
        <td>
          {props.item.budget_coa && <TbCircleCheck className="mx-auto" />}
        </td>
        <td>{props.item.account_type_name}</td>
        <td>{props.item.note}</td>
        <td>{convertNumber(props.item?.opening_balance ?? 0).intoCurrency}</td>
        <td>
          {props.item.header_level !== 3 && (
            <DataContext.Provider value={props.item}>
              <Add />
            </DataContext.Provider>
          )}
        </td>
      </tr>

      {isActive && (
        <ChildItem
          cheque_coa_condition={props.cheque_coa_condition}
          parent_id={props.item.coa_id}
          level={props.level}
        />
      )}
    </Fragment>
  )
}

function Add(): JSX.Element {
  // Hooks
  const item = useContext(DataContext)
  const refetch = useContext(RefetchContext)
  const onSuccess = useContext(SuccessContext)
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Add onClick={toggle} />
      
      {isActive && (
        <CreateModal
          account_type_id={item.account_type_id}
          group_coa_code={item.group_coa_code}
          group_coa_id={item.group_coa_id}
          parent_id={item.coa_id}
          parent_level={item.coa_level + 1}
          parent_name={`${item.coa_code} ${item.coa_name}`}
          report_type={item.report_type}
          toggle={toggle}
          onSuccess={(value: FormType) => {
            if (value.header_level !== "3") {
              return refetch()
            }

            onSuccess()
          }}
        />
      )}
    </Fragment>
  )
}