// React
import { Fragment, useContext } from "react"

// Components
import { Loading } from "components"

// Contexts
import { DataJournalContext } from "contexts"

// Form
import { useFormContext, useWatch } from "react-hook-form"

// Third-Party Libraries
import moment from "moment"

// Types
import type { FormType } from "../../../utils"

// Utils
import { convertNumber } from "utils"
import { useCoaBsi } from "../utils"

export function Product(params: {
  tracing: string
  transaction_date: string
  trx_code: string
}): JSX.Element {
  // Hooks
  const { tax_code } = useContext(DataJournalContext)

  // Form
  const { control } = useFormContext<FormType>()
  const probildetail = useWatch({
    control,
    name: "probildetail"
  })

  return (
    <Fragment>
      {probildetail.map((item, key) => {
        // Vars
        const selectedTaxCode = tax_code.data.find(i => i.value === item.tax_id)
        const rate: number = typeof selectedTaxCode?.rate === "number" ? selectedTaxCode?.rate : 1
        const rateCurrency: string = convertNumber(item.total * rate / 100).intoCurrency
        const total: string = convertNumber(item.total).intoCurrency

        return (
          <Fragment key={key}>
            {Boolean(item.total) && (
              <>
                <tr>
                  <td>{moment(params.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>{params.trx_code}</td>
                  <ProductInvetory product_id={item.product_id} />
                  <td>{total}</td>
                  <td></td>
                </tr>
            
                <tr>
                  <td>{moment(params.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>{params.trx_code}</td>
                  <td>{params.tracing}</td>
                  <td></td>
                  <td>{total}</td>
                </tr>
              </>
            )}
        
            {item.tax_id && Boolean(rate) && (
              <>
                <tr>
                  <td>{moment(params.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>{params.trx_code}</td>
                  <td>{selectedTaxCode?.tax_coa_paid}</td>
                  <td>{rateCurrency}</td>
                  <td></td>
                </tr>
        
                <tr>
                  <td>{moment(params.transaction_date).format("DD/MM/YYYY")}</td>
                  <td>{params.trx_code}</td>
                  <td>{params.tracing}</td>
                  <td></td>
                  <td>{rateCurrency}</td>
                </tr>
              </>
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

function ProductInvetory(params: {
  product_id: string
}) {
  // Hooks
  const { data, isLoading } = useCoaBsi(params.product_id)

  if (isLoading) {
    return (
      <td>
        <Loading loading />
      </td>
    )
  }

  return <td>{data}</td>
}