// React
import { useContext } from 'react'

// Components
import { Button } from 'components'
import { SortableItem } from './SortableItem'

// Contexts
import { FormatContext } from '../contexts'

// DnD kit
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers"

// Utils
import { useHeaders } from '../utils'

export function SelectHeaders(): JSX.Element {
  // Hooks
  const { headers, assignHeaders } = useContext(FormatContext)
  const { items, itemsNew, handleDragEnd, swapOrder } = useHeaders(headers)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  return (
    <section className='flex flex-col'>
      <section className='grow grid grid-cols-2 gap-2'>
        <section>
          <div className='text-xl font-bold text-center'>Available Headers</div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={event => handleDragEnd(event, "old")}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext 
              items={items.map(item => item.value)}
              strategy={verticalListSortingStrategy}
            >
              {items.map((item, key) => (
                <SortableItem
                  key={item.value}
                  name={item.value}
                  label={item.label}
                  onSwap={() => swapOrder(key, "old")}
                />
              ))}
            </SortableContext>
          </DndContext>
        </section>

        <section>
          <div className='text-xl font-bold text-center'>Selected Headers</div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={event => handleDragEnd(event, "new")}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext 
              items={itemsNew.map(item => item.value)}
              strategy={verticalListSortingStrategy}
            >
              {itemsNew.map((item, key) => {
                // Vars
                const required = item.required

                return (
                  <SortableItem
                    key={item.value}
                    disabled={required}
                    rightSide
                    label={item.label}
                    name={item.value}
                    onSwap={() => swapOrder(key, "new")}
                  />
                )
              })}
            </SortableContext>
          </DndContext>
        </section>
      </section>

      <section className='z-[2] bg-base-100 py-3 sticky bottom-0'>
        <section className='text-right'>
          <Button
            color='primary'
            onClick={() => assignHeaders(itemsNew)}
          >
            Next
          </Button>
        </section>
      </section>
    </section>
  )
}