// React
import { PropsWithChildren, createContext, useState } from "react"

export const AuthContext = createContext<{
  permission: string[]
  resetData: () => void
  setAuthData: (
    token: string,
    permission: string[],
    typeIndustryCode?: string
  ) => void
  token: string
  typeIndustryCode: string
}>({
  permission: [],
  token: "",
  typeIndustryCode: "",
  resetData() {},
  setAuthData(token, permission, typeIndustryCode) {}
})

export function AuthProvider(params: PropsWithChildren<{}>): JSX.Element {
  // Vars
  const [token, setToken] = useState<string>(
    localStorage.getItem("token") ?? ""
  )
  const [permission, setPermission] = useState<string[]>(
    localStorage.getItem("access")
      ? JSON.parse(localStorage.getItem("access")!)
      : []
  )
  const [typeIndustryCode, setTypeIndustryCode] = useState<string>("")

  const setAuthData = (
    token: string,
    permission: string[],
    typeIndustryCode?: string
  ): void => {
    setToken(token)
    setPermission(permission)
    setTypeIndustryCode(typeIndustryCode ?? "")
  }

  const resetData = (): void => {
    setToken("")
    setPermission([])
  }

  return (
    <AuthContext.Provider
      value={{
        permission,
        token,
        typeIndustryCode,
        resetData,
        setAuthData
      }}>
      {params.children}
    </AuthContext.Provider>
  )
}
