// Components
import { CommandButton } from "./CommandButton"

// Third-Party Libraries
import { useNavigate } from "react-router-dom"

export function CancelButton(): JSX.Element {
  // Hooks
  const navigate = useNavigate()

  return (
    <CommandButton
      type="button"
      actiontype="cancel"
      onClick={() => navigate(-1)}
    />
  )
}