import { useApi } from "utils";
import { ApproveType, FilterQuotation } from "../../types";
import { useEffect, useState } from "react";
import { SuccessFetch } from "types";
import { DataTypePaging } from "types";

export function useList({ page, approval_status, limit }: FilterQuotation) {

    const defaultValue = {
        payload: [],
        pagination_data: {
            page: 0,
            pages: 0,
            size: 0,
            total: 0
        }
    }

    const api = useApi();
    const [data, setData] = useState<DataTypePaging<ApproveType>>(defaultValue);
    const [isLoading, setLoading] = useState(true)

    const params: { [key: string]: number | string | undefined } = {}
    if (page) params.page = page;
    if (approval_status) params.approval_status = approval_status;
    if (limit) params.limit = limit;

    const fetchUrl = () => {
        return api.get("/customer-quotation/approve?", { params })
    }

    const fetchData = () => {
        setLoading(true);

        fetchUrl().then((res: SuccessFetch<DataTypePaging<ApproveType>>) => {
            setData(res.data);
        }).catch(() => {
            setData(defaultValue);
        }).finally(() => {
            setLoading(false);
        });
    }

    const refetch = () => fetchData();

    useEffect(() => {
        fetchData();

        return () => {
            setData(defaultValue);
            setLoading(true);
        }
        // eslint-disable-next-line
    }, [page, approval_status, limit]);

    return { data, isLoading, refetch }
}