// React
import { Fragment, useContext } from "react"

// Components
import { ErrorText, NumberInput } from "components"
import { ItemView } from "../../ItemView"

// Contexts
import { FilterContext } from "../../../contexts"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

// Types
import { FormType, ItemType } from "../../../utils"

// Utils
import { useHistoryCoa } from "../../../utils"
import { getHistoryBalance, getOpeningBalance } from "../utils"

export function ListItem(params: {
  index: number
  item: ItemType
  level: number
}) {
  // Hooks
  const { account_number, sub_header, zero_value } = useContext(FilterContext)

  // Form
  const { control, formState, getValues, setValue } = useFormContext<FormType>()

  const calculateParent = (list: ItemType[], parent_id: number) => {
    // Vars
    // eslint-disable-next-line
    const selected_item = list.find((item) => item.coa_id === parent_id)!
    // eslint-disable-next-line
    const selected_item_index = list.findIndex(
      (item) => item.coa_id === parent_id
    )!

    setValue(
      `list.${selected_item_index}`,
      {
        ...selected_item,
        opening_balance: list
          .filter((item) => {
            return item.parent_id === selected_item.coa_id
          })
          .reduce((acc, { opening_balance }) => {
            return acc + opening_balance
          }, 0)
      },
      { shouldValidate: true }
    )

    return selected_item.parent_id
  }

  return (
    <Fragment>
      {params.item.linked_code === "LA03" ? (
        <HistoryBalance />
      ) : Boolean(
          params.item.header_level === 2 ? sub_header.isActive : true
        ) &&
        Boolean(
          params.item.opening_balance === 0 ? zero_value.isActive : true
        ) ? (
        <Controller
          control={control}
          name={`list.${params.index}`}
          render={({ field }) => {
            // Vars
            const is_header = field.value.header_level !== 3

            return (
              <Fragment>
                <tr key={params.index}>
                  <td>
                    <div
                      className={`flex ${field.value.header_level === 3 && "font-normal"}`}
                      style={{ paddingLeft: 16 + 10 * params.level }}
                    >
                      {account_number.isActive && (
                        <Fragment>
                          <div>{field.value.coacode}</div>
                          <div className="divider divider-horizontal m-0" />
                        </Fragment>
                      )}

                      <div>{field.value.coa_name}</div>
                    </div>
                  </td>
                  <td className="p-0 w-[100px]">
                    <NumberInput
                      decimalScale={0}
                      inputClass={`text-right ${is_header && "font-extrabold"}`}
                      disabled={is_header || params.item.linked_code === "LA01"}
                      value={field.value.opening_balance}
                      onValueChange={(value) => {
                        field.onChange({
                          ...field.value,
                          opening_balance: value.floatValue
                        })

                        // Vars
                        const list = getValues("list")

                        //  ** START: Recalculate all of it's parent ** //
                        // Vars
                        let parent_id = field.value.parent_id

                        while (parent_id !== -1) {
                          parent_id = calculateParent(list, parent_id)
                        }
                        //  ** END: Recalculate all of it's parent ** //

                        //  ** START: Recalculate HISTORY BALANCE and all of it's parent ** //
                        // Vars
                        const history_balance = getHistoryBalance(list)
                        const assets = getOpeningBalance(list, "01")
                        const liability = getOpeningBalance(list, "02")
                        const coa_equity_detail = list
                          .filter((item) => {
                            return (
                              item.group_coa_code === "03" &&
                              item.header_level === 3 &&
                              item.linked_code !== "LA03"
                            )
                          })
                          .reduce((acc, { opening_balance }) => {
                            return acc + opening_balance
                          }, 0)

                        if (history_balance.item) {
                          setValue(`list.${history_balance.index}`, {
                            ...history_balance.item,
                            opening_balance:
                              assets - liability - coa_equity_detail
                          })

                          // Vars
                          let history_balance_parent_id =
                            history_balance.item.parent_id

                          while (history_balance_parent_id !== -1) {
                            history_balance_parent_id = calculateParent(
                              list,
                              history_balance_parent_id
                            )
                          }
                        }
                        //  ** END: Recalculate HISTORY BALANCE and all of it's parent ** //
                      }}
                    />

                    {!is_header && (
                      <ErrorMessage
                        errors={formState.errors}
                        name={`list.${params.index}.opening_balance`}
                        render={({ message }) => <ErrorText text={message} />}
                      />
                    )}
                  </td>
                </tr>
              </Fragment>
            )
          }}
        />
      ) : (
        <tr />
      )}

      {getValues("list").map((item, key) => {
        if (item.parent_id === params.item.coa_id) {
          return (
            <ListItem
              key={key}
              index={key}
              item={item}
              level={params.level + 1}
            />
          )
        }

        return <Fragment key={key} />
      })}
    </Fragment>
  )
}

function HistoryBalance() {
  // Hooks
  const coa = useHistoryCoa()
  const { zero_value } = useContext(FilterContext)

  // Form
  const { control } = useFormContext<FormType>()
  const list = useWatch({
    control,
    name: "list"
  })

  // Vars
  const history_balance = list.find((item) => item.linked_code === "LA03")
  const amount = list.find((item) => item.linked_code === "LA03")?.opening_balance ?? 0

  if (Boolean(amount === 0 ? zero_value.isActive : true)) {
    return (
      <ItemView
        isInputOnly
        coa_code={coa.coa_code}
        label={coa.coa_name}
        labelClassName="font-normal"
        level={3}
        amount={amount}
        code={history_balance?.coacode}
      />
    )
  }

  return <tr />
}
