import { Button, Card, DateInput, Input, Label, NumberInput, Select } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { FormType, PicturesType } from "../../types";
import moment from "moment";
import { Link } from "react-router-dom";
import { PicturesSection } from "../PicturesSection";
import { useDropdownLocation, useProduct } from "../../utils";
import { useProductDropdown } from "../../utils/hooks/useProductDropdown";
import { Fragment, useEffect, useState } from "react";
import { AddStock } from "../AddStock";


export function FormSection(props: {
    isEdit?: boolean
}) {
    // hook-form
    const { control, watch, setValue } = useFormContext<FormType>()
    const productId = watch('product_id')

    // hooks
    const { dropdownLocation } = useDropdownLocation()
    const { dropdownProduct } = useProductDropdown()
    const { dataProduct, refetch } = useProduct()

    // state
    const [pictureValue, setPictureValue] = useState<PicturesType[]>([])
    const [description, setDescription] = useState<string>('')

    // useEffetc
    useEffect(() => {
        const SelectedProduct = dataProduct.find(value => value.product_id.toString() === productId.toString())
        if (SelectedProduct) {
            setValue('mr_unit_id', SelectedProduct?.mr_unit_name ?? '')
            setValue('av_price_unit', SelectedProduct?.price ?? '0')
            setPictureValue(SelectedProduct?.picture)
            setDescription(SelectedProduct?.product_name)
        }
        /* eslint-disable */
    }, [productId, dataProduct])

    return (
        <Fragment>
            <div>
                <div className="w-full mb-2">
                    <Card>
                        <Card.Body>
                            <div className="flex justify-between items-center">
                                <div className="lg:flex">
                                    <Label text="DATE OPENING STOCK" className="lg:w-[180px]" />
                                    <div className="lg:w-[200px] w-[300px]">
                                        <Controller
                                            control={control}
                                            name="transaction_date"
                                            render={({ field, fieldState }) => (
                                                <DateInput
                                                    placeholderText="Select Date"
                                                    error={fieldState.error?.message}
                                                    ref={field.ref}
                                                    selected={moment(field.value).toDate()}
                                                    onChange={value => field.onChange(moment(value).format("YYYY-MM-DD"))}
                                                    disabled={!props.isEdit}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                {props.isEdit && (
                                    <Link to='/setup/inventory/opening-stock'>
                                        <Button color="primary">BACK</Button>
                                    </Link>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div>
                    <Card>
                        <Card.Body>
                            <div className="lg:mb-3 lg:grid lg:grid-cols-3">
                                <div className="lg:flex items-center gap-2">
                                    <Label text="LOCATION" />
                                    <Controller
                                        control={control}
                                        name="location_id"
                                        render={({ field, fieldState }) => (
                                            <Select
                                                className="lg:w-[205px] w-full"
                                                options={dropdownLocation}
                                                placeholder="Select Location"
                                                ref={field.ref}
                                                isDisabled={!props.isEdit}
                                                error={fieldState?.error?.message}
                                                value={dropdownLocation.find(item => item.value === field.value.toString()) ?? null}
                                                onChange={item => {
                                                    field.onChange(item?.value)
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="lg:flex items-center gap-5">
                                    <Label text="ITEM" />
                                    <Controller
                                        control={control}
                                        name="product_id"
                                        render={({ field, fieldState }) => (
                                            <Select
                                                className="lg:w-[205px]"
                                                options={dropdownProduct}
                                                placeholder="Select Item"
                                                ref={field.ref}
                                                error={fieldState?.error?.message}
                                                value={dropdownProduct.find(item => item.value === field.value.toString()) ?? null}
                                                isDisabled={!props.isEdit}
                                                onChange={item => {
                                                    field.onChange(item?.value)
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="lg:flex items-center gap-2">
                                    <Label text="DESCRIPTION" />
                                    <Input
                                        className="lg:w-[205px]"
                                        disabled
                                        value={description}
                                    />
                                </div>
                            </div>
                            <div className="lg:grid lg:grid-cols-3">
                                <div className="lg:flex items-center mb-3 gap-4">
                                    <Label text="AV PRICE" />
                                    <Controller
                                        control={control}
                                        name="av_price_unit"
                                        render={({ field, fieldState }) => (
                                            <NumberInput
                                                inputClass="lg:w-[205px]"
                                                decimalScale={2}
                                                error={fieldState.error?.message}
                                                ref={field.ref}
                                                value={field.value}
                                                decimalSeparator='.'
                                                thousandSeparator=","
                                                placeholder="Enter Price"
                                                onValueChange={value => field.onChange(value.floatValue)}
                                                disabled={!props.isEdit}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="lg:flex items-center mb-3 gap-2">
                                    <Label text="STOCK" />
                                    <Controller
                                        control={control}
                                        name="soh"
                                        render={({ field, fieldState }) => (
                                            <NumberInput
                                                inputClass="lg:w-[205px]"
                                                decimalScale={2}
                                                error={fieldState.error?.message}
                                                ref={field.ref}
                                                value={field.value}
                                                placeholder="Enter Stock"
                                                onValueChange={value => field.onChange(value.floatValue)}
                                                disabled={!props.isEdit}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="lg:flex items-center gap-2">
                                    <Label text="UNIT" className="mr-[55px]" />
                                    <Controller
                                        control={control}
                                        name="mr_unit_id"
                                        render={({ field, fieldState }) => (
                                            <Input
                                                className="lg:w-[205px] mb-2"
                                                ref={field.ref}
                                                disabled
                                                placeholder="Enter Unit"
                                                error={fieldState?.error?.message}
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    {props.isEdit && (
                                         <div className="mr-2 flex justify-end">
                                         <AddStock id={productId} refetch={refetch} />
                                     </div>
                                    )}
                                   
                                </div>
                            </div>
                            <div >
                                <Label text="PICTURES" />
                                <PicturesSection items={5} dataPicture={pictureValue} className="h-[150px] w-full" />
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}