/* eslint-disable react-hooks/exhaustive-deps */
import { ActionButton, PermissionLink } from 'components';
import moment from 'moment';
import { ModalDelete } from 'pages/Sales/Transaction/CustomerOrder/components';
import { Fragment } from 'react';
import { IoSendSharp } from 'react-icons/io5';
import { useToggle } from 'utils';
import { RegisterDateType } from '../types';
import { APPROVAL, DELIVERED } from '../utils/vars';
import { permissionProps } from 'types';

interface ItemProps {
  index: number;
  item: RegisterDateType;
  onDeleteSucces?: () => void;
  permission: permissionProps
}

export function Item({ index, item, onDeleteSucces, permission }: ItemProps) {
  const modalDelete = useToggle(false);

  return (
    <Fragment>
      <tr key={item.sales_order_id}>
        <td>{index + 1}</td>
        <td className="font-normal">
          <span className="ps-4"></span>
          {item.trx_code}
        </td>
        <td>{moment(item.transaction_date).format('DD/MM/YYYY')}</td>
        <td>{item.customer_name}</td>
        <td>{APPROVAL[item.approval_status]}</td>
        <td>{DELIVERED[item.delivery_status]}</td>
        <td className="text-right">{item.qty_order.toLocaleString('id-ID')}</td>
        <td className="text-right">
          {item.qty_delivered.toLocaleString('id-ID')}
        </td>
        <td className="text-right">
          {item.qty_back_order.toLocaleString('id-ID')}
        </td>
        <td className="text-right">{item.total.toLocaleString()}</td>
        <td className="text-right">{item.total_payment.toLocaleString()}</td>
        <td className="text-right">{item.balance_owing.toLocaleString()}</td>
        <td className="cursor-pointer">
          <div className="flex items-center gap-2">
            <PermissionLink
              permission="SR013"
              className='mt-2'
              to={`/sales/transaction/customer-order/edit/${item.sales_order_id}`}
            >
              <button color="primary" className="rounded-full" title="Collapse">
                <IoSendSharp size={15} />
              </button>
            </PermissionLink>
            {item.approval_status === APPROVAL.PENDING ? (
              <ActionButton.Delete permission="SR014" color='red' onClick={modalDelete.toggle} />
            ) : null}
          </div>
        </td>
      </tr>

      <ModalDelete
        data={item}
        modalState={modalDelete}
        onSuccess={onDeleteSucces}
      />
    </Fragment>
  );
}
