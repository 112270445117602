// React
import { useEffect, useState } from "react"

// Types
import type { SuccessFetch } from "types"
import type { DataType, DetailType, FetchTypeData } from "../../types"

// Utils
import { useApi } from "utils"

// Types
interface FetchType {
  datareject: FetchTypeData
  detailreject: DetailType[]
}

export function useDetail(reject_id: string): {
  data: DataType | null
  isLoading: boolean
} {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get(
      "/proreject/detailreject",
      { params: { reject_id } }
    ).then((res: SuccessFetch<{
      payload: FetchType
    }>) => {
      // Vars
      const payload = res.data.payload

      setData({
        ...payload,
        datareject: {
          ...payload.datareject,
          approve_by: payload.datareject.approve_by ?? "-"
        }
      })
    }).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}