export const getSourceAndLink = (dataSource: number, val: any) => {
  const map: { [key: number]: { source: string; link: string } } = {
    1: { source: "Spend Money", link: `/finance/transaction/spend-money/edit/${val?.data_source}/${val?.data_source_id}` },
    2: { source: "Receive Money", link: `/finance/transaction/receive-money/edit/${val?.data_source}/${val?.data_source_id}` },
    3: { source: "General Entry", link: `/finance/transaction/general-entry/edit/${val?.data_source}/${val?.data_source_id}` },
    4: { source: "Transfer Money", link: `/finance/transaction/transfer-money/edit/${val?.data_source}/${val?.data_source_id}` },
    5: { source: "Purchase Order", link: `/purchase/transaction/purchase-order/${val.purchase_order_id}` },
    6: { source: "Create Bill", link: `/purchase/transaction/enter-bills/${val?.purchase_order_id}/edit/${val?.bill_id}` },
    7: { source: "Payment Deposit Vendor", link: `/sales/transaction/payment-deposit/edit/${val?.data_source_id}` },
    8: { source: "Opening Balance Account", link: "/sales/transaction/opening-balance" },
    9: { source: "Payment Bill", link: `/purchase/transaction/payment-bill/${val.data_source_id}` },
    10: { source: "Sales Order", link: "/sales/transaction/sales-order-register" },
    11: { source: "Create Invoice", link: `/sales/transaction/invoice/edit/${val?.data_source_id}` },
    12: { source: "Payment Deposit Customer", link: `/sales/transaction/payment-deposit/edit/${val?.data_source_id}` },
    13: { source: "Payment Invoice", link: `/sales/transaction/payment-invoice/${val?.data_source_id}` },
    14: { source: "Opening Stock", link: `/setup/inventory/opening-stock/update/${val?.data_source_id}` },
    15: { source: "Create Bill Detail", link: `/purchase/transaction/enter-bills/${val?.purchase_order_id}/edit/${val.bill_id}` },
    16: { source: "Create Invoice Detail", link: `/sales/transaction/invoice/edit/${val?.data_source_id}` },
    17: { source: "Average Price Real", link: "/average-price-real" },
    18: { source: "Reject Item", link: `/purchase/transaction/reject-item/edit/${val.bill_id}/${val.reject_id}` },
    19: { source: "Reject Item Detail", link: `/purchase/transaction/reject-item/edit/${val.bill_id}/${val.reject_id}` },
    20: { source: "Return Item", link: `/sales/transaction/return-items/edit/${val?.data_source_id}` },
    21: { source: "Return Item Detail", link: `/sales/transaction/return-items/edit/${val?.data_source_id}` }
  };
  
  return map[dataSource] || { source: "-", link: "#" }; 
};
