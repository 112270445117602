// Components
import { BaseTable, Loading, PermissionLink } from "components"
import { DeleteItem } from "../../DeleteItem"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Utils
import { convertNumber } from "utils"
import { convertDate } from "pages/Inventori/Transaction/TransactionJournal/utils"

// Types
import type { TableSectionProps } from "pages/Purchase/Register/types"

export const TableSection = ({ data, isLoading, refetch }: TableSectionProps) => {
  const approvalChecked = (data: number) => {
    if(data === 1) { return "PENDING" }
    if(data === 2) { return "REVISI" }
    if(data === 3) { return "REJECT" }
    if(data === 4) { return "APPROVE" }
  }

  return (
    <BaseTable className="border-transparent">
      <thead>
        <tr className="uppercase">
            <th>No</th>
            <th>Bill Id#</th>
            <th>Ref Po Id#</th>
            <th>Date</th>
            <th>Vendor</th>
            <th>Status<br />Approval</th>
            <th>Qty<br />Delivered</th>
            <th>Location</th>
            <th>Subtotal</th>
            <th>Payment</th>
            <th>Balance<br />Owing</th>
            <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {!data.length || isLoading 
            ? <tr><td colSpan={13} className="text-center"><Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} /></td></tr>
            : data.map((val, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{val?.trx_code ?? "-"}</td>
                <td>{val?.trx_code_po ?? "-"}</td>
                <td>{val?.transaction_date ? convertDate(val?.transaction_date) : "-"}</td>
                <td>{val?.vendor_name ?? "-"}</td>
                <td>{val?.approve_status ? approvalChecked(val?.approve_status) : "-"}</td>
                <td className="text-center">{val?.qty_delivered ? convertNumber(val?.qty_delivered)?.intoNormalAmount : "-"}</td>
                <td>{val?.location_name ?? "-"}</td>
                <td className="text-right">{val?.sub_total ? convertNumber(val?.sub_total)?.intoCurrency : "0"}</td>
                <td className="text-right">{val?.payment ? convertNumber(val?.payment)?.intoCurrency : "0"}</td>
                <td className="text-right">{val?.balance_owing ? convertNumber(val?.balance_owing)?.intoCurrency : "0"}</td>
                <td>
                  <section className="flex items-center gap-2">
                    <PermissionLink permission="PR053" to={`/purchase/transaction/enter-bills/${val.purchase_order_id}/edit/${val.bill_id}`} className="mt-1">
                      <IoSendSharp />
                    </PermissionLink>
                    {val?.approve_status === 1 && (
                      <DeleteItem
                        onSuccess={() => {
                          if (refetch) {
                            refetch()
                          }
                        }}
                        item={{
                          purchase_order_id: val.purchase_order_id.toString(),
                          transaction_date: val.transaction_date,
                          trx_code: val.trx_code,
                          vendor_name: val.vendor_name
                        }}
                      />
                    )}
                  </section>
                </td>
              </tr>
            ))
        }
      </tbody>
    </BaseTable>
  )
}