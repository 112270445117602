// React
import { useContext, useEffect, useState } from "react"

// Components
import { Disclosure } from "@headlessui/react"
import { Button } from "components"

// Contexts
import { DataContext } from "../contexts"

// Form
import { ValidationError } from "yup"

// Icons
import { MdOutlineDownloading } from "react-icons/md"
import { TbCheck, TbX } from "react-icons/tb"

// Types
import type { ErrorFetch, SuccessFetch } from "types"
import type { DataType } from "../types"

// Utils
import { sweetAlert, useApi } from "utils"
import { exportFile, productValidationSchema, useRowStatus } from "../utils"

export function ImportData(): JSX.Element {
  // Hooks
  const { data } = useContext(DataContext)
  const { error, index, success, toggle } = useRowStatus()

  useEffect(() => {
    if (index >= data.length) {
      // Vars
      const status = {
        success: success === data.length,
        error: error.length === data.length
      }

      // Functions
      const getMessage = (): string => {
        if (success === data.length) {
          return "All data has been imported!"
        } else if (error.length === data.length) {
          return "All data can't be imported! \n Please check the error details on the import data"
        }

        return "All data can't be imported! \n You can check the error details on the import data and download the error data to be fixed"
      }

      sweetAlert(
        getMessage(),
        { icon: status.success ? "success" : status.error ? "error" : "info" }
      )
    }

    // eslint-disable-next-line
  }, [index])

  return (
    <div className="flex flex-col gap-3">
      <section className="flex justify-end gap-3">
        {index >= data.length && error.length && (
          <Button
            color="primary"
            onClick={() => {
              // Vars
              const failedData: { [key: string]: string | number | null }[] = data.filter((_, key) => {
                return error.map(item => item.index).includes(key)
              }).map(item => {
                return {
                  height: item.height,
                  length: item.length,
                  main_size: item.main_size,
                  mr_category_customer: item.mr_category_customer,
                  mr_product_category: item.mr_product_category,
                  mr_product_colour: item.mr_product_colour,
                  mr_product_group: item.mr_product_group,
                  mr_product_material: item.mr_product_material,
                  mr_product_style: item.mr_product_style,
                  product_barcode: item.product_barcode,
                  product_name: item.product_name,
                  weight: item.weight,
                  weight_unit: item.weight_unit,
                  width: item.width
                }
              })

              exportFile(failedData)
            }}
          >
            DOWNLOAD FAILED DATA
          </Button>
        )}

        <Button
          color="primary"
          disabled={index !== -1}
          onClick={() => toggle()}
        >
          {index === -1 || index >= data.length - 1 ? "START IMPORT" : `PROCESSING (${index + 1}/${data.length})`}
        </Button>
      </section>

      {[...Array(data.length)].map((_, key) => {
        // Vars
        const item = data[key]

        return (
          <ImportRow
            key={key}
            activeIndex={index}
            data={item}
            error={error.find((item) => key === item.index)?.message}
            index={key}
            onError={(value) => toggle("error", value)}
            onSuccess={() => toggle("success")}
          />
        )
      })}
    </div>
  )
}

function ImportRow(params: {
  activeIndex: number
  data: DataType
  error?: string[]
  index: number
  onSuccess: () => void
  onError: (value: string[]) => void
}): JSX.Element {
  // Vars
  const activeIndex = params.activeIndex
  const error = params.error
  const index = params.index
  const sameIndex: boolean = activeIndex === index
  const status = {
    error: error,
    progress: sameIndex && !error,
    success: !sameIndex && !error
  }
  
  // Hooks
  const api = useApi()
  const [data, setData] = useState<DataType>(params.data)

  useEffect(() => {
    if (sameIndex) {
      // Vars
      let payload = {
        product_code: data.product_code,
        product_barcode: data.product_barcode,
        product_name: data.product_name,
        mr_product_group_id: data.mr_product_group_id,
        mr_product_style_id: data.mr_product_style_id,
        main_size_id: data.main_size_id ?? undefined,
        mr_category_customer_id: data.mr_category_customer_id,
        mr_product_colour_id: data.mr_product_colour_id,
        mr_product_category_id: data.mr_product_category_id,
        mr_product_material_id: data.mr_product_material_id,
        size_type: data.size_type,
        type: data.type
      }
      const param = {
        length: data.size_type === "2" ? data.length : undefined,
        width: data.size_type === "2" ? data.width : undefined,
        height: data.size_type === "2" ? data.height : undefined,
        weight: data.weight,
        weight_unit_id: data.weight_unit_id,
        product_id: data.product_id ?? undefined
      }

      // Function to validate all products
      const validateProducts = async (): Promise<any> => {
        try {
          await productValidationSchema.validate({
            ...payload,
            ...param
          }, { abortEarly: false })
        } catch (err) {
          if (err instanceof ValidationError) {
            // Create an error object
            const errorObject = err.inner.reduce<Record<string, string>>((acc, curr) => {
              if (curr.path) {
                acc[curr.path] = curr.message
              }
              return acc
            }, {})

            return errorObject
          }
        }

        return null
      }

      // Functions
      const getApi = () => {
        if (data.product_id) {
          return api.putForm("/product/edit", payload, { params: param })
        }

        return api.postForm("/product/add", payload, { params: param })
      }
      const generateCode = (): Promise<string> => {
        return new Promise<string>((resolve) => {
          if (payload.product_code) {
            return resolve(payload.product_code)
          }

          api.get(
            "/product/productcode"
          ).then((res: SuccessFetch<{
            payload: { productcode: string }
          }>) => {
            resolve(res.data.payload.productcode)
          }).catch(() => {
            resolve("")
          })
        })
      }

      // Usage
      generateCode().then(code => {
        if (data.product_code !== code) {
          payload.product_code = code
          setData(prev => {
            return {
              ...prev,
              product_code: code
            }
          })
        }
      }).finally(() => {
        console.log(payload)
        validateProducts().then((errors) => {
          if (errors) {
            params.onError(Object.keys(errors).map(item => errors[item]))
            return
          }
  
          getApi().then(() => {
            params.onSuccess()
          }).catch((err: ErrorFetch<{
            message?: string
          }>) => {
            params.onError([err.response.data?.message ?? err.response.message])
          })
        })
      })
    }

    // eslint-disable-next-line
  }, [sameIndex])

  return (
    <Disclosure
      as="div"
      className="border p-2 rounded-lg"
    >
      <Disclosure.Button className="w-full flex justify-between items-center gap-3 p-2">
        <div className="grow text-left">{data.product_barcode} | {data.product_name}</div>

        <div>
          {activeIndex >= index && (
            <div className="border rounded">
              {status.progress ? (
                <Button
                  size="sm"
                  color="info"
                >
                  <MdOutlineDownloading size={14} />
                </Button>
              ) : status.success ? (
                <Button
                  size="sm"
                  color="primary"
                >
                  <TbCheck size={14} />
                </Button>
              ) : status.error ? (
                <Button
                  size="sm"
                  color="error"
                >
                  <TbX size={14} />
                </Button>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </Disclosure.Button>

      <Disclosure.Panel
        as="section"
        className="flex flex-col gap-3"
      >
        {error && (
          <section>
            <div className="text-lg font-bold">Error Messages</div>

            <ul className="list-disc list-inside text-error">
              {error.map((item, key) => <li key={key}>{item}</li>)}
            </ul>
          </section>
        )}

        <section>
          <div className="text-lg font-bold">Product Detail</div>

          <table>
            <tbody>
              {Object.keys(data).map((i, key) => (
                <tr key={key}>
                  <td className="pr-3">{i}</td>
                  <td>: {data[i]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </Disclosure.Panel>
    </Disclosure>
  )
}