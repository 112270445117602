import {
  BaseTable,
  Card,
  FooterMenu,
  HeaderMenu,
  Label,
  Loading,
  PaginationData,
  Select
} from "components"
import { createContext, useState } from "react"
import { APPROVAL_OPTIONS, default_pagination_value } from "utils"
import { TableList } from "./components"
import { FilterQuotation } from "./types"
import { useList } from "./utils"

type ContextApproveQuotationProps = {
  refetch: () => void
}

export const ContextApproveQuotation =
  createContext<ContextApproveQuotationProps>({
    refetch: () => {}
  })

export default function ApproveQuotation() {
  const [pagination, setPagination] = useState<FilterQuotation>({
    ...default_pagination_value,
    approval_status: "1"
  })

  const { data, isLoading, refetch } = useList(pagination)

  return (
    <ContextApproveQuotation.Provider value={{ refetch: refetch }}>
      <section className="container my-2">
        <section>
          <HeaderMenu title="CUSTOMER QUOTATION APPROVAL" />

          <section>
            <section className="mt-2">
              <Card>
                <Card.Body>
                  <div className="justify-end flex items-center">
                    <Label className="mr-2">APPROVAL</Label>
                    <Select
                      className="lg:w-[200px]"
                      placeholder="Select Approval"
                      options={APPROVAL_OPTIONS}
                      value={APPROVAL_OPTIONS.find(
                        (item) => item.value === pagination.approval_status
                      )}
                      onChange={(e) =>
                        setPagination({
                          ...pagination,
                          approval_status: e?.value
                        })
                      }
                    />
                  </div>
                </Card.Body>
              </Card>
            </section>
          </section>

          <section className="mt-2">
            <Card>
              <Card.Body>
                <div className="overflow-x-auto">
                  <BaseTable>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th colSpan={2}>QOUTATION ID#</th>
                        <th>DATE</th>
                        <th>CUSTOMER</th>
                        <th>LOCATION</th>
                        <th>MEMO</th>
                        <th>STATUS APPROVE</th>
                        <th>DATE APPROVE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading || !data.payload?.length ? (
                        <tr>
                          <td colSpan={12} className="text-center">
                            <Loading
                              loading={isLoading}
                              errorText={isLoading ? "" : "No data available"}
                            />
                          </td>
                        </tr>
                      ) : (
                        data.payload?.map((item, key) => {
                          return (
                            <TableList
                              key={item.customer_quotation_id}
                              data={item}
                              index={key}
                              pagination={pagination}
                            />
                          )
                        })
                      )}
                    </tbody>
                  </BaseTable>
                  <div className="mt-5">
                    <PaginationData
                      data={data}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </section>

          <section className="mt-2">
            <FooterMenu />
          </section>
        </section>
      </section>
    </ContextApproveQuotation.Provider>
  )
}
