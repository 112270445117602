// SweetAlert2
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

export function duplicateAlert(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    withReactContent(Swal).fire({
      title: "Are you sure you want to duplicate the entire profile to create a new item?",
      showCancelButton: true,
      confirmButtonText: "Duplicate",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-error"
      }
    }).then(res => {
      if (res.isConfirmed) {
        resolve()
      } else {
        reject()
      }
    })
  })
}