// React
import { useState } from "react"

// Components
import { HeaderMenu } from "components"
import { Format, ImportData, UploadFile } from "./components"

// Contexts
import { DataContext } from "./contexts"

// Types
import type { DataType } from "./types"

export default function ImportItem(): JSX.Element {
  // Hooks
  const [data, setData] = useState<DataType[]>([])

  return (
    <section className="container my-3 flex flex-col gap-5">
      <HeaderMenu title="IMPORT ITEM" />

      <DataContext.Provider value={{ data, assignData: setData }}>
        <section className="flex flex-wrap gap-3">
          <Format />
          <UploadFile />
        </section>

        {data.length ? <ImportData /> : <></>}
      </DataContext.Provider>
    </section>
  )
}