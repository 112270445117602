import { FormProvider, useForm } from "react-hook-form"
import { FormType } from "../../types"
import { FormSection } from "../FormSection"
import { HeaderMenu } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { validationSchema } from "../../utils/vars"
import toast from "react-hot-toast"
import { useApi } from "utils"
import { FormAction } from "../FormAction"
import { Fragment } from "react"
import moment from "moment"
import { useNavigate } from "react-router-dom"

export type DefaultValuesType = Pick<FormType, 'transaction_date' | 'location_id' | 'product_id' | 'mr_unit_id' | 'av_price_unit' | 'soh'>

export function CreateAction() {
    const api = useApi()
    const navigate = useNavigate()

    const defaultValues: DefaultValuesType = {
        transaction_date: moment().format("YYYY-MM-DD"),
        location_id: '',
        product_id: '',
        av_price_unit: '',
        mr_unit_id: '',
        soh: ''
    }

    const methods = useForm<DefaultValuesType>({
        defaultValues,
        resolver: yupResolver(validationSchema),
    })

    const onSubmit = (value: DefaultValuesType) => {
        return new Promise<void>((resolve) => {
            toast.promise(
                api.post("/opening-stock", {
                    transaction_date: value.transaction_date,
                    location_id: value.location_id,
                    product_id: value.product_id,
                    av_price_unit: value.av_price_unit,
                    soh: value.soh,
                }),
                {
                    loading: "Loading...",
                    success: (res) => res.data.message,
                    error: (err) => err.response.data.message ?? err.response.message
                }
            ).then(() => {
                navigate(-1)
            }).catch(() => { }).finally(resolve)
        })
    }

    return (
        <Fragment>
            <section className="container my-5">
                <section>
                    <HeaderMenu title="DATA ENTRY | OPENING STOCK" />
                    <section className="mt-2">
                        <section className="mt-2">
                            <FormProvider {...methods}>
                                <FormAction
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                    isEdit={true}
                                >
                                    <FormSection isEdit={true} />
                                </FormAction>
                            </FormProvider>
                        </section>
                    </section>
                </section>
            </section>
        </Fragment>
    )
}