// React
import { useState } from "react"

export function useDataImport(): {
  data: any[]
  setData: (data: any[]) => void
} {
  // Hooks
  const [data, setData] = useState<any[]>([])

  return { data, setData }
}