// Components
import { BaseTable, FooterMenu, HeaderMenu, Loading, Select } from "components"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import { Link } from "react-router-dom"

// Utils
import { statusList, useList } from "./utils"
import moment from "moment"
import { delivery_option } from "pages/Purchase/Register/utils"
import { convertNumber } from "utils"
import { billStatusList } from "../EnterBill/utils"

export default function RecordReject(): JSX.Element {
  // Hooks
  const { data, isLoading, statusReject, setStatusReject } = useList()

  return (
    <div className="container my-5 flex flex-col gap-3">
      <HeaderMenu title="DATA ENTRY | RECORD REJECT" />

      <div className="w-[300px]">
        <Select
          label="STATUS REJECT"
          placeholder="Select Status"
          options={statusList}
          value={statusList.find(item => item.value === statusReject) ?? null}
          onChange={e => setStatusReject(e?.value ?? "")}
        />
      </div>

      <BaseTable>
        <thead>
          <tr>
            <th>NO</th>
            <th colSpan={2}>PROJECT ID#</th>
            <th>DATE</th>
            <th>LINK BILL ID#</th>
            <th>VENDOR</th>
            <th>QTY REJECT</th>
            <th>TOTAL</th>
            <th>LOCATION</th>
            <th>MEMO</th>
            <th>STATUS APPROVE</th>
            <th>STATUS REJECT</th>
          </tr>
        </thead>

        <tbody>
          {isLoading || !data.length ? (
            <tr>
              <td colSpan={12}>
                <Loading loading={isLoading} />
              </td>
            </tr>
          ) : data.map((item, key) => (
            <tr key={key}>
              <td className="text-center">{key + 1}</td>

              <td>
                <Link to={`/purchase/transaction/record-reject/${item.reject_id}`}>
                  <IoSendSharp />
                </Link>
              </td>

              <td>{item.trx_code}</td>
              <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
              <td>{item.trx_bill_code}</td>
              <td>{item.vendor_name}</td>
              <td>{convertNumber(item.qty).intoCurrency}</td>
              <td>{convertNumber(item.total).intoCurrency}</td>
              <td>{item.location_name}</td>
              <td>{item.memo}</td>
              <td>{delivery_option.find(i => i.value === item.approve_status.toString())?.label ?? "-"}</td>
              <td>{billStatusList[item.reject_status.toString()]}</td>
            </tr>
          ))}
        </tbody>
      </BaseTable>

      <FooterMenu />
    </div>
  )
}