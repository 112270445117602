export const versionList: {
  description: string[]
  version: string
}[] = [
  {
    version: "1.05",
    description: [
      "Menyetarakan tinggi form pada Employee, Item, Vendor & Customer",
      "Selalu tampilkan scroll bar"
    ]
  },
  {
    version: "1.06",
    description: ["Tampilkan status privilege pada navbar layout"]
  },
  {
    version: "1.07",
    description: ["Tampilkan dokumentasi versi pada sistem"]
  },
  {
    version: "1.08",
    description: ["Sembunyikan menu System Setup"]
  },
  {
    version: "1.09",
    description: ["Hapus pop-up detail & pindahkan fungsi soft delete ke update pop-up di semua tampilan menu preference"]
  },
  {
    version: "1.10",
    description: [
      "Sorting list COA berdasarkan Code",
      "Tambah kolom location pada list COA",
      "Semua COA dapat diubah (dengan kondisi tertentu)"
    ]
  },
  {
    version: "1.11",
    description: [
      "Sembunyikan menu COA Group",
      "Hapus pop-up detail & pindahkan fungsi soft delete ke update pop-up di semua tampilan setup"
    ]
  },
  {
    version: "1.12",
    description: [
      "Tambah Inventory & Account Dashboard",
      "Tambah Analytics",
      "Ubah filter tanggal pada Finance Dashboard"
    ]
  },
  {
    version: "1.13",
    description: ["Tambah graph pada Analytics"]
  },
  {
    version: "1.14",
    description: [
      "Hilangkan kolom code & tambah fitur sort pada kolom action di privilege employee",
      "Ganti kolom kode dengan checkbox untuk menghapus banyak data (hanya tampilan)"
    ]
  },
  {
    version: "10.16",
    description: [
      "Penambahan setup - prefences - setting company (type barcode)",
      "Penambahan link account untuk : LA10-Asset Account for Reject  dan LA11- Liability Account for Returns",
      "disable edit pada setup - account - coa type",
      "Penambahan insert data ke table akumulasi Balance dan PNL berdasar lokasi",
      "Penambahan saat register subscribe di setiap company Usernane: TheKing@Skies",
      "Perubahan tampilan akumulasi aged payable (purchase - register - aged payable)",
      "Penambahan POP UP: item profile pada setiap data barcode di forrm data entry : PO dkk dan SO dkk"
    ]
  },
  {
    version: "10.17",
    description: [
      "Buat fungsi pada tombol history untuk melakukan refresh halaman",
      "Hilangkan tombol detail & soft delete pada inventory item",
      "Hilangkan semua status item pada profile bila status item menjadi Inactive"
    ]
  },
  {
    version: "10.18",
    description: [
      "Fitur hapus beberapa & semua data pada department"
    ]
  },
  {
    version: "11.11",
    description: [
      "Hapus kolom kode pada menu Divisi, Group Position & Job Position"
    ]
  },
  {
    version: "11.12",
    description: [
      "Tambah cetak barcode pada inventory register",
      "Ubah urutan & skema form work status pada Employee",
      "Perbaikan tampilan pop-up coa list",
      "Penambahan kolom baru pada form job position"
    ]
  },
  {
    version: "11.18",
    description: [
      "Hapus semua validasi maksimal teks pada setup",
      "Bersihkan semua value pada restrictedData & withOutButton"
    ]
  },
  {
    version: "11.19",
    description: [
      "Ubah nama & urutan menu",
      "Tambah tampilan 8 Key pada Analytics"
    ]
  },
  {
    version: "11.20",
    description: [
      "Perubahan tampilan tabel & integrasi dropdown filter dengan api pada 8 Key Analytics"
    ]
  },
  {
    version: "11.21",
    description: [
      "Penambahan tombol Duplicate Item pada Detail Inventory Item"
    ]
  },
  {
    version: "11.22",
    description: [
      "Ubah Gambar Placeholder Item",
      "Tambah pop-up pada Duplicate Item",
      "Perbaikan auto generate Nama & Barcode Item",
      "Menambah skema auto complete pada kolom debit & kredit General Entry",
      "Perbaikan modal jurnal pada spend money & receive money",
      "Samakan gambar pada product data dengan picture tab di item"
    ]
  },
  {
    version: "11.23",
    description: [
      "Perbaikan input kosong pada input Length, Height & Width pada item",
      "Penyesuaian semua input angka pada menu transaksi"
    ]
  },
  {
    version: "11.24",
    description: [
      "Tambah tombol refresh pada Item",
      "Tambah tombol cancel di setiap menu transaksi",
      "Tambah opsi ALL pada filter menu approval"
    ]
  },
  {
    version: "11.25",
    description: [
      "Hilangkan COA bernilai 0 pada transaksi jurnal",
      "Prioritaskan COA bernilai debet di setiap transaksi jurnal"
    ]
  },
  {
    version: "11.26",
    description: [
      "Perbaikan form item pada tab profile",
      "Hilangkan tombol tambah pada list coa jika level header item menjadi 3"
    ]
  },
  {
    version: "11.27",
    description: [
      "Perbaikan data bill masih mengikuti data po pada edit & detail",
      "Harga-harga item dapat diubah berdasarkan persentase",
      "Tambah status untuk mangaktifkan create deposit pada purchase order"
    ]
  },
  {
    version: "11.28",
    description: [
      "Hapus tombol jurnal pada footer payment bill"
    ]
  },
  {
    version: "12.07",
    description: [
      "Perbaikan ukuran gambar pada list item"
    ]
  },
  {
    version: "12.10",
    description: [
      "Perubahan label approval pada purchase order",
      "Perbaikan fungsi edit bill pada list all bills di purchase register"
    ]
  },
  {
    version: "12.11",
    description: [
      "Tambah Import Item"
    ]
  },
  {
    version: "12.12",
    description: [
      "Penyesuaian Import Item"
    ]
  },
  {
    version: "12.13",
    description: [
      "Perbaikan performa Import Item",
      "Tambah fitur untuk download data gagal import"
    ]
  }
]