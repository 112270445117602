// React
import { useContext, useEffect } from "react"

// Components
import {
  BaseTable,
  Card,
  Checkbox,
  CommandButton,
  DateInput,
  FooterMenu,
  HeaderMenu,
  Input,
  Loading,
  NumberInput,
  Select,
  Textarea
} from "components"
import { TableSection } from "./components"

// Icons
import { TbChevronRight } from "react-icons/tb"

// Third-Party Libraries
import moment from "moment"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"

// Types
import type { DetailType } from "./PurchaseOrder/types"
import { DataJournalContext, DataJournalContextProvider } from "contexts"

// Utils
import { convertNumber, useApi, useLocation, useToggle } from "utils"
import { default_option, delivery_option } from "../Register/utils"
import { useProduct } from "./PurchaseOrder/components/DetailBody/utils"
import { useSupplier } from "./components/Supplier/utils"
import { useDetail } from "./utils"

export default function ClosePurchase(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading } = useDetail(id!)

  if (isLoading || !data) {
    return <Loading loading={isLoading} />
  }

  return (
    <section className="container my-5 flex flex-col gap-2">
      <HeaderMenu title="DATA ENTRY | CLOSE PURCHASE">
        {data.trx_code}
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3 items-end">
        <Supplier value={data.vendor_id} />

        <div className="mb-3 flex flex-wrap">
          TRADING TERM{" "}
          <span className="mt-1">
            <TbChevronRight />
          </span>{" "}
          Net {data.payment_term} days after OEM
        </div>

        <Checkbox readOnly value={data.tax_inclusive}>
          TAX-INCLUSIVE
        </Checkbox>
      </section>

      <Card>
        <Card.Body className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
          <section className="flex flex-col gap-3">
            <section>
              <Deliver value={data.location_id} />

              <Textarea disabled value={data.address} />
            </section>

            <Input disabled label="MEMO" value={data.memo} />
          </section>

          <section className="flex flex-col gap-3">
            <DateInput
              disabled
              label="DATE"
              selected={moment(data.transaction_date).toDate()}
              onChange={() => {}}
            />

            <Input disabled label="SUPPLIER ID" value={data.referensi} />
          </section>
        </Card.Body>
      </Card>

      <div className="overflow-x-auto">
        <BaseTable>
          <thead>
            <tr>
              <th>ITEM CODE</th>
              <th>DESCRIPTION</th>
              <th>ORDER</th>
              <th>RECEIVED</th>
              <th>BACKORDER</th>
              <th>UNIT</th>
              <th>PRICE</th>
              <th>DISC</th>
              <th>TOTAL</th>
              <th>JOB</th>
              <th>TAX</th>
            </tr>
          </thead>

          <tbody>
            <DataJournalContextProvider>
              <BodyTable data={data.podetail} vendor_id={data.vendor_id} />
            </DataJournalContextProvider>
          </tbody>
        </BaseTable>
      </div>

      <div className="max-w-[600px]">
        <TableSection>
          <TableSection.Row
            label="PO STATUS"
            value={
              delivery_option.find(
                (item) => item.value === String(data.po_delivery_status ?? 1)
              )?.label ?? "-"
            }
          />

          <TableSection.Row
            label="APPROVE STATUS"
            value={default_option[data.approval_status]?.label ?? "-"}
          />

          <TableSection.Row label="APPROVED BY" value={data.approve_by_name} />
        </TableSection>

        <NumberInput
          disabled
          label="FREIGHT"
          inputClass="text-right"
          value={data.freight}
        />
      </div>

      <FooterMenu
        hasCancelButton
        customElement={{
          save: <Save />
        }}
      />
    </section>
  )
}

function BodyTable(params: {
  data: DetailType[]
  vendor_id: string
}): JSX.Element {
  // Hooks
  const { data, refetch } = useProduct()
  const { project, tax_code } = useContext(DataJournalContext)

  useEffect(() => {
    refetch(params.vendor_id)

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {params.data.map((item, key) => {
        // Vars
        const product = data.find((i) => i.value === item.product_id)
        const project_name: string | undefined = project.data.find(
          (i) => i.value === item.project_id
        )?.label
        const tax_name: string | undefined = tax_code.data.find(
          (i) => i.value === item.tax_id
        )?.label

        return (
          <tr key={key}>
            <td>{product?.product_barcode}</td>
            <td>{product?.product_name}</td>
            <td>{convertNumber(item.qty_order).intoNormalAmount}</td>
            <td>{convertNumber(item.delivered).intoNormalAmount}</td>
            <td>
              {convertNumber(item.qty_order - item.delivered).intoNormalAmount}
            </td>
            <td>{product?.mr_unit_name}</td>
            <td className="text-right">
              {convertNumber(item.price_measure).intoCurrency}
            </td>
            <td className="text-right">
              {convertNumber(item.discount).intoNormalAmount}%
            </td>
            <td className="text-right">
              {convertNumber(item.total).intoCurrency}
            </td>
            <td>{project_name}</td>
            <td>{tax_name}</td>
          </tr>
        )
      })}
    </>
  )
}

function Deliver(params: { value: string }): JSX.Element {
  // Hooks
  const { data, isLoading } = useLocation()

  return (
    <Select
      isDisabled
      label="DELIVER TO"
      placeholder="Select Deliver"
      isLoading={isLoading}
      options={data}
      value={data.find((item) => item.value === params.value) ?? null}
    />
  )
}

function Save(): JSX.Element {
  // Hooks
  const api = useApi()
  const navigate = useNavigate()
  const { id } = useParams()
  const { isActive, setActive } = useToggle(false)

  function onSubmit() {
    setActive(true)

    toast
      .promise(
        api.post(
          `/purchaseorder/manualclose?purchase_order_id=${id}&date_close=${moment().format(
            "YYYY-MM-DD"
          )}`
        ),
        {
          loading: "Closing Purchase Order...",
          success: (res) => res.data.message,
          error: (err) => err.response.data?.message ?? err.response.message
        }
      )
      .then(() => {
        navigate(-1)
      })
      .catch(() => {})
      .finally(() => {
        setActive(false)
      })
  }

  return (
    <CommandButton
      actiontype="save"
      loading={isActive ? "true" : undefined}
      onClick={onSubmit}
    />
  )
}

function Supplier(params: { value: string }): JSX.Element {
  // Hooks
  const { data, isLoading } = useSupplier()

  return (
    <Select
      isDisabled
      label="SUPPLIER"
      className="grow"
      placeholder="Select Supplier"
      isLoading={isLoading}
      value={data.find((item) => item.value === params.value) ?? null}
      options={data}
    />
  )
}
