import { BaseTable, Card, CommandButton, Loading, PaginationData } from "components";
import { Filter, Item, SummarySection } from "./components";
import { FilterType } from "./types";
import React, { useState } from "react";
import { defaultFilter, useList } from "./utils";
import { convertNumber } from "utils";

export const dataContext = React.createContext({
    currentPage: 1,
    limit: 10,
    size: 1,
    total: 0,
    refetch() {}
})

export default function SalesOrderRegisterInvoice() {

    const [selected, setSelected] = useState<FilterType>(defaultFilter)
    const { data, isLoading, refetch } = useList(selected)

    const subTotal = data?.payload?.reduce((acc, item) => acc + item.subtotal, 0)
    const paymentTotal = data?.payload?.reduce((acc, item) => acc + item.payment, 0)
    const balanceTotal = data?.payload?.reduce((acc, item) => acc + item.balance_owing, 0)

    return (
        <section className="container my-2">
            <section>

                <Filter selected={selected} setSelected={setSelected} />

                <section className="mt-2">
                    <Card>
                        <Card.Body>
                            <div className="overflow-x-auto">
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>INVOICE ID#</th>
                                            <th>REF SO ID#</th>
                                            <th>DATE</th>
                                            <th>CUSTOMER</th>
                                            <th className="whitespace-pre-line">STATUS APPROVAL</th>
                                            <th className="whitespace-pre-line">QTY DELIVERED</th>
                                            <th>LOCATION</th>
                                            <th>SUBTOTAL</th>
                                            <th>PAYMENT</th>
                                            <th className="whitespace-pre-line">BALANCE OWING</th>
                                            <th>ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <dataContext.Provider
                                            value={{
                                                currentPage: selected.page,
                                                limit: selected.limit,
                                                size: data.pagination_data.size,
                                                total: selected.total,
                                                refetch: refetch
                                            }}
                                        >
                                            {isLoading || data?.payload?.length === 0 ? (
                                                <tr>
                                                    <td colSpan={13} className="text-center">
                                                        <Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} />
                                                    </td>
                                                </tr>
                                            ) :
                                                data?.payload?.map((item: any, key: number) => (
                                                    <Item data={item} index={key} />
                                                ))}
                                        </dataContext.Provider>
                                    </tbody>
                                </BaseTable>
                                <div className="mt-5">
                                    <PaginationData data={data} pagination={selected} setPagination={setSelected} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>

                <section className="mt-2">
                    <Card>
                        <Card.Body className="flex flex-col gap-5">
                            <section className="flex flex-wrap justify-between items-center gap-3">
                                <section className="flex flex-wrap gap-3">
                                    <CommandButton actiontype="help" />
                                    <CommandButton actiontype="print" />
                                    <CommandButton actiontype="email" />
                                    <CommandButton actiontype="export" />
                                </section>
                                <section>
                                    <SummarySection label="SUMMARY SUBTOTAL" value={convertNumber(subTotal)?.intoCurrency} />
                                    <SummarySection label="SUMMARY PAYMENT" value={convertNumber(paymentTotal)?.intoCurrency} />
                                    <SummarySection label="SUMMARY BALANCE" value={convertNumber(balanceTotal)?.intoCurrency} />
                                </section>
                            </section>
                        </Card.Body>
                    </Card>
                </section>
            </section>
        </section>
    )
}