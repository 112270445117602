import { Fragment, useState } from "react"
import {
  ActionButton,
  Button,
  Card,
  FooterMenu,
  HeaderMenu,
  Loading
} from "../../../../../../../../../components"
import { FormProvider, useForm } from "react-hook-form"
import type {
  AddressList,
  PersonalList,
  ProfileList
} from "../../../../../types"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  useDetailProfileData,
  useDetailAddressData,
  useDetailMapData
} from "../utils"
import { RenderProvider } from "../../../../../utils"
import { Tab } from "./Tabs"
import { FormPersonalData } from "../../../../FormSection/FormPersonalData"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApi } from "../../../../../../../../../utils"
import toast from "react-hot-toast"
import { FormProfile } from "../../../../FormSection/FormProfile"
import { FormAddress } from "../../../../FormSection/FormAddress"
import { FormMap } from "../../../../FormSection/FormMap"
import {
  profileValidationSchema,
  addressValidationSchema,
  mapValidationSchema
} from "pages/Card/Transaction/CreateNewVendor/utils/function"

export function Update(props: { id: PersonalList; permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <ActionButton.Update
        permission={"CR033"}
        className="join-item"
        onClick={() =>
          navigate("/cards/transaction/create-new-vendor/update", {
            state: { dataId: props.id }
          })
        }
      />
    </Fragment>
  )
}
export function UpdatePage(props: {
  asModal?: boolean
  onSuccess?: () => void
  isDetailShop?: boolean
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const id = location?.state?.dataId?.vendor_id ?? params.id
  const data = location?.state?.dataId
  const tabs = ["PROFILE", "ADDRESS", "MAP"]

  const [activeTab, setActiveTab] = useState<number | undefined>(0)

  const { dataProfile, errorProfile, isLoadingProfile, refetchProfile } =
    useDetailProfileData(id ? id : null)
  const { dataAddress, errorAddress, isLoadingAddress, refetchAddress } =
    useDetailAddressData(id ? id : null)
  const { dataMap, errorMap, isLoadingMap, refetchMap } = useDetailMapData(
    id ? id : null
  )

  return (
    <RenderProvider>
      <section className="container my-5">
        {!props.asModal && <HeaderMenu title="UPDATE VENDOR REGISTRATION" />}

        <Card>
          <Card.Body className="flex-row justify-end items-center">
            {!props.isDetailShop && (
              <Button
                color="primary"
                onClick={() => navigate("/cards/register/all")}>
                BACK
              </Button>
            )}
          </Card.Body>
          <Card.Body>
            <div className="lg:flex gap-1">
              <div className="lg:w-[34%] border border-[#3c3c3c] lg:border-[#3c3c3c]">
                <button className="bg-[#3c3c3c] px-4 py-2 w-full text-white text-[12px]">
                  <b>PERSONAL DATA</b>
                </button>
                <div
                  className={`max-w-sm w-full lg:max-w-full lg:flex h-[1600px]`}>
                  <div className="w-full p-6">
                    <DataForm
                      dataDefault={data!}
                      dataProfile={dataProfile!}
                      setActiveTab={setActiveTab}
                      onSuccess={props.onSuccess}
                    />
                  </div>
                </div>
              </div>
              <div className="lg:w-[66%] border border-[#3c3c3c] lg:border-[#3c3c3c]">
                <Tab
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  className="text-[11px]"
                />
                <div
                  className={`max-w-sm w-full lg:max-w-full lg:flex h-[1600px]`}>
                  <div className="w-full p-6">
                    {activeTab === 0 ? (
                      isLoadingProfile || errorProfile ? (
                        <Loading
                          errorText={errorProfile}
                          loading={isLoadingProfile}
                        />
                      ) : (
                        <DataProfileForm
                          dataProfile={dataProfile!}
                          id={id}
                          refetchProfile={refetchProfile}
                        />
                      )
                    ) : (
                      ""
                    )}
                    {activeTab === 1 ? (
                      isLoadingAddress || errorAddress ? (
                        <Loading
                          errorText={errorAddress}
                          loading={isLoadingAddress}
                        />
                      ) : (
                        <DataAddressForm
                          dataAddress={dataAddress!}
                          id={id}
                          refetchAddress={refetchAddress}
                        />
                      )
                    ) : (
                      ""
                    )}
                    {activeTab === 2 ? (
                      isLoadingMap || errorMap ? (
                        <Loading errorText={errorMap} loading={isLoadingMap} />
                      ) : (
                        <DataMapForm
                          dataMap={dataMap!}
                          id={id}
                          refetchMap={refetchMap}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <section className="mt-2">
          {!props.isDetailShop && <FooterMenu hasCancelButton />}
        </section>
      </section>
    </RenderProvider>
  )
}
function DataForm(props: {
  dataDefault: PersonalList
  dataProfile?: ProfileList
  setActiveTab: (newState: any) => void
  onSuccess?: () => void
}) {
  const api = useApi()
  const location = useLocation()

  const [fileValue, setFileValue] = useState<File | undefined>(undefined)

  type UpdatePersonalData = Pick<
    PersonalList,
    "vendor_code" | "vendor_Name" | "vendor_Foto"
  >

  const defaultValues: UpdatePersonalData = {
    vendor_code:
      props?.dataDefault?.vendor_code ?? props?.dataProfile?.vendor_code ?? "",
    vendor_Name:
      props?.dataDefault?.vendor_name ?? props?.dataProfile?.vendor_name ?? "",
    vendor_Foto: props?.dataDefault?.foto ?? ""
  }
  const validationSchema = yup
    .object()
    .shape({
      vendor_Name: yup
        .string()
        .label("Vendor name")
        .required()
        .max(30, "Must be 30 characters or less")
    })
  const methods = useForm<UpdatePersonalData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: PersonalList) => {
    const formData = new FormData()

    formData.append("vendor_code", value?.vendor_code!)
    formData.append("vendor_Name", value?.vendor_Name)
    if (value?.vendor_Foto) {
      formData.append(fileValue ? "vendor_Foto" : "", value?.vendor_Foto)
    }

    const params: { [key: string]: string | undefined } = {}
    if (location?.state?.dataId?.vendor_id)
      params.vendor_id = location?.state?.dataId?.vendor_id

    return new Promise<void>((resolve) => {
      toast
        .promise(api.put("/vendor/edit", formData, { params }), {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        })
        .then(() => {
          props.setActiveTab(0)

          if (props.onSuccess) {
            props.onSuccess()
          }
        })
        .catch(() => {})
        .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormPersonalData
        action="UPDATE"
        onSubmit={onSubmit}
        setFileValue={setFileValue}
      />
    </FormProvider>
  )
}

interface ProfileProps {
  dataProfile?: any
  id: number
  refetchProfile?: () => void
}
const DataProfileForm: React.FC<ProfileProps> = ({
  dataProfile,
  id,
  refetchProfile
}) => {
  const api = useApi()
  type CreateProfile = Omit<
    ProfileList,
    | "vendor_id"
    | "price_level_vendor_id"
    | "payment_term_vendor_id"
    | "link_map"
  >

  const defaultValues: CreateProfile = {
    handphone: dataProfile?.handphone ?? "",
    email: dataProfile?.email ?? "",
    website: dataProfile?.website ?? "",
    card_label: dataProfile?.card_label ?? undefined,
    group_card: dataProfile?.group_card ?? "3",
    category_vendor_id: dataProfile?.category_vendor_id ?? undefined,
    source_from: dataProfile?.source_from ?? "",
    employee_id: dataProfile?.employee_id ?? undefined,
    media: dataProfile?.media ?? "",
    price_level_id: dataProfile?.price_level_vendor_id ?? undefined,
    payment_term_id: dataProfile?.payment_term_vendor_id ?? undefined,
    max_balance: dataProfile?.max_balance ?? undefined,
    bank_id: dataProfile?.bank_id ?? undefined,
    bank_account: dataProfile?.bank_account ?? "",
    bank_beneficiary: dataProfile?.bank_beneficiary ?? "",
    bank_branch: dataProfile?.bank_branch ?? ""
  }
  const methods = useForm<CreateProfile>({
    defaultValues,
    resolver: yupResolver(profileValidationSchema)
  })
  const onSubmit = (value: CreateProfile) => {
    return new Promise<void>((resolve) => {
      toast
        .promise(api.put(`/vendor/updateprofile?vendor_id=${id}`, value), {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        })
        .then(() => refetchProfile && refetchProfile())
        .catch(() => refetchProfile && refetchProfile())
        .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormProfile action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface AddressProps {
  dataAddress?: any
  id: number
  refetchAddress?: () => void
}
const DataAddressForm: React.FC<AddressProps> = ({
  dataAddress,
  id,
  refetchAddress
}) => {
  const api = useApi()

  const defaultValues = {
    address_type_id: dataAddress?.[0]?.address_type_id ?? undefined,
    address_type_id_2: dataAddress?.[0]?.address_type_id_2 ?? undefined,
    country_id: dataAddress?.[0]?.country_id ?? undefined,
    country_id_2: dataAddress?.[0]?.country_id_2 ?? undefined,
    province_id: dataAddress?.[0]?.province_id ?? undefined,
    province_id_2: dataAddress?.[0]?.province_id_2 ?? undefined,
    regency_id: dataAddress?.[0]?.regency_id ?? undefined,
    regency_id_2: dataAddress?.[0]?.regency_id_2 ?? undefined,
    subdistrict_id: dataAddress?.[0]?.subdistrict_id ?? undefined,
    subdistrict_id_2: dataAddress?.[0]?.subdistrict_id_2 ?? undefined,
    village_id: dataAddress?.[0]?.village_id ?? undefined,
    village_id_2: dataAddress?.[0]?.village_id_2 ?? undefined,
    address: dataAddress?.[0]?.address ?? undefined,
    address_2: dataAddress?.[0]?.address_2 ?? undefined
  }
  const methods = useForm<AddressList>({
    defaultValues,
    resolver: yupResolver(addressValidationSchema)
  })
  const onSubmit = (value: AddressList) => {
    return new Promise<void>((resolve) => {
      toast
        .promise(api.put(`/vendor/updateaddress?vendor_id=${id}`, value), {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        })
        .then(() => refetchAddress && refetchAddress())
        .catch(() => refetchAddress && refetchAddress())
        .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormAddress action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}

interface MapProps {
  dataMap?: any
  id: number
  refetchMap?: () => void
}
const DataMapForm: React.FC<MapProps> = ({ dataMap, id, refetchMap }) => {
  const api = useApi()

  type CreateMap = Pick<ProfileList, "link_map">

  const defaultValues: CreateMap = { link_map: dataMap?.link_map ?? "" }
  const methods = useForm<CreateMap>({
    defaultValues,
    resolver: yupResolver(mapValidationSchema)
  })
  const onSubmit = (value: CreateMap) => {
    return new Promise<void>((resolve) => {
      toast
        .promise(api.put(`/vendor/updatealinkmap?vendor_id=${id}`, value), {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        })
        .then(() => refetchMap && refetchMap())
        .catch(() => refetchMap && refetchMap())
        .finally(resolve)
    })
  }

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <FormMap action="UPDATE" onSubmit={onSubmit} />
    </FormProvider>
  )
}
