import { BaseTable, Card, FooterMenu, HeaderMenu, Label, Loading, PaginationData, Select } from "components";
// import { Item } from "./components";
import { appDropdown, useApprovalList, defaultValue } from "./utils";
import React, { useState } from "react";
import { FilterState } from "./types";
import { Item } from "./components";

export const dataContext = React.createContext({
    currentPage: 1,
    limit: 10,
    size: 1,
    total: 0
})

export default function ApproveDepoVendor() {
    const [pagination, setPagination] = useState<FilterState>(defaultValue)
    const { data, isLoading, refetch } = useApprovalList(pagination)

    return (
        <section className="container my-2">
            <section>
                <HeaderMenu title="DEPOSIT APPROVAL" />

                <section>
                    <section className="mt-2">
                        <Card>
                            <Card.Body>
                                <div className="justify-end lg:flex items-center">
                                    <Label className="mr-2">APPROVAL</Label>
                                    <Select
                                        className="lg:w-[200px]"
                                        placeholder="Select Approval"
                                        options={appDropdown}
                                        value={appDropdown.find(item => item.value === pagination.approval_status )}
                                        onChange={e => setPagination({...pagination, approval_status: e?.value})}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </section>
                </section>

                <section className="mt-2">
                    <Card>
                        <Card.Body>
                            <div className="overflow-x-auto">
                                <BaseTable>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th colSpan={2}>PAYMENT ID#</th>
                                            <th>DATE</th>
                                            <th>VENDOR</th>
                                            <th>REF PO ID#</th>
                                            <th>PAYMENT AMOUNT</th>
                                            <th>LOCATION</th>
                                            <th>MEMO</th>
                                            <th>STATUS APPROVE</th>
                                            <th>DATE APPROVE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <dataContext.Provider
                                            value={{
                                                currentPage: pagination.page,
                                                limit: pagination.limit,
                                                size: data.pagination_data.size,
                                                total: pagination.total
                                            }}
                                        >
                                            {isLoading || data?.payload?.length === 0 ? (
                                                <tr>
                                                    <td colSpan={12} className="text-center">
                                                        <Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} />
                                                    </td>
                                                </tr>
                                            ) :
                                                data?.payload.map((item, key: number) => (
                                                    <Item data={item} index={key} refetch={refetch} />
                                                ))}
                                        </dataContext.Provider>
                                    </tbody>
                                </BaseTable>
                                <div className="mt-5">
                                    <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </section>

                <section className="mt-2">
                    <FooterMenu/>
                </section>
            </section>
        </section>
    )
}