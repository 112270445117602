import { JournalList, JournalListCheck, MapJournalList } from "../../types"
import { dataSource } from "../vars/dataSource"

export const mapJournalList = (data: JournalList[], data_source_id?: number): MapJournalList[] => {
  let res: MapJournalList[] = []

  const dataJournal = data_source_id ? data?.filter(item => item.data_source_id === data_source_id) : data

  // get unique trx_code
  const trxCode: string[] = [
    ...new Set(dataJournal?.map((journal) => journal.trx_code))
  ]

  trxCode.forEach((trx) => {
    // group transaction by trx_code
    const dataByTrxCode: JournalListCheck[] = data
      ?.filter((journal) => journal.trx_code === trx)
      .map((journal) => ({
        ...journal,
        job_id: journal.job_id === "string" ? "" : journal.job_id
      }))

    // calculate total debit & credit of dataByTrxCode
    const balanceChecker: JournalListCheck = dataByTrxCode.reduce(
      (acc, curr) => {
        acc.reconsiled = curr.reconsiled
        acc.journal_code = curr.journal_code

        if (curr.position === 1) {
          acc.checkerDebit! += curr.amount
        } else {
          acc.checkerCredit! += curr.amount
        }

        return acc
      },
      {
        journal_id: NaN,
        journal_code: "",
        transaction_date: "",
        source_memo: "",
        source_code: "",
        trx_code: trx,
        coa_id: NaN,
        coa_code: "",
        coa_name: "",
        memo: "Balance Checker",
        amount: NaN,
        position: NaN,
        reconsiled: false,
        job_id: "",
        data_source: NaN,
        data_source_id: NaN,
        checkerDebit: 0,
        checkerCredit: 0
      }
    )

    const memoSourceRow: JournalListCheck = {
      journal_id: NaN,
      journal_code: "",
      transaction_date: dataByTrxCode[0].transaction_date,
      source_memo: dataSource[dataByTrxCode[0].data_source]?.group + " transactions",
      source_code: "",
      trx_code: trx,
      coa_id: NaN,
      coa_code: "",
      coa_name: "",
      memo: "",
      amount: NaN,
      position: NaN,
      reconsiled: dataByTrxCode[0].reconsiled,
      job_id: "",
      data_source: dataByTrxCode[0].data_source,
      data_source_id: dataByTrxCode[0].data_source_id
    }

    dataByTrxCode.unshift(memoSourceRow)

    res.push({
      date: dataByTrxCode[0].transaction_date,
      data: {
        source_memo: dataSource[dataByTrxCode[0].data_source]?.group + " transactions",
        data: dataByTrxCode.concat(balanceChecker)
      }
    })
  })

  return res
}
