import { Input, Select, Button, Checkbox, NumberInput, Loading } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { PersonalDataProps, ProductList } from "../../types"
import {
  useCategoryCustomer,
  useProductCategory,
  useProductColour,
  useProductGroup,
  useProductMaterial,
  useProductSize,
  useProductStyle,
  useProductUnit
} from "./utils"
import { FaHistory, FaPlus } from "react-icons/fa"
import { useContext, useEffect, useState } from "react"
import { CreateModal as CreateModalUnit } from "../../../../../Setup/Inventory/ProductUnit/components"
import { CreateModal as CreateModalSize } from "../../../../../Setup/Inventory/ProductSize/components"
import { CreateModal as CreateModalStyle } from "../../../../../Setup/Inventory/ProductStyle/components"
import { CreateModal as CreateModalColour } from "../../../../../Setup/Inventory/ProductColour/components"
import { CreateModal as CreateModalCategory } from "../../../../../Setup/Inventory/ProductCategory/components"
import { CreateModal as CreateModalMarket } from "../../../../../Setup/Preference/CategoryCustomer/components"
import { CreateModal as CreateModalMaterial } from "../../../../../Setup/Inventory/ProductMaterial/components"
import {
  useProductCode,
  useProductBarcode,
  useProductName,
  useDefaultSize
} from "../ChildItem/components/Item/utils"
import { ModalSectionPortal } from "../ChildItem/components/Item/components/FormPersonalData"
import { PictureContext } from "../../contexts"

export const FormPersonalData = ({
  action,
  data,
  onSubmit,
  setFileValue,
  isActiveSubmit,
  itemsProfile,
  setCheckLoading,
  permission
}: PersonalDataProps) => {
  const { control, setValue, getValues, handleSubmit, formState } =
    useFormContext<ProductList>()

  const { dataCode } = useProductCode()
  const { dataUnit, isLoadingUnit, refetchUnit } = useProductUnit("name")
  const { dataProduct, isLoadingProduct } = useProductGroup()
  const { dataStyle, isLoadingStyle, refetchStyle } = useProductStyle()
  const { dataSize, isLoadingSize, refetchSize } = useProductSize()
  const { dataColour, isLoadingColour, refetchColour } = useProductColour()
  const { dataCategory, isLoadingCategory, refetchCategory } =
    useProductCategory()
  const { dataCustomer, isLoadingCustomer, refetchMarket } =
    useCategoryCustomer()
  const { dataMaterial, isLoadingMaterial, refetchMaterial } =
    useProductMaterial()
  const { dataDefault } = useDefaultSize()

  const [errorText, setErrorText] = useState<any>()
  const [imageViews, setImageView] = useState<{
    file: File | null
    view: string | null
  }>({ file: null, view: null })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    action: "",
    data: {}
  })
  const { REACT_APP_BASE_URL_API } = process.env

  const universal_size = useWatch({ control, name: "universal_size" })
  const volume_size = useWatch({ control, name: "volume_size" })
  const main_size_id = useWatch({ control, name: "main_size_id" })

  const isActiveUniversalField = universal_size === true
  const isActiveVolumeField = volume_size === true

  const picture = useContext(PictureContext)

  const calculateVolume = () => {
    const length = parseFloat(control?._formValues?.length) || 0
    const width = parseFloat(control?._formValues?.width) || 0
    const hight = parseFloat(control?._formValues?.height) || 0
    const volumeValue = (length * width * hight) / 1000000

    setValue("volume", String(volumeValue))
  }

  const length = parseFloat(control?._formValues?.length) || 0
  const width = parseFloat(control?._formValues?.width) || 0
  const hight = parseFloat(control?._formValues?.height) || 0
  const volumeValue = (length * width * hight) / 1000000

  const onBSIcreate = `${itemsProfile?.buy ? "B" : ""}${
    itemsProfile?.sell ? "S" : ""
  }${itemsProfile?.inventory ? "I" : ""}`

  useEffect(() => {
    if (volume_size === true) {
      setValue("main_size_id", dataDefault?.default_size!)
    }

    // eslint-disable-next-line
  }, [dataDefault?.default_size])

  return (
    <section>
      {/* Input Profile */}
      <>
        <div className="avatar flex justify-center mt-6 mb-10">
          <div className="w-[150px]">
            {/* eslint-disable-next-line */}
            {picture.isLoading ? (
              <Loading loading />
            ) : (
              <img
                src={
                  picture.data ? 
                  `${REACT_APP_BASE_URL_API}/getimage/?imgpath=${picture.data}` :
                  "https://t4.ftcdn.net/jpg/05/17/53/57/360_F_517535712_q7f9QC9X6TQxWi6xYZZbMmw5cnLMr279.jpg"
                }
                alt={data?.product_name ?? "Item Picture"}
              />
            )}
          </div>
        </div>
        <Controller
          control={control}
          name="Product_Foto"
          render={({ field }) => (
            <input
              className="absolute mt-[-190px] ml-[100px] w-[150px] h-[150px] rounded-[50%] opacity-0"
              type="file"
              disabled={action === "DETAIL"}
              onChange={(e) => {
                setFileValue(e.target.files?.[0])
                const selectedFile = e.target.files?.[0]
                field.onChange(e.target.files?.[0])

                if (selectedFile) {
                  setImageView({
                    ...imageViews,
                    file: selectedFile ?? control?._formValues?.Product_Foto
                  })
                  const reader = new FileReader()
                  reader.onloadend = () => {
                    setImageView({
                      ...imageViews,
                      view: reader.result as string
                    })
                  }
                  reader.readAsDataURL(selectedFile)
                } else {
                  setImageView({ ...imageViews, file: null, view: null })
                }
              }}
            />
          )}
        />
      </>

      {/* Product ID */}
      <div className="flex items-end">
        <Controller
          control={control}
          name="product_code"
          render={({ field }) => (
            <Input
              {...field}
              label="PRODUCT ID"
              className="w-full"
              inputClass="!rounded-r-none"
              value={
                field.value ?? data?.product_code ?? dataCode?.productcode ?? ""
              }
              disabled={true}
            />
          )}
        />
        <label className="form-control w-[100px]">
          <div className="input input-bordered border-none bg-gray-200 dark:bg-gray-900 dark:border-gray-400 !rounded-l-none font-black dark:text-primary flex items-center justify-center">
            {onBSIcreate}
          </div>
        </label>
      </div>

      {/* Item Group */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="mr_product_group_id"
          render={({ field, fieldState }) => {
            return (
              <Select
                label="ITEM GROUP"
                controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
                placeholder="Select Item Group"
                isDisabled={action === "DETAIL"}
                isLoading={isLoadingProduct}
                error={fieldState.error?.message}
                options={dataProduct}
                value={
                  field.value
                    ? dataProduct.find(
                        (item) => item.value === field.value?.toString()
                      )
                    : null
                }
                onChange={(e) => {
                  field.onChange(e?.value)
                  setValue(
                    "product_code",
                    action === "UPDATE"
                      ? control?._formValues?.product_code
                      : dataCode?.productcode!
                  )
                }}
              />
            )
          }}
        />
        {action !== "DETAIL" && (
          <div className="mt-[36px]">
            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({ ...modalConfig, show: true, action: "PORTAL" })
              }>
              PORTAL
            </Button>
          </div>
        )}
      </div>

      {/* Item Style */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="mr_product_style_id"
          render={({ field, fieldState }) => (
            <Select
              label="STYLE"
              controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Select Style"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingStyle}
              error={fieldState.error?.message}
              options={dataStyle}
              value={dataStyle.find(
                (item) => item.value === field.value?.toString()
              )}
              onChange={(e) => {
                field.onChange(e?.value)
              }}
            />
          )}
        />
        {action !== "DETAIL" && (
          <div className="flex mt-[36px]">
            <Button
              type="button"
              color="primary"
              onClick={refetchStyle}
            >
              <FaHistory />
            </Button>

            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({ ...modalConfig, show: true, action: "STYLE" })
              }>
              <FaPlus />
            </Button>
          </div>
        )}
      </div>

      {/* Universal Size */}
      <>
        <label className="label">
          <span
            className={`label-text font-bold text-[15px] ${
              errorText && !main_size_id && "text-error"
            }`}>{`SIZE ${
            errorText && !main_size_id ? "( field required )" : ""
          }`}</span>
        </label>
        <Controller
          control={control}
          name="universal_size"
          render={({ field }) => (
            <Checkbox
              {...field}
              ref={null}
              disabled={action === "DETAIL"}
              onChange={(e) => {
                setValue("universal_size", e.target.checked)
                setValue("volume_size", false)
                setValue("length", "0")
                setValue("width", "0")
                setValue("height", "0")
                setValue("volume", "")
                setValue("size_type", "1")
              }}>
              UNIVERSAL SIZE
            </Checkbox>
          )}
        />
        {isActiveUniversalField && (
          <div className="flex items-top">
            <Controller
              control={control}
              name="main_size_id"
              render={({ field, fieldState }) => (
                <Select
                  controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
                  className="mt-3"
                  placeholder="Select Size"
                  isDisabled={action === "DETAIL" || !isActiveUniversalField}
                  isLoading={isLoadingSize}
                  error={fieldState.error?.message}
                  options={dataSize}
                  value={
                    field.value !== undefined
                      ? dataSize.find(
                          (item) => item.value === field.value?.toString()
                        )
                      : []
                  }
                  onChange={(e) => field.onChange(e?.value)}
                />
              )}
            />
            {action !== "DETAIL" && (
              <div className="flex mt-[12px]">
                <Button
                  type="button"
                  color="primary"
                  onClick={refetchSize}
                >
                  <FaHistory />
                </Button>

                <Button
                  type="button"
                  color="primary"
                  className="!rounded-r-lg"
                  disabled={!isActiveUniversalField}
                  onClick={() =>
                    setModalConfig({
                      ...modalConfig,
                      show: true,
                      action: "SIZE"
                    })
                  }>
                  <FaPlus />
                </Button>
              </div>
            )}
          </div>
        )}
      </>

      {/* Volume Size */}
      <>
        <div className="mt-5">
          <Controller
            control={control}
            name="volume_size"
            render={({ field }) => (
              <Checkbox
                {...field}
                ref={null}
                disabled={action === "DETAIL"}
                onChange={(e) => {
                  setValue("volume_size", e.target.checked)
                  // field.onChange(!field.value)
                  setValue("universal_size", false)
                  // setValue("main_size_id", undefined!)
                  setValue("main_size_id", dataDefault?.default_size!)
                  setValue("size_type", "2")
                }}>
                VOLUME SIZE
              </Checkbox>
            )}
          />
        </div>
        {isActiveVolumeField && (
          <div className="flex items-start gap-1">
            <Controller
              control={control}
              name="volume"
              render={({ field }) => (
                <Input
                  {...field}
                  label="VOLUME"
                  className="min-w-[70px]"
                  value={`${field.value ?? volumeValue ?? ""} M³`}
                  disabled={true}
                />
              )}
            />
            <Controller
              control={control}
              name="length"
              render={({ field, fieldState }) => (
                <div className="min-w-[70px]">
                  <NumberInput
                    label="LENGTH"
                    error={fieldState.error?.message}
                    placeholder="Cm"
                    value={field.value ?? data?.length ?? ""}
                    disabled={action === "DETAIL" || !isActiveVolumeField}
                    ref={field.ref}
                    onValueChange={(e) => {
                      field.onChange(String(e.floatValue ?? 0))
                      calculateVolume()
                    }}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="width"
              render={({ field, fieldState }) => (
                <div className="min-w-[70px]">
                  <NumberInput
                    label="WIDTH"
                    error={fieldState.error?.message}
                    placeholder="Cm"
                    value={field.value ?? data?.width ?? ""}
                    disabled={action === "DETAIL" || !isActiveVolumeField}
                    ref={field.ref}
                    onValueChange={(e) => {
                      field.onChange(String(e.floatValue ?? 0))
                      calculateVolume()
                    }}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="height"
              render={({ field, fieldState }) => (
                <div className="min-w-[70px]">
                  <NumberInput
                    label="HEIGHT"
                    error={fieldState.error?.message}
                    placeholder="Cm"
                    value={field.value ?? data?.width ?? ""}
                    disabled={action === "DETAIL" || !isActiveVolumeField}
                    ref={field.ref}
                    onValueChange={(e) => {
                      field.onChange(String(e.floatValue ?? 0))
                      calculateVolume()
                    }}
                  />
                </div>
              )}
            />
          </div>
        )}
      </>

      {/* Weight */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="weight"
          render={({ field, fieldState }) => (
            <div className="min-w-[70px]">
              <NumberInput
                label="WEIGHT"
                className="w-full"
                error={fieldState.error?.message}
                inputClass="!rounded-r-none"
                placeholder="Enter Weight"
                value={field.value ?? data?.weight ?? ""}
                disabled={action === "DETAIL"}
                ref={field.ref}
                onValueChange={(e) => {
                  field.onChange(e.value)
                }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="weight_unit_id"
          render={({ field, fieldState }) => (
            <Select
              controlClass={`${
                action !== "DETAIL" ? "!rounded-none" : "!rounded-l-none"
              } mt-9`}
              placeholder="Unit"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingUnit}
              error={fieldState.error?.message}
              options={dataUnit}
              value={dataUnit.find(
                (item) => item.value === field.value?.toString()
              )}
              onChange={(e) => {
                field.onChange(e?.value)
              }}
            />
          )}
        />
        {action !== "DETAIL" && (
          <div className="flex mt-[36px]">
            <Button
              type="button"
              color="primary"
              onClick={refetchUnit}
            >
              <FaHistory />
            </Button>

            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({ ...modalConfig, show: true, action: "UNIT" })
              }>
              <FaPlus />
            </Button>
          </div>
        )}
      </div>

      {/* Colour */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="mr_product_colour_id"
          render={({ field, fieldState }) => (
            <Select
              label="COLOUR"
              controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Select Colour"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingColour}
              error={fieldState.error?.message}
              options={dataColour}
              value={dataColour.find(
                (item) => item.value === field.value?.toString()
              )}
              onChange={(e) => {
                field.onChange(e?.value)
              }}
            />
          )}
        />
        {action !== "DETAIL" && (
          <div className="flex mt-[36px]">
            <Button
              type="button"
              color="primary"
              onClick={refetchColour}
            >
              <FaHistory />
            </Button>

            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({ ...modalConfig, show: true, action: "COLOUR" })
              }>
              <FaPlus />
            </Button>
          </div>
        )}
      </div>

      {/* Category */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="mr_product_category_id"
          render={({ field, fieldState }) => (
            <Select
              label="CATEGORY"
              controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Select Category"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingCategory}
              error={fieldState.error?.message}
              options={dataCategory}
              value={dataCategory.find(
                (item) => item.value === field.value?.toString()
              )}
              onChange={(e) => {
                field.onChange(e?.value)
              }}
            />
          )}
        />
        {action !== "DETAIL" && (
          <div className="flex mt-[36px]">
            <Button
              type="button"
              color="primary"
              onClick={refetchCategory}
            >
              <FaHistory />
            </Button>

            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: true,
                  action: "CATEGORY"
                })
              }>
              <FaPlus />
            </Button>
          </div>
        )}
      </div>

      {/* Market Segment */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="mr_category_customer_id"
          render={({ field, fieldState }) => (
            <Select
              label="MARKET SEGMENT"
              controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Select Market Segment"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingCustomer}
              error={fieldState.error?.message}
              options={dataCustomer}
              value={dataCustomer.find(
                (item) => item.value === field.value?.toString()
              )}
              onChange={(e) => field.onChange(e?.value)}
            />
          )}
        />
        {action !== "DETAIL" && (
          <div className="flex mt-[36px]">
            <Button
              type="button"
              color="primary"
              onClick={refetchMarket}
            >
              <FaHistory />
            </Button>

            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({ ...modalConfig, show: true, action: "MARKET" })
              }>
              <FaPlus />
            </Button>
          </div>
        )}
      </div>

      {/* Material */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="mr_product_material_id"
          render={({ field, fieldState }) => (
            <Select
              label="MATERIAL"
              controlClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Select Material"
              isDisabled={action === "DETAIL"}
              isLoading={isLoadingMaterial}
              error={fieldState.error?.message}
              options={dataMaterial}
              value={dataMaterial.find(
                (item) => item.value === field.value?.toString()
              )}
              onChange={(e) => {
                field.onChange(e?.value)
              }}
            />
          )}
        />
        {action !== "DETAIL" && (
          <div className="flex mt-[36px]">
            <Button
              type="button"
              color="primary"
              onClick={refetchMaterial}
            >
              <FaHistory />
            </Button>

            <Button
              type="button"
              color="primary"
              className="!rounded-r-lg"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: true,
                  action: "MATERIAL"
                })
              }>
              <FaPlus />
            </Button>
          </div>
        )}
      </div>

      {/* Item Barcode */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="product_barcode"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label="ITEM BARCODE"
              className="w-full"
              error={
                control?._formValues?.product_barcode
                  ? ""
                  : fieldState.error?.message
              }
              inputClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Input item barcode"
              value={field.value ?? data?.product_barcode ?? ""}
              disabled={action === "DETAIL"}
            />
          )}
        />
        {action !== "DETAIL" && <GenerateCode />}
      </div>

      {/* Item Name */}
      <div className="flex items-top">
        <Controller
          control={control}
          name="product_name"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label="ITEM NAME"
              className="w-full"
              error={
                control?._formValues?.product_name
                  ? ""
                  : fieldState.error?.message
              }
              inputClass={action !== "DETAIL" ? "!rounded-r-none" : ""}
              placeholder="Input item name"
              value={field.value ?? data?.product_name ?? ""}
              disabled={action === "DETAIL"}
            />
          )}
        />
        {action !== "DETAIL" && <GenerateItem />}
      </div>

      {/* Submit Button */}
      {action !== "DETAIL" && (
        <div className="mt-5 flex justify-end">
          <Button
            type="button"
            color="primary"
            permission={"IT011"}
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(
              () => onSubmit(getValues()),
              (err) => setErrorText(err?.main_size_id)
            )}
            disabled={
              (action === "UPDATE" && isActiveSubmit) || dataDefault === null
            }>
            {dataDefault === null
              ? "memproses..."
              : action === "UPDATE"
              ? "UPDATE"
              : !main_size_id
              ? "SAVE"
              : isActiveSubmit
              ? "UPDATE"
              : "SAVE"}
          </Button>
        </div>
      )}

      {modalConfig?.show === true && modalConfig?.action === "PORTAL" && (
        <ModalSectionPortal
          data={control?._formValues?.mr_product_group_id}
          toggle={() =>
            setModalConfig({ ...modalConfig, show: false, action: "PORTAL" })
          }
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "UNIT" && (
        <CreateModalUnit
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "UNIT" })
            refetchUnit()
            setCheckLoading(true)
          }}
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "SIZE" && (
        <CreateModalSize
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "SIZE" })
            refetchSize()
          }}
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "STYLE" && (
        <CreateModalStyle
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "STYLE" })
            refetchStyle()
          }}
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "COLOUR" && (
        <CreateModalColour
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "COLOUR" })
            refetchColour()
          }}
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "CATEGORY" && (
        <CreateModalCategory
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "CATEGORY" })
            refetchCategory()
          }}
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "MARKET" && (
        <CreateModalMarket
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "MARKET" })
            refetchMarket()
          }}
        />
      )}
      {modalConfig?.show === true && modalConfig?.action === "MATERIAL" && (
        <CreateModalMaterial
          toggle={() => {
            setModalConfig({ ...modalConfig, show: false, action: "MATERIAL" })
            refetchMaterial()
          }}
        />
      )}
    </section>
  )
}

function GenerateCode(): JSX.Element {
  // Hooks
  const { refetchBarcode, isLoading } = useProductBarcode()

  // Form
  const { control, setValue } = useFormContext<ProductList>()
  const mr_product_material_id = useWatch({
    control,
    name: "mr_product_material_id"
  })
  const mr_product_category_id = useWatch({
    control,
    name: "mr_product_category_id"
  })
  const mr_product_style_id = useWatch({
    control,
    name: "mr_product_style_id"
  })
  const mr_product_colour_id = useWatch({
    control,
    name: "mr_product_colour_id"
  })
  const mr_product_group_id = useWatch({
    control,
    name: "mr_product_group_id"
  })

  const generateData = async () => {
    refetchBarcode(
      mr_product_material_id,
      mr_product_category_id,
      mr_product_style_id,
      mr_product_colour_id,
      mr_product_group_id
    ).then(res => {
      setValue("product_barcode", res)
    }).catch(res => {
      setValue("product_barcode", res)
    })
  }

  return (
    <div className="mt-[36px]">
      <Button
        type="button"
        color="primary"
        className="!rounded-r-lg"
        loading={isLoading ? "true" : undefined}
        onClick={generateData}
      >
        {isLoading ? "" : "AUTO"}
      </Button>
    </div>
  )
}

function GenerateItem(): JSX.Element {
  // Hooks
  const { refetchName, isLoading } = useProductName()

  // Form
  const { control, setValue } = useFormContext<ProductList>()
  const mr_product_material_id = useWatch({
    control,
    name: "mr_product_material_id"
  })
  const mr_product_category_id = useWatch({
    control,
    name: "mr_product_category_id"
  })
  const mr_product_style_id = useWatch({
    control,
    name: "mr_product_style_id"
  })
  const mr_product_colour_id = useWatch({
    control,
    name: "mr_product_colour_id"
  })
  const lengthValue = useWatch({
    control,
    name: "length"
  })
  const widthValue = useWatch({
    control,
    name: "width"
  })
  const heightValue = useWatch({
    control,
    name: "height"
  })

  const generateData = async () => {
    refetchName(
      mr_product_material_id,
      mr_product_category_id,
      mr_product_style_id,
      mr_product_colour_id,
      lengthValue && lengthValue !== "0" ? lengthValue : "",
      widthValue && widthValue !== "0" ? widthValue : "",
      heightValue && heightValue !== "0" ? heightValue : ""
    ).then(res => {
      setValue("product_name", res)
    }).catch(res => {
      setValue("product_name", res)
    })
  }

  return (
    <div className="mt-[36px]">
      <Button
        type="button"
        color="primary"
        className="!rounded-r-lg"
        loading={isLoading ? "true" : undefined}
        onClick={generateData}
      >
        {isLoading ? "" : "AUTO"}
      </Button>
    </div>
  )
}