// React
import { useContext, useEffect } from "react"

// Components
import { Button, Modal } from "components"
import { FormSection } from "./FormSection"

// Configs
import { theme } from "configs"

// Form
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"
import { random } from "uniqolor"

// Types
import type { DataList } from "../types"

// Utils
import { useApi } from "utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: {
  location_name?: string
  address?: string
  description?: string,
  country_id?: string,
  province_id?: string,
  regency_id?: string,
  subdistrict_id?: string,
  village_id?: string,
  onSuccess?: () => void
  toggle: () => void
  refetch?: () => void
}) {
  // Hooks
  const api = useApi()
  const { toggle } = useContext(Render)

  const { dataCode} = useCode()
	

	useEffect(() => {
	  if (dataCode?.code) {
		methods.setValue("code", dataCode?.code)
	  }
   // eslint-disable-next-line
	}, [dataCode?.code])

  type CreateData = Omit<DataList, "location_id" | "country_name" | "province_name" | "regency_name" | "subdistrict_name" | "village_name"> 

  const defaultValues= {
    code: "",
    location_name: "",
    address: "",
    description: "",
    country_id: Number(props.country_id) ?? null,
    province_id: Number(props.province_id) ?? null,
    regency_id: Number(props.regency_id) ?? null,
    subdistrict_id: Number(props.subdistrict_id) ?? null,
    village_id: Number(props.village_id) ?? null,
    color: random().color,
  }
  const validationSchema = yup.object().shape({ 
    code: yup.string().label("CODE").required().min(2).max(3),
    location_name: yup.string().label("Location name").required().max(100, "Must be 100 characters or less"),
    address: yup.string().label("Address").optional().max(100, "Must be 100 characters or less"),
    description: yup.string().label("Description").optional()
  })
  const onSubmit = (value: CreateData) => {
    return new Promise<void>((resolve) => {
      if (value.location_name === theme.location_name) {
        toast.error("Location name has been set as default location")
        return resolve()
      }

      toast.promise(
        api.post("/location/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message
        }
      ).then(() => {
        props.toggle()
        toggle()
        props?.refetch && props.refetch()
        if (props.onSuccess) {
          props.onSuccess()
        }
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateData>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="SETUP | LOCATION" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormSection />

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>SAVE</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}