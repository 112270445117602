// React
import { Fragment, useState } from "react"

// Components
import { ActionButton, BaseTable, Button, Loading } from "components"
import { TabSection } from "pages/Purchase/Transaction/components"

// Icons
import { IoSendSharp } from "react-icons/io5"

// Third-Party Libraries
import moment from "moment"
import { Link, useParams } from "react-router-dom"

// Utils
import { convertNumber, useToggle } from "utils"
import { useProbillList, useRejectList } from "../../../utils"
import { DeleteModal } from "pages/Purchase/Transaction/RejectItem/components"

export function Rejected(): JSX.Element {
  // Hooks
  const [currentTab, setCurrentTab] = useState<number>(0)

  return (
    <section className="mt-1">
      <TabSection
        activeIndex={currentTab}
        list={["BILL", "REJECT"]}
        onChangeTab={setCurrentTab}
      />

      {currentTab === 0 ? (
        <Bill />
      ) : currentTab === 1 ? (
        <Reject />
      ) : (
        <Fragment />
      )}
    </section>
  )
}

function Bill(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading } = useProbillList(id)

  return (
    <BaseTable>
      <thead>
        <tr>
          <th colSpan={2}>BILL ID</th>
          <th>DATE</th>
          <th>STATUS</th>
          <th>DELIVERY</th>
          <th>MEMO</th>
          <th>SUBTOTAL</th>
          <th>PAYMENT</th>
          <th>BALANCE</th>
          <th>ACTION</th>
        </tr>
      </thead>

      <tbody>
        {isLoading || !data.length ? (
          <tr>
            <td colSpan={10}>
              <Loading
                loading={isLoading}
                errorText="No data available"
              />
            </td>
          </tr>
        ) : data.map((item, key) => (
          <tr key={key}>
            <td>
              <IoSendSharp />
            </td>

            <td className="text-center">{item.trx_code}</td>
            <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
            <td>{item.approval}</td>
            <td>Delivery#{item.delivery_no}</td>
            <td>{item.memo}</td>
            <td className="text-right">{convertNumber(item.total_bill).intoCurrency}</td>
            <td className="text-right">{convertNumber(item.total_payment).intoCurrency}</td>
            <td className="text-right">{convertNumber(item.balance).intoCurrency}</td>
            <td className="text-center">
              {item.approval === "Approved" && (
                <Link to={`/purchase/transaction/reject-item/${id}/${item.bill_id}`}>
                  <Button color="primary">REJECT</Button>
                </Link>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </BaseTable>
  )
}

function Reject(): JSX.Element {
  // Hooks
  const { id } = useParams()
  const { data, isLoading, refetch } = useRejectList(id!)

  return (
    <BaseTable>
      <thead>
        <tr>
          <th></th>
          <th>REJECT ID</th>
          <th>DATE</th>
          <th>STATUS</th>
          <th>MEMO</th>
          <th>SUBTOTAL</th>
          <th>ACTION</th>
        </tr>
      </thead>

      <tbody>
        {isLoading || !data.length ? (
          <tr>
            <td colSpan={7}>
              <Loading loading={isLoading} errorText="No data available" />
            </td>
          </tr>
        ) : data.map((item, key) => (
          <tr key={key}>
            <td>
              <section className="flex justify-center">
                <Link to={`/purchase/transaction/reject-item/edit/${item.bill_id}/${item.reject_id}`}>
                  <IoSendSharp />
                </Link>
              </section>
            </td>
            <td>{item.trx_code}</td>
            <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
            <td>{item.approve_status}</td>
            <td>{item.memo}</td>
            <td className="text-right">{convertNumber(item.subtotal).intoCurrency}</td>
            <td className="text-center">
              {item.approve_status === "Pending" && (
                <Delete
                  bill_id={item.bill_id.toString()}
                  reject_id={item.reject_id.toString()}
                  onSuccess={refetch}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </BaseTable>
  )
}

function Delete(params: {
  bill_id: string
  onSuccess: () => void
  reject_id: string
}): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Delete onClick={toggle} />

      {isActive && (
        <DeleteModal
          {...params}
          toggle={toggle}
        />
      )}
    </Fragment>
  )
}