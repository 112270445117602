// Pages
import { UpdatePage } from "pages/Card/Transaction/CreateNewCustomer/components/ChildItem/components/Item/components"
import { UpdatePage as UpdateVendor } from "pages/Card/Transaction/CreateNewVendor/components/ChildItem/components/Item/components"
import { CreateNewCustomer } from "pages/Iframe/CreateNewCustomer"
import { CreateNewEmployee } from "pages/Iframe/CreateNewEmployee"
import { CreateNewVendor } from "pages/Iframe/CreateNewVendor"
import { DetailInvoice } from "pages/Iframe/DetailInvoice"

// Types
import type { RouteObject } from "react-router-dom"

export const iframeRoutes: RouteObject[] = [
  {
    path: "create-customer",
    element: <CreateNewCustomer />
  },
  {
    path: "create-employee",
    element: <CreateNewEmployee />
  },
  {
    path: "create-vendor",
    element: <CreateNewVendor />
  },
  {
    path: "invoice-detail/:id",
    element: <DetailInvoice />
  },
  {
    path: "customer-update/:id",
    element: <UpdatePage isDetailShop={true} />
  },
  {
    path: "vendor-update/:id",
    element: <UpdateVendor isDetailShop={true} />
  }
]