// React
import { Fragment, useContext } from "react"

// Components
import { ActionButton } from "components"
import { Delete, Update } from "./components"
import { ChildItem } from "../ChildItem"
import { CreateModal } from "../CreateModal"

// Contexts
import { LocationContext } from "contexts"

// Icons
import { TbChevronRight, TbCircleCheck } from "react-icons/tb"

// Types
import type { CoaGroup } from "../../types"
import type { permissionProps } from "types"

// Utils
import { convertNumber, useToggle } from "../../../../../utils"
import { DataContext } from "../../utils"

export function Item(props: {
  index: number
  item: CoaGroup
  onSuccess: () => void
  parent_id?: number
  permission: permissionProps
}) {
  // Hooks
  const location = useContext(LocationContext)
  const { data } = useContext(DataContext)
  const { isActive, setActive, toggle } = useToggle(false)

  // Vars
  const detail_count = props.item.childcount
  const isChildNotEmpty =
    data.sort((a, b) => a.coa_code.localeCompare(b.coa_code)).filter((item) => item.parent_id === props.item.coa_id).length === 0

  const renderChild = () => {
    props.onSuccess()
    setActive(false)
    setTimeout(() => setActive(true), 200)
  }

  return (
    <Fragment>
      <tr
        className={props.item.header_level === 3 ? "font-normal" : "font-bold"}>
        <td className="text-center">{!props.parent_id && props.index + 1}</td>
        <td>{detail_count > 0 && `${detail_count}D`}</td>
        <td>
          {props.item.header_level !== 3 && (
            <section className="flex justify-center">
              <button
                color="primary"
                className="rounded-full"
                onClick={toggle}
                title="Collapse">
                <TbChevronRight
                  className={`transition ${isActive && "rotate-90"}`}
                />
              </button>
            </section>
          )}
        </td>
        <td>
          {props.item.cheque_coa && <TbCircleCheck className="mx-auto" />}
        </td>
        <td className="whitespace-nowrap">{props.item.coa_code}</td>
        <td style={{ paddingLeft: `${16 * (props.item.coa_level + 1)}px` }}>
          {props.item.coa_name}
        </td>
        <td>
          {location.data.find(item => item.value === props.item.location_id?.toString())?.label}
        </td>
        <td>
          {props.item.budget_coa && <TbCircleCheck className="mx-auto" />}
        </td>
        <td className="text-center">
          {props.item.header_level === 3 && "D "}
          {props.item.coa_level}
          {props.item.header_level === 1
            ? " H"
            : props.item.header_level === 2
            ? " S"
            : ""}
        </td>
        <td>{props.item.account_type_name}</td>
        <td className="text-right">
          {convertNumber(props.item.opening_balance ?? 0).intoCurrency}
        </td>
        <td>
            <section className="join flex">
              {props.item.header_level !== 3 && (
                <Add item={props.item} onSuccess={renderChild} />
              )}

              <Update
                id={props.item.coa_id}
                isChildNotEmpty={isChildNotEmpty}
                onSuccess={props.onSuccess}
              />

              <Delete id={props.item.coa_id} onSuccess={props.onSuccess} />
            </section>
        </td>
      </tr>

      {isActive && (
        <ChildItem
          permission={props?.permission}
          parent_id={props.item.coa_id}
        />
      )}
    </Fragment>
  )
}

function Add(props: { item: CoaGroup; onSuccess: () => void }) {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <Fragment>
      <ActionButton.Add
        permission={"AR011"}
        className="join-item"
        onClick={toggle}
      />

      {isActive && (
        <CreateModal
          account_type_id={props.item.account_type_id}
          group_coa_code={props.item.group_coa_code}
          group_coa_id={props.item.group_coa_id}
          parent_id={props.item.coa_id}
          parent_level={props.item.coa_level + 1}
          parent_name={`${props.item.coa_code} ${props.item.coa_name}`}
          report_type={props.item.report_type}
          toggle={toggle}
          onSuccess={props.onSuccess}
        />
      )}
    </Fragment>
  )
}
