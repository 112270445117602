// React
import { useState } from "react"

// Components
import { BaseTable, Card, FooterMenu, HeaderMenu, Label, Loading, PaginationData, Select, TableNumber } from "components"
import { ApproveAction } from "./components"

// Third-Party Libraries
import moment from "moment"

// Types
import type { FilterState } from "./types"

// Utils
import { convertNumber } from "utils"
import { appDropdown, useApprovalList, filterValue, statusMap } from "./utils"

export default function ApprovePaymentInvoice() {
	// Hooks
	const [pagination, setPagination] = useState<FilterState>(filterValue)
	const { data, isLoading, refetch } = useApprovalList(pagination)

	return (
		<section className="container my-2">
			<section>
				<HeaderMenu title="PAYMENT INVOICE APPROVAL" />

				<section>
					<section className="mt-2">
						<Card>
							<Card.Body>
								<div className="justify-end lg:flex items-center">
									<Label className="mr-2">APPROVAL</Label>

									<Select
										className="lg:w-[200px]"
										placeholder="Select Approval"
										options={appDropdown}
										value={appDropdown.find(item => item.value === pagination.approval_status)}
										onChange={e => setPagination({ ...pagination, approval_status: e?.value })}
									/>
								</div>
							</Card.Body>
						</Card>
					</section>
				</section>

				<section className="mt-2">
					<Card>
						<Card.Body>
							<div className="overflow-x-auto">
								<BaseTable>
									<thead>
										<tr>
											<th rowSpan={2}>No</th>
											<th colSpan={2} rowSpan={2}>PAYMENT INVOICE ID #</th>
											<th rowSpan={2}>DATE</th>
											<th rowSpan={2}>CUSTOMER</th>
											<th rowSpan={2}>REF INVOICE ID#</th>
											<th colSpan={2}>PAYMENT</th>
											<th rowSpan={2}>LOCATION</th>
											<th rowSpan={2}>MEMO</th>
											<th rowSpan={2}>STATUS APPROVE</th>
											<th rowSpan={2}>DATE APPROVE</th>
										</tr>

										<tr>
											<th>DEPOSIT</th>
											<th>AMOUNT</th>
										</tr>
									</thead>

									<tbody>
										{isLoading || data?.payload?.length === 0 ? (
											<tr>
												<td colSpan={12} className="text-center">
													<Loading loading={isLoading} errorText={isLoading ? "" : "No data available"} />
												</td>
											</tr>
										) : data?.payload.map((item, key: number) => (
											<tr key={key}>
												<td>
													{TableNumber({
														dataLength: data.pagination_data.size,
														pageCurrent: pagination.page,
														index: key,
														limit: pagination.limit,
														totalData: data.pagination_data.total
													})}
												</td>
												<td>
													<ApproveAction data={item} refetch={refetch} />
												</td>
												<td>{item.trx_code}</td>
												<td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>
												<td>{item.customer.customer_name}</td>
												<td>{item.invoice.trx_code}</td>
												<td className="text-right">{convertNumber(item.deposit_amount).intoCurrency}</td>
												<td className="text-right">{convertNumber(item.payment_amount).intoCurrency}</td>
												<td>{item.location.location_name}</td>
												<td>{item.memo}</td>
												<td>{statusMap[item.approval_status]}</td>
												<td>{item.approve_date ? moment(item.approve_date).format("DD/MM/YYYY") : ""}</td>
											</tr>
										))}
									</tbody>
								</BaseTable>

								<div className="mt-5">
									<PaginationData data={data} pagination={pagination} setPagination={setPagination} />
								</div>
							</div>
						</Card.Body>
					</Card>
				</section>

					<section className="mt-2">
							<FooterMenu/>
					</section>
			</section>
		</section>
	)
}