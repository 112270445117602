import { Fragment, useState } from "react"
import { Button, Card, Input, PaginationData, Loading, TableNumber, HeaderMenu, BaseTable, TableHeaderSort, FooterMenu} from "../../../../components"
import { TbChevronRight } from "react-icons/tb"
import { RenderProvider, useColumnConfig, useList } from "./utils"
import { useNavigate } from "react-router-dom"
import debounce from "lodash/debounce"
import { PaginationState } from "types"
import { default_pagination_value, usePermission } from "utils"
import {PersonalList } from "./types"
import { Delete, Detail, Excel, Pdf, Update } from "./components/ChildItem/components/Item/components"
import { IsActive } from "./components/ChildItem/components/Item/components/IsActive"
import FilterTable from "./components/FilterTable"
import { defaultValue } from "./utils/function"

type headerState = { title: string, sort: boolean, align?: 'left' | 'right' }
export default function CreateNewVendor(props: { tab?: string }) {
  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const {dataColumn, refetchColumn} = useColumnConfig()
  const onDebounce = debounce((e: any) => setPagination(prev => { return { ...prev, keyword: e.target.value } }))
  const dataList: PersonalList[] = data.items
  const permission = usePermission(["CR03"])
  
  const dataLength = data.pagination_data.size
  const pageCurrent = pagination.page
  const limit = pagination.limit
  const totalData = pagination.total

  const dataFillter = defaultValue(dataColumn)

  const header: headerState[] = [
    { title: "ID VENDOR", sort: true },
    { title: "VENDOR", sort: true },
  ]

  const sortMapping: Record<string, string> = {
    'ID VENDOR': 'code',
    'VENDOR': 'name',
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }
  
  
  return (
    <section className="container my-5">
      {props?.tab !== "tab" && 
      <HeaderMenu title="DATA ENTRY | CREATE NEW VENDOR" />}
      <RenderProvider>
        <section className="container my-2">
          <Card>
            <Card.Body>
              <div className="flex justify-between items-center">
                <div className="ml-4">
                  <div className="flex gap-3">
                    <FilterTable dataList={dataColumn} refetch={refetchColumn} />
                    <Excel dataFillter={dataFillter}/>
                    <Pdf dataFillter={dataFillter} />
                  </div>
                </div>
                <Create permission={permission?.canCreate} />
              </div>
            </Card.Body>
            <Card.Body>
              <div>
                <Input
                  label="SEARCH"
                  className="lg:w-[300px] md:w-full mb-2"
                  placeholder="Search data..."
                  onChange={onDebounce}
                />
              </div>
              <div className="overflow-x-auto">
                <BaseTable className="border-collapse">
                  <thead>
                    <tr>
                      <th >NO</th>
                      <TableHeaderSort
                      title={header}
                      onSort={(item, sortType) => handleSort(item, sortType)}
                    />
                      {dataFillter.handphone && <th>HANDPHONE</th>}
                      {dataFillter.email && <th>EMAIL</th>}
                      {dataFillter.cardLabel && <th>CARD LABEL</th>}
                      {dataFillter.category && <th>CATEGORY</th>}
                      {dataFillter.priceLevel && <th>PRICE LEVEL</th>}
                      {dataFillter.bank && <th>BANK</th> }
                      {dataFillter.bankAccount && <th>BANK ACCOUNT</th> }
                      {dataFillter.country && <th>COUNTRY</th> }
                      {dataFillter.province && <th>PROVINCE</th> }
                      {dataFillter.regency && <th>REGENCY</th> }
                      {dataFillter.subdistrict && <th>SUBDISTRICT</th> }
                      {dataFillter.village && <th>VILLAGE</th> }
                      {dataFillter.address && <th>ADDRESS</th> }
                      <th className="w-[100px]">ACTION</th>
                    </tr>
                  </thead>

                  <tbody>
                    {isLoading || dataList.length === 0
                      ? <tr><td colSpan={9} className="text-center"><Loading loading={isLoading} errorText="No data available" /></td></tr>
                      : <Fragment>
                        {dataList.map((item, key) => (
                          <tr className="font-normal" key={key}>
                            <td>{TableNumber({ dataLength: dataLength, pageCurrent: pageCurrent, index: key, limit: limit, totalData: totalData })}</td>
                            <td>{item.vendor_code}</td>
                            <td>{item.vendor_name}</td>
                            {dataFillter.handphone && <td>{item.handphone}</td>}
                            {dataFillter.email && <td>{item.email}</td>}
                            {dataFillter.cardLabel && <td>{item.card_label === 1 ? 'Individu' : 'Company'}</td>}
                            {dataFillter.category && <td>{item.category_vendor_name}</td>}
                            {dataFillter.priceLevel && <td>{item.price_level_vendor_name}</td>}
                            {dataFillter.bank && <td>{item.bank_name}</td>}
                            {dataFillter.bankAccount && <td>{item.bank_account}</td>}
                            {dataFillter.country && <td>{item.country_name}</td>}
                            {dataFillter.province && <td>{item.province_name}</td>}
                            {dataFillter.regency && <td>{item.regency_name}</td>}
                            {dataFillter.subdistrict && <td>{item.subdistrict_name}</td>}
                            {dataFillter.village && <td>{item.village_name}</td>}
                            {dataFillter.address && <td>{item.address}</td>}
                            <td className="flex">
                              <Detail permission={permission?.canRead} id={item} />
                              <Update permission={permission?.canUpdate} id={item} />
                              <Delete permission={permission?.canDelete} id={item} refetch={refetch} />
                              <IsActive permission={permission?.canSoftDelete} id={item} refetch={refetch} />
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    }
                  </tbody>
                </BaseTable>
              </div>
              <div className="mt-5">
                <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
              </div>
            </Card.Body>
          </Card>
        </section>
        <section className="mt-2">
              <FooterMenu/>
        </section>
      </RenderProvider>
    </section>
  )
}

function Create(props: { permission?:boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <Button permission={"CT041"} color="primary" onClick={() => navigate("/cards/transaction/create-new-vendor/create")}>
        CREATE NEW <TbChevronRight />
      </Button>
    </Fragment>
  )
}