// React
import { useState } from "react"

// Third-Party Libraries
import axios from "axios"
import toast from "react-hot-toast"

// Types
import type { SelectOptionType, SuccessFetch } from "types"

// Utils
import { useApi } from "utils"

// Types
interface DataType {
  size: SelectOptionType[]
  category: SelectOptionType[]
  product_category: SelectOptionType[]
  colour: SelectOptionType[]
  group: SelectOptionType[]
  material: SelectOptionType[]
  style: SelectOptionType[]
  unit: SelectOptionType[]
  product: SelectOptionType[]
}

export function useMasterData(): {
  isLoading: boolean
  refetch: () => Promise<DataType>
} {
  // Hooks
  const api = useApi()
  const [isLoading, setLoading] = useState<boolean>(false)

  const refetch = (): Promise<DataType> => {
    setLoading(true)

    return new Promise<DataType>((resolve) => {
      axios.all([
        api.get("/productsize/"),
        api.get("/categorycustomer/"),
        api.get("/productcategory/"),
        api.get("/productcolour/"),
        api.get("/productgroup/"),
        api.get("/productmaterial/"),
        api.get("/productstyle/"),
        api.get("/productunit/"),
        api.get("/product/")
      ]).then(axios.spread((
        size,
        category,
        product_category,
        colour,
        group,
        material,
        style,
        unit,
        product
      ) => {
        // Vars
        const size_value: SuccessFetch<{ payload: { mr_product_size_id: number; mr_product_size_name: string; }[] }> = size
        const category_value: SuccessFetch<{ payload: { category_customer_id: number; category_customer_name: string; }[] }> = category
        const product_category_value: SuccessFetch<{ payload: { mr_product_category_id: number; mr_product_category_name: string; }[] }> = product_category
        const colour_value: SuccessFetch<{ payload: { mr_product_colour_id: number; mr_product_colour_name: string; }[] }> = colour
        const group_value: SuccessFetch<{ payload: { mr_product_group_id: number; mr_product_group_name: string; }[] }> = group
        const material_value: SuccessFetch<{ payload: { mr_product_material_id: number; mr_product_material_name: string; }[] }> = material
        const style_value: SuccessFetch<{ payload: { mr_product_style_id: number; mr_product_style_name: string; }[] }> = style
        const unit_value: SuccessFetch<{ payload: { mr_unit_id: number; mr_unit_name: string; }[] }> = unit
        const product_value: SuccessFetch<{ payload: { product_id: number; product_barcode: string; }[] }> = product
        const result: DataType = {
          category: category_value.data.payload.map(item => ({
            label: item.category_customer_name,
            value: item.category_customer_id.toString()
          })),
          colour: colour_value.data.payload.map(item => ({
            label: item.mr_product_colour_name,
            value: item.mr_product_colour_id.toString()
          })),
          group: group_value.data.payload.map(item => ({
            label: item.mr_product_group_name,
            value: item.mr_product_group_id.toString()
          })),
          material: material_value.data.payload.map(item => ({
            label: item.mr_product_material_name,
            value: item.mr_product_material_id.toString()
          })),
          product_category: product_category_value.data.payload.map(item => ({
            label: item.mr_product_category_name,
            value: item.mr_product_category_id.toString()
          })),
          size: size_value.data.payload.map(item => ({
            label: item.mr_product_size_name,
            value: item.mr_product_size_id.toString()
          })),
          style: style_value.data.payload.map(item => ({
            label: item.mr_product_style_name,
            value: item.mr_product_style_id.toString()
          })),
          unit: unit_value.data.payload.map(item => ({
            label: item.mr_unit_name,
            value: item.mr_unit_id.toString()
          })),
          product: product_value.data.payload.map(item => ({
            label: item.product_barcode,
            value: item.product_id.toString()
          }))
        }

        resolve(result)
      })).catch(() => {
        toast.error("Fetching Data Master Error")
      }).finally(() => {
        setLoading(false)
      })
    })
  }

  return { isLoading, refetch }
}