// Components
import { Button } from 'components'

// DnD kit
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

// Icons
import { TbChevronLeft, TbChevronRight, TbGripVertical } from 'react-icons/tb'

export function SortableItem(params: {
  disabled?: boolean
  label: string
  name: string
  rightSide?: boolean
  onSwap: () => void
}) {
  // Hooks
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: params.name})

  // Vars
  const disabled: boolean = Boolean(params.disabled)
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  
  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`w-full p-3 border rounded-lg text-xl font-bold flex items-center gap-1 relative ${disabled ? "bg-base-200 cursor-not-allowed" : "bg-base-100 cursor-pointer"}`}
      onClick={() => !disabled && params.onSwap()}
    >
      <section className={`absolute ${params.rightSide ? "left-0" : "right-0"}`}>
        {params.rightSide ? <TbChevronLeft /> : <TbChevronRight />}
      </section>

      <Button
        color='ghost'
        size='sm'
        className='cursor-grab'
        {...attributes}
        {...listeners}
      >
        <TbGripVertical />
      </Button>

      {params.label}
    </div>
  )
}