import { Fragment, useState } from "react"
import { Button, Card, Select, Input, PaginationData, Loading, TableNumber, HeaderMenu, BaseTable, TableHeaderSort, FooterMenu } from "../../../../components"
import { TbChevronRight } from "react-icons/tb"
import { RenderProvider, restrictedData, useColumnConfig, useList } from "./utils"
import { useNavigate } from "react-router-dom"
import { FaSearch } from "react-icons/fa";
import { useDepartement, useMainLocation, useJobPosition } from "./components/FormSection/utils"
import debounce from "lodash/debounce"
import { PersonalList } from "./types"
import { Delete, Detail, Excel, Pdf, Update } from "./components/ChildItem/components/Item/components"
import { IsActive } from "./components/ChildItem/components/Item/components/IsActive"
import FilterTable from "./components/FilterData"
import { defaultValue } from "./utils/function"
import { usePermission } from "utils"

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  pages: number;
  keyword: string;
  department?: string;
  jobpos?: string;
  location?: string;
  sort?: string;
}
type headerState = { title: string, sort: boolean, align?: 'left' | 'right' }
export default function CreateNewEmployee(props: { tab?: string }) {
  const [searchData, setSearchData] = useState<any | undefined>({ department: "", jobpos: "", location: "" });
  const permission = usePermission(["CR01"])

  const default_pagination_value = {
    total: 0,
    page: 1,
    limit: 10,
    pages: 1,
    keyword: "",
    department: "",
    jobpos: "",
    location: ""
  }

  const { dataDepartement, isLoadingDepartement } = useDepartement()
  const { dataJobPosition, isLoadingJobPosition } = useJobPosition()
  const { dataMainLocation, isLoadingMainLocation } = useMainLocation()
  const {dataColumn, refetchColumn} = useColumnConfig()

  const [pagination, setPagination] = useState<PaginationState>(default_pagination_value)
  const { data, isLoading, refetch } = useList(pagination)
  const onDebounce = debounce((e: any) => setPagination(prev => { return { ...prev, keyword: e.target.value } }))
  const dataList: PersonalList[] = data.items
  const dataFillter = defaultValue(dataColumn)

  const dataLength = data.pagination_data.size
  const pageCurrent = pagination.page
  const limit = pagination.limit
  const totalData = pagination.total

  const header: headerState[] = [
    { title: "ID", sort: true },
    { title: "NAME", sort: true },
  ]

  const sortMapping: Record<string, string> = {
    'ID': 'employee_code',
    'NAME': 'employee_name',
  }
  const handleSort = (item: string, sortType: string) => {
    const sortParam = sortMapping[item] ? `${sortMapping[item]}:${sortType}` : '';
    setPagination({ ...pagination, sort: sortParam, page: 1 })
  }

  return (
    <section className="container my-5">
      <RenderProvider>
        {props?.tab !== "tab" && 
        <HeaderMenu title="DATA ENTRY | CREATE NEW EMPLOYEE" />}
        <section className="container my-2">
          <Card>
            <Card.Body className="flex-row justify-between items-center">
              <div className="ml-4">
                <div className="flex gap-3">
                  <FilterTable dataList={dataColumn} refetch={refetchColumn}/>
                  <Excel dataFillter={dataFillter}/>
                  <Pdf dataFillter={dataFillter}/>
                </div>
              </div>
              <Create permission={permission?.canCreate} />
            </Card.Body>
            <Card.Body>
              <div className="lg:flex lg:justify-between lg:items-end">
                <div className="mb-3">
                  <Input
                    label="SEARCH" 
                    className="lg:w-[300px] md:w-full"
                    placeholder="Search data..."
                    onChange={onDebounce}
                  />
                </div>
                <div className="flex items-end mb-4 justify-between">
                  <Select
                    label="DEPARTMENT"
                    placeholder="Select department"
                    className="lg:w-[200px] md:w-full me-2"
                    width={true}
                    isLoading={isLoadingDepartement}
                    options={dataDepartement}
                    value={dataDepartement.find(item => item.value === searchData?.department)}
                    onChange={e => setSearchData({ ...searchData, department: e?.value })}
                  />
                  <Select
                    label="JOB POSITION"
                    placeholder="Select job position"
                    className="lg:w-[200px] md:w-full me-2"
                    width={true}
                    isLoading={isLoadingJobPosition}
                    options={dataJobPosition}
                    value={dataJobPosition.find(item => item.value === searchData?.jobpos)}
                    onChange={e => setSearchData({ ...searchData, jobpos: e?.value })}
                  />
                  <Select
                    label="MAIN LOCATION"
                    placeholder="Select main location"
                    className="lg:w-[200px] md:w-full me-2"
                    width={true}
                    isLoading={isLoadingMainLocation}
                    options={dataMainLocation}
                    value={dataMainLocation.find(item => item.value === searchData?.location)}
                    onChange={e => setSearchData({ ...searchData, location: e?.value })}
                  />
                  <Button
                    type="button"
                    color="primary"
                    className="!rounded-lg"
                    onClick={() => {
                      setPagination(prev => { 
                        return {
                          ...prev, 
                          department: searchData?.department,
                          jobpos: searchData?.jobpos,
                          location: searchData?.location
                        } 
                      })
                    }}
                  >
                    <FaSearch />
                  </Button>
                </div>
              </div>
              <div className="overflow-x-auto">
                <BaseTable className="border-collapse">
                  <thead>
                    <tr>
                      <th>NO</th>
                      <TableHeaderSort
                      title={header}
                      onSort={(item, sortType) => handleSort(item, sortType)}
                    />
                      {dataFillter.nomorNationalId && <th>NOMOR OF NATIONALITY ID</th>}
                      {dataFillter.religion && <th>RELIGION</th>}
                      {dataFillter.handphone && <th>HANDPHONE</th>}
                      {dataFillter.email && <th>EMAIL</th>}
                      {dataFillter.country && <th>COUNTRY</th>}
                      {dataFillter.province && <th>PROVINCE</th>}
                      {dataFillter.regency && <th>REGENCY</th>}
                      {dataFillter.subdistrict && <th>SUBDISTRICT</th>}
                      {dataFillter.village && <th>VILLAGE</th>}
                      {dataFillter.address && <th>ADDRESS</th>}
                      {dataFillter.departement && <th>DEPARTEMENT</th>}
                      {dataFillter.divisi && <th>DIVISI</th>}
                      {dataFillter.gender && <th>GENDER</th>}
                      {dataFillter.employeeStatus && <th>EMPLOYEE STATUS</th>}
                      {dataFillter.bank && <th>BANK</th>}
                      {dataFillter.branch && <th>BRANCH</th>}
                      {dataFillter.accountBankName && <th>ACCOUNT BANK NAME</th>}
                      <th className="w-[100px]">ACTION</th>
                    </tr>
                  </thead>

                  <tbody>
                    {isLoading || dataList.length === 0 
                      ? <tr><td colSpan={9} className="text-center"><Loading loading={isLoading} errorText="No data available" /></td></tr>
                      : <Fragment>
                          {dataList.map((item, key) => {
                            // Vars
                            const isRestricted: boolean = Boolean(restrictedData.find(i => i === item.employee_code))

                            return (
                              <tr className="font-normal" key={key}>
                                <td>{TableNumber({ dataLength: dataLength, pageCurrent: pageCurrent, index: key, limit: limit, totalData: totalData })}</td>
                                <td>{item.employee_code}</td>
                                <td>{item.employee_name}</td>
                                {dataFillter.nomorNationalId && <td>{item.nomor}</td>}
                                {dataFillter.religion && <td>{item.religion_name}</td>}
                                {dataFillter.handphone && <td>{item.handphone}</td>}
                                {dataFillter.email && <td>{item.email}</td>}
                                {dataFillter.country && <td>{item.country_name}</td>}
                                {dataFillter.province && <td>{item.province_name}</td>}
                                {dataFillter.regency && <td>{item.regency_name}</td>}
                                {dataFillter.subdistrict && <td>{item.subdistrict_name}</td>}
                                {dataFillter.village && <td>{item.village_name}</td>}
                                {dataFillter.address && <td>{item.address}</td>}
                                {dataFillter.departement && <td>{item.department_name}</td>}
                                {dataFillter.divisi && <td>{item.divisi_name}</td>}
                                {dataFillter.gender && <td>{item.gender}</td>}
                                {dataFillter.employeeStatus && <td>{item.employee_status_name}</td>}
                                {dataFillter.bank && <td>{item.bank_name}</td>}
                                {dataFillter.branch && <td>{item.branch}</td>}
                                {dataFillter.accountBankName && <td>{item.account_number}</td>}
                                <td className="flex">
                                  {!isRestricted && <Detail permission={permission?.canRead} id={item} />}

                                  {!isRestricted && (
                                    <Fragment>
                                      <Update permission={permission?.canUpdate} id={item} />
                                      <Delete permission={permission?.canDelete} id={item} refetch={refetch} />
                                      <IsActive permission={permission?.canSoftDelete} id={item} refetch={refetch} />
                                    </Fragment>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </Fragment>
                    }
                  </tbody>
                </BaseTable>
              </div>
              <div className="mt-5">
                <PaginationData data={data} pagination={pagination} setPagination={setPagination} />
              </div>
            </Card.Body>
          </Card>
        </section>
        <section className="mt-2">
          <FooterMenu hasCancelButton/>
        </section>
      </RenderProvider>
    </section>
  )
}

function Create(props: { permission?: boolean }) {
  const navigate = useNavigate()
  return (
    <Fragment>
      <Button permission={"CR011"} color="primary" onClick={() => navigate("/cards/transaction/create-new-employee/create")}>
        CREATE NEW <TbChevronRight />
      </Button>
    </Fragment>
  )
}