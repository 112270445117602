/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, HeaderMenu, Input, NumberInput } from "components"
import { APPROVAL } from "pages/Sales/Register/utils/vars"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { IoSendSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { useToggle } from "utils"
import {
  CodeCustomerOrder,
  DropdownCustomer,
  FormCustomerOrder,
  ModalDelete,
  SummaryFooter,
  TableCustomerOrder
} from "../../CustomerOrder/components"
import { FormSalesOrderType } from "../../CustomerOrder/types"
import { useSOInvoiceList } from "../../Invoice/utils/hooks"
import { useDepositCustomerListBySo } from "../../PaymentDeposit/utils/hooks"
import { CommandButtons } from "./CommandButtons"

export type FormSectionProps = {
  isEdit?: boolean
  approvalView?: boolean
  onSubmit: () => void
}

export const FormSection = ({
  isEdit = false,
  approvalView = false,
  onSubmit
}: FormSectionProps) => {
  const navigate = useNavigate()
  const modalDelete = useToggle(false)
  const methods = useFormContext<FormSalesOrderType>()
  const [
    approval_status,
    sales_order_id,
    transaction_date,
    trx_code,
    customer_id,
    customer_name
  ] = useWatch({
    control: methods.control,
    name: [
      "approval_status",
      "sales_order_id",
      "transaction_date",
      "trx_code",
      "customer_id",
      "customer_name"
    ]
  })
  const invoices = useSOInvoiceList({
    sales_order_id: sales_order_id?.toString() ?? ""
  })
  const depositCustomer = useDepositCustomerListBySo({
    params: { sales_order_id: sales_order_id ?? NaN }
  })

  const deleteData = {
    sales_order_id: sales_order_id ?? 0,
    customer_id: customer_id ?? 0,
    transaction_date,
    trx_code: trx_code ?? "",
    customer_name: customer_name ?? "",
    approval_status: approval_status ?? 1
  }

  return (
    <section className="container my-5 flex flex-col gap-5">
      <HeaderMenu title="DATA ENTRY | SALES ORDER">
        <CodeCustomerOrder isEdit={isEdit} />
      </HeaderMenu>

      <section className="grid lg:grid-cols-3 gap-x-6 gap-y-3">
        <section className="w-full">
          <DropdownCustomer approvalView={approvalView} isEdit={isEdit} />
        </section>

        <section>
          <div className="normal-case flex justify-evenly items-center pt-10">
            <div className="flex flex-col gap-2">
              <span>TRADING TERM </span>
              <span>PRICE LEVEL</span>
            </div>

            <div className="px-2 flex flex-col gap-2">
              <IoSendSharp />
              <IoSendSharp />
            </div>

            <div className="flex flex-col gap-2">
              <Controller
                control={methods.control}
                name="payment_term_name"
                render={({ field }) => (
                  <span>Net {field.value ?? 0} Days after OEM </span>
                )}
              />

              <Controller
                control={methods.control}
                name="price_level_name"
                render={({ field }) => (
                  <span>{field.value ? field.value : "-"}</span>
                )}
              />
            </div>
          </div>
        </section>

        <section className="lg:justify-self-end self-end mb-3">
          <Controller
            control={methods.control}
            name="tax_inclusive"
            render={({ field }) => (
              <Checkbox disabled value={field.value} label="">
                TAX-INCLUSIVE
              </Checkbox>
            )}
          />
        </section>
      </section>

      <FormCustomerOrder approvalView={approvalView} />

      <TableCustomerOrder
        approvalView={approvalView}
        showActionButton={
          !invoices?.data?.length && !depositCustomer?.data?.length
        }
      />

      <section className="grid lg:grid-cols-2 gap-x-6 gap-y-3">
        <div className="flex flex-col gap-3 flex-grow">
          <Controller
            control={methods.control}
            name="so_status_text"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="SO STATUS"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="so_payment_status_text"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="PAYMENT STATUS"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="approval_status_text"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE STATUS"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="approved_by_name"
            render={({ field, fieldState }) => (
              <Input
                disabled
                label="APPROVE BY"
                value={field.value}
                ref={field.ref}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="freight"
            render={({ field, fieldState }) => (
              <NumberInput
                textRight
                label="FREIGHT"
                value={field.value}
                disabled={approval_status !== APPROVAL.PENDING || approvalView}
                ref={field.ref}
                onValueChange={(value) => field.onChange(value.floatValue)}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

        <div className="flex flex-col lg:items-end gap-3">
          <SummaryFooter />
        </div>
      </section>

      <CommandButtons
        showSaveButton
        isSubmitting={methods.formState.isSubmitting}
        onSave={onSubmit}
      />

      <ModalDelete
        modalState={modalDelete}
        data={deleteData}
        onSuccess={() => {
          modalDelete.setActive(false)
          navigate("/sales/register", { replace: true })
        }}
      />
    </section>
  )
}
