// EXCEPTIONS
// #1: ignore new key on dropdown

// React
import { Fragment, useContext, useEffect } from "react"

// Components
import { ActionButton, Button, Input, NumberInput } from "components"
import { Expenses, Project, TaxCode } from "./components"

// Contexts
import { DataJournalContext } from "pages/Finance/Transaction/contexts"

// Form
import { Controller, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Icons
import { TbEye } from "react-icons/tb"

// Third-Party Libraries
import toast from "react-hot-toast"

// Utils
import { useToggle } from "utils"
import { FormType, getAcc, getAmountDiff } from "pages/Finance/Transaction/utils"
import { DataJournalType, account_class, validationSchema } from "./utils"

export function DataJournal(props: {
  defaultValue?: DataJournalType
  method: "add" | "edit"
  onAddJournal: (value: DataJournalType) => void
  onCancelEditing?: () => void
}) {
  // Hooks
  const { tax_code: { data } } = useContext(DataJournalContext)

  // Form
  const { control } = useFormContext<FormType>()
  const memo = useWatch({
    control,
    name: "memo"
  })
  const reference_no = useWatch({
    control,
    name: "reference_no"
  })
  const amount = useWatch({
    control,
    name: "amount"
  })
  const datajournal = useWatch({
    control,
    name: "datajournal"
  })

  // Hooks
  const { isActive, toggle } = useToggle(props.method === "add")
  const { sub_total, tax } = getAcc(datajournal)

  // Vars
  const defaultTax = data.find(item => item.rate === 0)
  const amount_diff = getAmountDiff(amount, sub_total, tax)

  // Form
  const defaultValues: DataJournalType = {
    amount: amount_diff < 0 ? 0 : amount_diff,
    coa_id: "",
    coa_name: "",
    memo: memo,
    project_id: "",
    project_name: "",
    rate: 0,
    referensi: reference_no,
    tax_id: "",
    tax_coa_collect: "",
    tax_coa_collect_id: "",
    tax_coa_paid: "",
    tax_coa_paid_id: "",
    tax_name: ""
  }
  const methods = useForm<DataJournalType>({
    defaultValues: props.method === "edit" ? props.defaultValue : defaultValues,
    resolver: yupResolver(validationSchema)
  })
  const onSubmit = (value: DataJournalType) => {
    props.onAddJournal(value)
    methods.reset()
  }

  useEffect(() => {
    if (props.method === "add") {
      methods.reset({
        amount: amount_diff < 0 ? 0 : amount_diff,
        coa_id: "",
        coa_name: "",
        memo: memo,
        project_id: "",
        project_name: "",
        rate: 0,
        referensi: reference_no,
        tax_id: defaultTax?.value ?? "",
        tax_coa_collect: defaultTax?.tax_coa_collect ?? "",
        tax_coa_collect_id: defaultTax?.tax_coa_collect_id.toString() ?? "",
        tax_coa_paid: defaultTax?.tax_coa_paid ?? "",
        tax_coa_paid_id: defaultTax?.tax_coa_paid_id.toString() ?? "",
        tax_name: defaultTax?.label ?? ""
      })
    }

    // eslint-disable-next-line
  }, [amount, datajournal, memo, reference_no])

  return (
    <FormProvider {...methods}>
      <tr className="no-padding-body">
        <td className="text-center">
          {isActive && (
            <Button
              color="transparent"
              onClick={() => {
                if (amount_diff <= 0) {
                  return toast.error("Insufficient Balance")
                }

                toggle()
              }}
            >
              <TbEye className="size-5" />
            </Button>
          )}
        </td>
        {isActive ? (
          <td colSpan={7} />
        ) : (
          <Fragment>
            <td>
              <Expenses />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="memo"
                render={({ field, fieldState }) => (
                  <section {...account_class}>
                    <Input
                      {...field}
                      className="text-gray-400"
                      placeholder="Enter Memo"
                      error={fieldState.error?.message}
                    />
                  </section>
                )}
              />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="amount"
                render={({ field, fieldState }) => (
                  <section {...account_class}>
                    <NumberInput
                      className="text-gray-400"
                      placeholder="Enter Amount"
                      error={fieldState.error?.message}
                      ref={field.ref}
                      value={field.value}
                      onValueChange={value => field.onChange(value.floatValue)}
                    />
                  </section>
                )}
              />
            </td>
            <td>
              <Project />
            </td>
            <td>
              <Controller
                control={methods.control}
                name="referensi"
                render={({ field, fieldState }) => (
                  <section {...account_class}>
                    <Input
                      {...field}
                      className="text-gray-400"
                      placeholder="Enter Reference"
                      error={fieldState.error?.message}
                    />
                  </section>
                )}
              />
            </td>
            <td>
              <TaxCode />
            </td>
            <td className="text-center">
              {props.onCancelEditing && <ActionButton.Close onClick={props.onCancelEditing} />}
              <ActionButton.Add loading={methods.formState.isSubmitting ? "true" : undefined} onClick={methods.handleSubmit(onSubmit)} />
            </td>
          </Fragment>
        )}
      </tr>
    </FormProvider>
  )
}