// Components
import { Button } from "components"

export function TabSection(params: {
  customElement?: JSX.Element
  activeIndex: number
  list: string[]
  onChangeTab: (tabIndex: number) => void
}) {
  return (
    <section className="flex overflow-x-auto no-scrollbar">
      {params.list.map((item, key) => {
        // Vars
        const isActive: boolean = key === params.activeIndex

        return (
          <section key={key} className="min-w-[126px]">
            <Button
              size="sm"
              color="ghost"
              className={`no-animation w-full bg-[#3c3c3c] hover:bg-primary hover:text-black whitespace-nowrap ${isActive ? "bg-primary text-black" : "text-white"}`}
              onClick={() => params.onChangeTab(key)}
            >
              {item}
            </Button>
          </section>
        )
      })}

      {params.customElement}
    </section>
  )
}