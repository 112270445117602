/* eslint-disable react-hooks/exhaustive-deps */
import { Button, PermissionLink } from "components"
import moment from "moment"
import { ModalDeleteJournal } from "pages/Finance/Journal/components/ModalDeleteJournal"
import { JournalList, MapJournalList } from "pages/Finance/Journal/types"
import { mapJournalList } from "pages/Finance/Journal/utils/functions"
import { UseFilter } from "pages/Finance/Journal/utils/hooks"
import { RegisterDateType } from "pages/Finance/Register/types"
import { generateLinkTransaction } from "pages/Finance/Register/utils/function"
import { Fragment, useEffect, useState } from "react"
import {
  IoCheckmarkCircle,
  IoCloseCircle,
  IoSendSharp,
  IoTrashBin
} from "react-icons/io5"
import { SuccessFetch } from "types"
import { convertNumber, useApi, useToggle } from "utils"

interface ItemProps {
  index: number
  item: RegisterDateType
  filter: UseFilter
  onChangeCheckbox?: (index: number) => void
  fetchList: () => void
  permission: string
}

export function Item({
  index,
  item,
  filter,
  onChangeCheckbox,
  fetchList,
  permission
}: ItemProps) {
  const api = useApi()
  const loading = useToggle(false)
  const modalDelete = useToggle(false)
  const [dataJournal, setDataJournal] = useState<MapJournalList[]>([])

  useEffect(() => {
    if (modalDelete.isActive) {
      loading.toggle()

      api
        .postForm("/journal/getjournaldata", {
          StartDate: filter.dateFrom,
          EndDate: filter.dateTo,
          DataSource: item.data_source
        })
        .then((res: SuccessFetch<{ payload: JournalList[] }>) => {
          const data = res?.data?.payload ?? []
          setDataJournal(mapJournalList(data, item.data_source_id))
        })
        .catch(() => setDataJournal([]))
        .finally(() => setTimeout(loading.toggle, 800))
    }
  }, [modalDelete.isActive])

  return (
    <Fragment>
      <tr key={item.journal_id}>
        <td>{index + 1}</td>

        <td className="font-normal">
          <span className="pe-2">{item.data_source_code}</span>
          <span className="ps-4">{item.trx_code}</span>
        </td>

        <td>{moment(item.transaction_date).format("DD/MM/YYYY")}</td>

        <td>{item.trader}</td>

        <td>{item.reference_no}</td>

        <td>
          <section className="flex">
            <div className="pe-1 text-lg">
              {item.reconsiled ? (
                <IoCheckmarkCircle color="#4dcb5b" />
              ) : (
                <IoCloseCircle color="red" />
              )}
            </div>
            <div className="ps-4">{item.mrk}</div>
          </section>
        </td>

        <td>{item.memo}</td>

        <td>{item.location_name}</td>

        <td className="text-right">
          {item.position === 1 ? convertNumber(item.amount).intoCurrency: ""}
        </td>

        <td className="text-right text-red-500">
          {item.position === 2 ? convertNumber(item.amount).intoCurrency: ""}
        </td>

        <td className="text-center">
          <input
            type="checkbox"
            defaultChecked={item.checked}
            className="checkbox checkbox-sm rounded-sm"
            onChange={() => (onChangeCheckbox ? onChangeCheckbox(index) : null)}
          />
        </td>

        <td>
          <section className="text-lg flex items-center cursor-pointer">
            <PermissionLink permission={permission + "3"} to={generateLinkTransaction({ data_source: item.data_source, data_source_id: item.data_source_id})}>
              <button
                className="pe-4 rounded-full"
                title="Collapse">
                <IoSendSharp size={15} />
              </button>
            </PermissionLink>
            {item.reconsiled ? null : (
              <Button permission={permission + "4"} onClick={modalDelete.toggle}>
                <IoTrashBin size={15} color="red" />
              </Button>
            )}
          </section>
        </td>
      </tr>

      {modalDelete.isActive ? (
        <ModalDeleteJournal
          loadingData={loading.isActive}
          isOpen={modalDelete.isActive}
          journalList={dataJournal[0]?.data?.data ?? []}
          date={item.transaction_date}
          journalCode={item.journal_code}
          toggle={modalDelete.toggle}
          refetchJournal={fetchList}
        />
      ) : null}
    </Fragment>
  )
}
