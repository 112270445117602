import { useContext, useEffect } from "react"
import { Button, HintList, Modal } from "../../../../../components"
import { FormSection } from "./FormSection"
import * as yup from "yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import type { ListDepartement } from "../types"
import { useApi } from "../../../../../utils"
import { Render, useCode } from "../utils"

export function CreateModal(props: {
  department_code?: string
  department_name?: string
  description?: string
  toggle: () => void
  refetch?: () => void
}) {

  const api = useApi()
  const { toggle } = useContext(Render)
  const { dataCode} = useCode()

  useEffect(() => {
    if (dataCode?.code) {
      methods.setValue("department_code", dataCode?.code)
    }
 // eslint-disable-next-line
  }, [dataCode?.code])

  type CreateListCategoryVendor = Omit<ListDepartement, "department_id" | "divisi_name"> 

  const defaultValues:CreateListCategoryVendor= {
    department_code: "",
    divisi_id: "",
    department_name: "",
    description: ""
  }
  const validationSchema = yup.object().shape({
    department_code: yup.string().label("Department code").required().max(3, 'Maximum code is 3 characters'),
    divisi_id: yup.string().label("Divisi").required(),
    department_name: yup.string().label("Department name").required().max(100, "Must be 100 characters or less"),
    description: yup.string().label("Department name").optional()
  })
  const onSubmit = (value: CreateListCategoryVendor) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        api.post("/department/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.message ?? err.response.message
        }
      ).then(() => {
        props.toggle()
        toggle()
        props?.refetch && props.refetch()
      }).catch(() => {}).finally(resolve)
    })
  }
  const methods = useForm<CreateListCategoryVendor>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="CREATE DEPARTEMENT" size="xl" closeModal={props.toggle}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal.Body
            hintText={(
              <HintList
                info={`Defining data attributes for "Company Department Structure" in an ERP system is essential for organizing and managing different functional areas within a company. This structure is fundamental to human resources management, accounting, project management, and reporting. Here's a comprehensive explanation of the data attributes for "Company Department Structure":`}
                list={[
                  {
                    label: "Code",
                    list: [
                      {
                        label: "Definition",
                        value: "A unique alphanumeric identifier assigned to each department within the company."
                      },
                      {
                        label: "Purpose",
                        value: "The code serves as a concise reference for quickly identifying and managing different departments within the ERP system. It simplifies data entry, enhances searchability, and ensures consistency in recording and tracking operations across various departments."
                      },
                      {
                        label: "Example",
                        value: `Codes like "DEP01" for "Human Resources," "DEP02" for "Finance," or "DEP03" for "Sales" enable users to efficiently manage department-specific tasks, personnel, and budgets.`
                      }
                    ]
                  },
                  {
                    label: "Department",
                    list: [
                      {
                        label: "Definition",
                        value: "The name or title of the department within the company."
                      },
                      {
                        label: "Purpose",
                        value: "This attribute specifies the official name of the department, which is crucial for internal organization, interdepartmental collaborations, and external communications. It helps in clear delineation of responsibilities and facilitates effective management of resources and personnel."
                      },
                      {
                        label: "Example",
                        value: `- "Human Resources" — Manages employee relations, recruitment, and benefits. 
                                - "Finance" — Handles company financials, including accounting, budgeting, and financial reporting. 
                                - "Sales" — Responsible for sales operations, customer relationships, and revenue generation. 
                                - "Marketing" — Focuses on market research, advertising, and promotional activities.`
                      },
                    ]
                  },
                  {
                    label: "Description",
                    list: [
                      {
                        label: "Definition",
                        value: "A brief explanatory text that provides additional details or context about the department."
                      },
                      {
                        label: "Purpose",
                        value: "The description offers insights into the primary functions, roles, and responsibilities of the department. It assists employees and management in understanding the scope of each department's activities, which is essential for efficient workflow and effective resource allocation."
                      },
                      {
                        label: "Example",
                        value: `- For "Human Resources," the description might be: "Responsible for managing employee recruitment, training, performance evaluations, and ensuring compliance with labor laws. Also handles employee benefits and conflict resolution." 
                                - For "Finance," it could read: "Oversees all financial functions including accounting, budgeting, risk management, and financial reporting. Ensures the company's financial stability and compliance with financial regulations." 
                                - For "Sales," it might state: "Drives the company’s revenue through customer engagement, sales strategies, and relationship management. Develops and implements plans to achieve sales targets." 
                                - For "Marketing," it might note: "Develops strategic marketing initiatives and communications to enhance the company's market presence and customer engagement. Manages branding, advertising, and promotional events."`
                      },
                    ]
                  }
                ]}
                detail="These data attributes are essential for clearly defining and managing the company's departmental structure within the ERP system. They enable organized management of personnel and resources, streamlined operations, and improved communication and coordination across different functional areas of the company."
              />
            )}
          >
            <FormSection />
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button type="submit" color="primary" loading={methods.formState.isSubmitting ? "true" : undefined}>Save</Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  )
}