// React
import { Fragment } from "react"

// Components
import { Button, Modal } from "components"
import { FinalFormat } from "./FinalFormat"
import { SelectHeaders } from "./SelectHeaders"

// Contexts
import { FormatContext } from '../contexts'

// Utils
import { useToggle } from "utils"
import { useDataFormat } from '../utils'

export function Format(): JSX.Element {
  // Hooks
  const { isActive, toggle } = useToggle(false)

  return (
    <>
      <Button
        color="primary"
        onClick={toggle}
      >
        DOWNLOAD FORMAT
      </Button>

      {isActive && <ModalSection toggle={toggle} />}
    </>
  )
}

function ModalSection(params: {
  toggle: () => void
}): JSX.Element {
  // Vars
  const tabViews: {
    element: JSX.Element
    label: string
  }[] = [
    {
      element: <SelectHeaders />,
      label: "Select Headers"
    },
    {
      element: <FinalFormat />,
      label: "Final Format"
    }
  ]

  // Hooks
  const format = useDataFormat()

  return (
    <Modal
      isOpen
      title="DOWNLOAD FORMAT"
      closeModal={params.toggle}
    >
      <Modal.Body contentClassName="py-0">
        <FormatContext.Provider value={format}>
          <div className="z-[2] py-3 bg-base-100 sticky top-0 flex justify-center">
            <section className="steps">
              {tabViews.map((item, key) => (
                <li
                  key={key}
                  className={`step ${format.tab >= key && "step-primary font-bold"}`}
                >
                  {item.label}
                </li>
              ))}
            </section>
          </div>

          <div className="py-2">
            {tabViews.map((item, key): JSX.Element => <Fragment key={key}>{format.tab === key ? item.element : null}</Fragment>)}
          </div>
        </FormatContext.Provider>
      </Modal.Body>
    </Modal>
  )
}