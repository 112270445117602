// React
import { useEffect, useState } from "react"

// Third-Party Libraries
import axios from "axios"

// Types
import type { SuccessFetch } from "types"
import type { TaxCodePayload } from "../../types"
import type { DataJournalType } from "../vars"
import type { FormEditType } from "../vars"

// Utils
import { useApi } from "utils"

export function useDetail(data_source: string, data_source_id: string) {
  // Hooks
  const api = useApi()
  const [data, setData] = useState<null | FormEditType>(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    axios.all([
      api.postForm("/journal/getjournaldetail", {
        data_source,
        data_source_id
      }),
      api.get("/taxcode/"),
      api.get("/project/"),
      api.get("/coa/")
    ]).then(axios.spread((...allRes) => {
      // Vars
      const res: SuccessFetch<{
        payload: {
          finance: {
            amount: number
            authorization_payment: null | string
            card_id: number
            coa_code: string
            coa_id: number
            coa_name: string
            expired_payment: null | string
            finance_id: number
            group_card: number
            memo: string
            location_id: number
            name_payment: null | string
            note_payment: string
            number_payment: null | string
            reconsiled: boolean
            reference_no: string
            tax_inclusive: boolean
            transaction_date: string
            trx_code: string
            type_payment: null | string
            data_source: number
          }
          financedetail: {
            amount: number
            coa_id: number
            memo: string
            project_id: number
            tax_id: number
            referensi: string
          }[]
          images: { file_path: string }[]
          journal: [{ journal_code: string }]
        }
      }> = allRes[0]
      const tax: SuccessFetch<{
        payload: TaxCodePayload[]
      }> = allRes[1]
      const project: SuccessFetch<{
        payload: {
          project_id: number
          project_name: string
        }[]
      }> = allRes[2]
      const coa: SuccessFetch<{
        payload: {
          coa_id: number
          coa_code: string
          coa_name: string
        }[]
      }> = allRes[3]
      const result = res.data.payload
      const tax_inclusive = result.finance.tax_inclusive

      setData({
        tax_inclusive,
        amount: result.finance.amount,
        authorization_payment: result.finance.authorization_payment ?? "",
        balance: 0,
        card_id: result.finance.card_id.toString(),
        coa_id: result.finance.coa_id.toString(),
        coa_name: `${result.finance.coa_code} ${result.finance.coa_name}`,
        expired_payment: result.finance.expired_payment ?? "",
        financeid: result.finance.finance_id,
        group_card: result.finance.group_card.toString(),
        journal_code: result.journal.length > 0 ? result.journal[0].journal_code : "",
        location_id: result.finance.location_id.toString(),
        memo: result.finance.memo,
        name_payment: result.finance.name_payment ?? "",
        note_payment: result.finance.note_payment,
        number_payment: result.finance.number_payment ?? "",
        reconsiled: result.finance.reconsiled,
        reference_no: result.finance.reference_no,
        transaction_date: result.finance.transaction_date,
        trx_code: result.finance.trx_code,
        type_payment: result.finance.type_payment ?? "",
        imgPath: result.images?.map(item => item.file_path) ?? [],
        datajournal: result.financedetail.map(item => {
          // Vars
          const current_coa = coa.data.payload.find(i => Boolean(item.coa_id === i.coa_id))!
          const current_project = project.data.payload.find(i => i.project_id === item.project_id)!
          const current_tax = tax.data.payload.find(i => Boolean(item.tax_id === i.tax_id))!

          return {
            amount: item.amount,
            coa_id: item.coa_id.toString(),
            coa_name: `${current_coa.coa_code} ${current_coa.coa_name}`,
            memo: item.memo,
            project_id: item.project_id.toString(),
            project_name: current_project.project_name,
            rate: current_tax.rate,
            referensi: item.referensi,
            tax_coa_collect: `${current_tax.coa_collect_grp_code}-${current_tax.coa_collect_coa_code} ${current_tax.coa_collect_coa_name}`,
            tax_coa_collect_id: current_tax.coa_collect_id.toString(),
            tax_coa_paid: `${current_tax.coa_paid_grp_code}-${current_tax.coa_paid_coa_code} ${current_tax.coa_paid_coa_name}`,
            tax_coa_paid_id: current_tax.coa_paid_id.toString(),
            tax_id: item.tax_id.toString(),
            tax_name: `${current_tax.tax_name} (${current_tax.rate}%)`
          } as DataJournalType
        })
      })
    })).catch(() => {
      setData(null)
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData(null)
      setLoading(true)
    }

    // eslint-disable-next-line
  }, [])

  return { data, isLoading }
}