// Pages
import { purchaseMenu } from "configs/purchaseMenu"
import { salesMenu } from "configs/salesMenu"
import TaskList from "pages/Task/List"
import { ModuleLayout } from "../components"
import { accountsMenu, cardsMenu, financeMenu, inventoryMenu } from "../configs"
import { accountsRoutes } from "./accountsRoutes"
import { cardsRoutes } from "./cardsRoutes"
import { financeRoutes } from "./financeRoutes"
import { inventoryRoutes } from "./inventoryRoutes"
import { purchaseRoutes } from "./purchaseRoutes"
import { salesRoutes } from "./salesRoutes"
import { tasksRoutes } from "./tasksRoutes"

// Utils
import { routeMapper } from "utils"

export const mainRoute = [
  {
    path: "/",
    element: <TaskList />
  },
  {
    path: "/accounts",
    element: <ModuleLayout menu={accountsMenu.menu} />,
    children: routeMapper(accountsRoutes)
  },
  {
    path: "/finance",
    element: <ModuleLayout menu={financeMenu.menu} />,
    children: routeMapper(financeRoutes)
  },
  {
    path: "/inventory",
    element: <ModuleLayout menu={inventoryMenu.menu} />,
    children: routeMapper(inventoryRoutes)
  },
  {
    path: "/cards",
    element: <ModuleLayout menu={cardsMenu.menu} />,
    children: routeMapper(cardsRoutes)
  },
  {
    path: "/sales",
    element: <ModuleLayout menu={salesMenu.menu} />,
    children: routeMapper(salesRoutes)
  },
  {
    path: "/purchase",
    element: <ModuleLayout menu={purchaseMenu.menu} />,
    children: routeMapper(purchaseRoutes)
  },
  {
    path: "/tasks",
    children: routeMapper(tasksRoutes)
  }
]
