import { Input, Select, Button, Modal, NumberInput } from "../../../../../../components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import type { ProfileList } from "../../types"
import { useBank, useCategory, useMarketing, usePaymentTerm, usePriceLevel } from "./utils"
import { FaPlus } from "react-icons/fa";
import { useEffect, useState } from "react"
import { CreateModal as CreateModalCategory } from "../../../../../Setup/Preference/CategoryCustomer/components";
import { CreateModal as CreateModalPriceLevel } from "../../../../../Setup/Preference/PriceLevelCustomer/components";
import { CreateModal as CreateModalPaymentTerm } from "../../../../../Setup/Preference/PaymentTermCustomer/components";
import { CreateModal as CreateModalBank } from "../../../../../Setup/Preference/Bank/components";
import { CreatePage as NewEmployee } from "../../../../../Card/Transaction/CreateNewEmployee/components"

interface ProfileProps {
  action?: "CREATE" | "UPDATE" | "DETAIL"
  onSubmit: (newState: any) => void
  isActiveSubmit?: boolean
  permission?: string
}

export const FormProfile: React.FC<ProfileProps> = ({ action, onSubmit, isActiveSubmit, permission }) => {
  const { control, getValues, handleSubmit, formState } = useFormContext<ProfileList>()

  return (
    <div>
      <section>
        <div className="flex">
          <div className="w-[50%]">
            <Controller
              control={control}
              name="handphone"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="HANDPHONE"
                  type="text"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input handphone"
                  value={field.value ?? ""}
                  onChange={(e) => {
                    const result = e.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '')
                    field.onChange(result)
                  }}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  type="email"
                  label="EMAIL"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input email"
                  value={field.value ?? ""}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <Controller
              control={control}
              name="website"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="WEBSITE"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input website"
                  value={field.value ?? ""}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <CardLabel isDisabled={action === "DETAIL"} />
            <Group isDisabled={action === "DETAIL"} />
            <Category isDisabled={action === "DETAIL"} />
            <Controller
              control={control}
              name="source_from"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="SOURCE FROM"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input source from"
                  value={field.value ?? ""}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <Marketing isDisabled={action === "DETAIL"} />
            <Controller
              control={control}
              name="media"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="MEDIA"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input media"
                  value={field.value ?? ""}
                  disabled={action === "DETAIL"}
                />
              }
            />
          </div>
          <div className="w-[10px]"></div>
          <div className="w-[50%]">
            <PriceLevel isDisabled={action === "DETAIL"} />
            <PaymentTerm isDisabled={action === "DETAIL"} />
            <Controller
              control={control}
              name="max_balance"
              render={({ field, fieldState }) =>
                <NumberInput
                  label="MAX BALANCE"
                  decimalSeparator="."
                  thousandSeparator=","
                  className="text-gray-400"
                  placeholder="Input max balance"
                  value={field.value ?? ""}
                  onValueChange={value => field.onChange(value.floatValue)}
                  error={fieldState.error?.message}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <Bank isDisabled={action === "DETAIL"} />
            <Controller
              control={control}
              name="bank_account"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="BANK ACCOUNT"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input bank account"
                  value={field.value ?? ""}
                  onChange={(e) => {
                    const result = e.target.value.replace(/[^\d,]/g, '').replace(/[^\d.]/g, '')
                    field.onChange(result)
                  }}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <Controller
              control={control}
              name="bank_beneficiary"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="BANK BENEFICIARY"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input bank beneficiary"
                  value={field.value ?? ""}
                  disabled={action === "DETAIL"}
                />
              }
            />
            <Controller
              control={control}
              name="bank_branch"
              render={({ field, fieldState }) =>
                <Input
                  {...field}
                  label="BANK BRANCH"
                  className="w-full"
                  error={fieldState.error?.message}
                  placeholder="Input bank branch"
                  value={field.value ?? ""}
                  disabled={action === "DETAIL"}
                />
              }
            />
          </div>
        </div>
      </section>
      {action !== "DETAIL" &&
        <div className="flex justify-end">
          <Button
            type="button"
            className="!none mt-5"
            color="primary"
            loading={formState.isSubmitting ? "true" : undefined}
            onClick={handleSubmit(() => onSubmit(getValues()), (err) => console.log(err))}
            disabled={isActiveSubmit}
            permission={"CR021"}
          >
            SAVE
          </Button>
        </div>
      }
    </div>
  )
}


function CardLabel(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const data = [
    { value: "1", label: "Individu" },
    { value: "2", label: "Company" },
  ]

  return (
    <Controller
      control={control}
      name="card_label"
      render={({ field, fieldState }) => (
        <Select
          label="CARD LABEL"
          placeholder="Select card label..."
          isDisabled={props.isDisabled}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />

  )
}
function Group(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const data = [
    { value: "1", label: "Employee" },
    { value: "2", label: "Customer" },
    { value: "3", label: "Vendor" },
  ]

  return (
    <Controller
      control={control}
      name="group_card"
      render={({ field, fieldState }) => (
        <Select
          label="GROUP"
          placeholder="Select group..."
          isDisabled={true}
          error={fieldState.error?.message}
          options={data}
          value={data.find(item => item.value === field.value?.toString())}
          onChange={e => field.onChange(e?.value)}
        />
      )}
    />
  )
}
function Category(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading, refetch } = useCategory()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="category_customer_id"
        render={({ field, fieldState }) => (
          <Select
            label="CATEGORY"
            placeholder="Select category..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled &&
        <div className="mt-[36px]">
          <Button
            type="button"
            color="primary"
            className="!rounded-r-lg"
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalCategory toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Marketing(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading, refetch } = useMarketing()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="employee_id"
        render={({ field, fieldState }) => (
          <Select
            label="MARKETING"
            placeholder="Select marketing..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled &&
        <div className="mt-[36px]">
          <Button
            type="button"
            color="primary"
            className="!rounded-r-lg"
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true &&
        <Modal
          isOpen
          title="CREATE NEW EMPLOYEE"
          size="full"
          closeModal={() => {
            setModalConfig(false)
            refetch()
          }}>
          <NewEmployee asModal />
        </Modal>
      }
    </div>
  )
}
function PriceLevel(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()
  const { data, isLoading, refetch } = usePriceLevel()
  const [modalConfig, setModalConfig] = useState(false)

  const price_level_id = useWatch({ control, name: "price_level_id" })
  const retailPriceId = data?.find(item => item?.label?.toString() === "RETAIL PRICE")?.value;

  useEffect(() => {
    if (price_level_id === undefined) {
      setValue("price_level_id", retailPriceId?.toString())
      refetch()
    }
    // eslint-disable-next-line
  }, [price_level_id, setValue, retailPriceId, refetch])

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="price_level_id"
        render={({ field, fieldState }) => (
          <Select
            label="PRICE LEVEL"
            placeholder="Select price level..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled &&
        <div className="mt-[36px]">
          <Button
            type="button"
            color="primary"
            className="!rounded-r-lg"
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalPriceLevel toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function PaymentTerm(props: { isDisabled: boolean }) {
  const { control, setValue } = useFormContext<ProfileList>()
  const { data, isLoading, refetch } = usePaymentTerm()
  const [modalConfig, setModalConfig] = useState(false)

  const dataDay = data.map(item => ({ ...item, label: `${item.label} day`, value: item.value }))
  const payment_term_id = useWatch({ control, name: "payment_term_id" })
  const paymentTermId = data?.find(item => item?.label?.toString() === "1")?.value;

  useEffect(() => {
    if (payment_term_id === undefined) {
      setValue("payment_term_id", paymentTermId?.toString())
      refetch()
    }
    // eslint-disable-next-line
  }, [payment_term_id, setValue, paymentTermId, refetch])

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="payment_term_id"
        render={({ field, fieldState }) => (
          <Select
            label="PAYMENT TERM (DAY)"
            placeholder="Select payment term..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={dataDay}
            value={dataDay.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled &&
        <div className="mt-[36px]">
          <Button
            type="button"
            color="primary"
            className="!rounded-r-lg"
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalPaymentTerm toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}
function Bank(props: { isDisabled: boolean }) {
  const { control } = useFormContext<ProfileList>()
  const { data, isLoading, refetch } = useBank()
  const [modalConfig, setModalConfig] = useState(false)

  return (
    <div className="flex items-top">
      <Controller
        control={control}
        name="bank_id"
        render={({ field, fieldState }) => (
          <Select
            label="BANK"
            placeholder="Select bank..."
            controlClass={!props.isDisabled ? "!rounded-r-none" : ""}
            isDisabled={props.isDisabled}
            isLoading={isLoading}
            error={fieldState.error?.message}
            options={data}
            value={data.find(item => item.value === field.value?.toString())}
            onChange={e => {
              field.onChange(e?.value)
              refetch()
            }}
          />
        )}
      />
      {!props.isDisabled &&
        <div className="mt-[36px]">
          <Button
            type="button"
            color="primary"
            className="!rounded-r-lg"
            onClick={() => setModalConfig(true)}>
            <FaPlus />
          </Button>
        </div>
      }
      {modalConfig === true && <CreateModalBank toggle={() => {
        setModalConfig(false)
        refetch()
      }} />}
    </div>
  )
}