import { Input, Button } from "../../../../../../components"
import { Controller, useFormContext } from "react-hook-form"
import type { PersonalList } from "../../types"
import { useState } from "react"
import { usePersonalCode } from "../ChildItem/components/Item/utils"
interface PersonalProps {
  action?: string
  data?: PersonalList
  isActiveSubmit?: boolean
  setFileValue: (newState: any) => void
  onSubmit: (newState: any) => void
  permission?: boolean
}

export const FormPersonalData: React.FC<PersonalProps> = ({ action, data, onSubmit, setFileValue, isActiveSubmit, permission }) => {
  const { control, setValue, getValues ,handleSubmit, formState} = useFormContext<PersonalList>()
  const { dataCode } = usePersonalCode()

  const [imageViews, setImageView] = useState<{ file: File | null; view: string | null }>({ file: null, view: null })
  const { REACT_APP_BASE_URL_API } = process.env

  return (
    <section>
      {/* Input Profile */}
      <>
        <div className="avatar flex justify-center mt-6 mb-10">
          <div className="w-[150px] rounded-full">
            {/* eslint-disable-next-line */}
            <img src={imageViews?.view 
              ? imageViews?.view
              : control?._formValues?.Employee_Foto
              ? `${REACT_APP_BASE_URL_API}/getimage/?imgpath=${control?._formValues?.Employee_Foto}`
              : "https://t4.ftcdn.net/jpg/05/17/53/57/360_F_517535712_q7f9QC9X6TQxWi6xYZZbMmw5cnLMr279.jpg"} 
            />
          </div>
        </div>
        <Controller
          control={control}
          name="Employee_Foto"
          render={({ field }) => (
            <input 
              className="absolute mt-[-190px] ml-[100px] w-[150px] h-[150px] rounded-[50%] opacity-0" 
              type="file" 
              disabled={action === "DETAIL"} 
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];

                if (selectedFile) {
                  if (selectedFile.size > 300 * 1024) {
                    alert("Image size maximum 300KB");
                    return;
                  }
              
                  setFileValue(selectedFile);
                  field.onChange(selectedFile);
                  
                  setImageView({ ...imageViews, file: selectedFile ?? control?._formValues?.Employee_Foto});
                  const reader = new FileReader();
                  reader.onloadend = () => setImageView({ ...imageViews, view: reader.result as string });
                  reader.readAsDataURL(selectedFile);
                } else {
                  setFileValue(null);
                  setImageView({ ...imageViews, file: null, view:  null });
                }
              }}
            />
          )}
        />
      </>

      <>
        <Controller
          control={control}
          name="employee_code"
          render={({ field }) => 
            <Input 
              {...field} 
              label="EMPLOYEE CODE"  
              value={action === "CREATE" ? dataCode?.empcode : field.value ?? data?.employee_code ?? ""} 
              disabled={true} 
            />
          }
        />
      </>

      <div className="flex items-top">
        <Controller
          control={control}
          name={action === "UPDATE" ? "empoyee_name" : "employee_name"}
          render={({ field, fieldState }) => 
            <Input 
              {...field} 
              label="FULL NAME *" 
              className="w-full" 
              error={fieldState.error?.message}
              placeholder="Input full name" 
              value={field.value ?? data?.employee_name ?? ""} 
              onChange={(e) => {
                if(action === "UPDATE"){
                  setValue("employee_code", control?._defaultValues?.employee_code!)
                } else {
                  setValue("employee_code", dataCode?.empcode!)
                }
                if(action === "UPDATE"){setValue("empoyee_name", e.target.value?.toUpperCase())}
                if(action === "CREATE"){setValue("employee_name", e.target.value?.toUpperCase())}
              }}
              disabled={action === "DETAIL"} 
            />
          }
        />
      </div>
      <Controller
        control={control}
        name="account"
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="LOGIN *" 
            className="w-full" 
            error={fieldState.error?.message}
            placeholder="Input account" 
            value={field.value ?? ""} 
            disabled={action === "DETAIL"} 
          />
        }
      />
      <Controller
        control={control}
        name="passwd"
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="PASSWORD *" 
            className="w-full" 
            error={fieldState.error?.message}
            placeholder="Input password" 
            value={field.value ?? ""} 
            disabled={action === "DETAIL"} 
          />
        }
      />
      <Controller
        control={control}
        name={action === "UPDATE" ? "Confirm_Passwd" : "confirm_passwd"}
        render={({ field, fieldState }) => 
          <Input 
            {...field} 
            label="RE-PASSWORD *" 
            className="w-full" 
            error={fieldState.error?.message}
            placeholder="Input re-password" 
            value={field.value ?? ""} 
            disabled={action === "DETAIL"} 
          />
        }
      />

      {/* Submit Button */}
      {action !== "DETAIL" && <div className="mt-5 flex justify-end">
        <Button 
          type="button"
          color="primary"
          loading={formState.isSubmitting ? "true" : undefined}
          permission={"CT021"}
          onClick={handleSubmit(() => onSubmit(getValues()),(err)=> console.log(err))}
          disabled={isActiveSubmit}
          >
          SAVE
        </Button>
      </div>}
    </section>
  )
}